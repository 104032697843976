import React, { Component, useEffect } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import EditProfile from "../Accounts/Profile/EditProfile";
import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import NotFoundIndex from "../NotFound/NotFoundIndex";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import LandingPageLoader from "../Loader/LandingPageLoader";
import EmptyLayout from "../layouts/EmptyLayout";
import LandingPageIndex from "../LandingPageIndex/LandingPageIndex";
import HomePageIndex from "../Home/HomePageIndex";
import MessageIndex from "../Messages/MessageIndex";
import BookmarksIndex from "../Bookmarks/BookmarksIndex";
import BookmarkPhoto from "../Bookmarks/BookmarkPhoto";
import BookmarkVideo from "../Bookmarks/BookmarkVideo";
import ModelViewProfile from "../Model/ModelViewProfile";
import FollowingIndex from "../Accounts/FansFollowing/Following/FollowingIndex";
import ListIndex from "../Accounts/List/ListIndex";
import NotificationIndex from "../Notification/NotificationIndex";
import CreatePostIndex from "../Post/CreatePost/CreatePostIndex";
import FavoritesIndex from "../Accounts/Favorites/FavoritesIndex";
import PaymentsIndex from "../Accounts/Payments/PaymentsIndex";
import BankingIndex from "../Accounts/Payments/BankingIndex";
import CardsIndex from "../Accounts/Payments/CardsIndex";
import AddCardPage from "../Accounts/Payments/AddCardPage";
import AddBankIndex from "../Accounts/Payments/AddBankIndex";
import Logout from "../Accounts/Logout";
import Wallet from "../Wallet/Wallet";
import BillingAccountIndex from "../Accounts/Payments/BillingAccountIndex";
import DocumentUploadIndex from "../DocumentUpload/DocumentUploadIndex";
import StaticPage from "../StaticPage/StaticPage";
import FanIndex from "../Accounts/FansFollowing/Fans/FanIndex";
import PostView from "../Post/PostView";
import ResetPasswordForm from "../Accounts/ResetPasswordForm";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { getKycDocumentStart } from "../../store/actions/KycDocumentAction";
import VerifyEmailForm from "../Accounts/VerifyEmailForm";
import HelpIndex from "../Help/HelpIndex";
import ChangePassword from "../Accounts/Profile/ChangePassword";
import DeleteAccount from "../Accounts/Profile/DeleteAccount";
import {withTranslation} from "react-i18next";
import MediaPlanningPage from '../Post/MediaPlanningPage';


const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  title = '',
  ...rest
}) => {
  useEffect(() => {
      if(title) {
          window.document.title = title
      }
  }, [title]);
  
  return <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
  };

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  title = '',
  ...rest
}) => {
  useEffect(() => {
      if(title) {
          window.document.title = title
      }
  }, [title]);
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  return <Route
    {...rest}
    render={(props) =>
      userId && accessToken ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
  };

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
    
  }

  componentDidMount() {
    this.fetchConfig();
    
    if (localStorage.getItem("userLoginStatus") !== "true" && (history.location.pathname && history.location.pathname !== "/")) {
      localStorage.setItem("attemptedUrl", history.location.pathname);
    } else if (localStorage.getItem("userLoginStatus") === "true") {
        this.props.dispatch(fetchUserDetailsStart());
        this.props.dispatch(getKycDocumentStart());
    }
    
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }

    $("#google_analytics").html(
      configuration.get("configData.google_analytics")
    );

    $("#header_scripts").html(configuration.get("configData.header_scripts"));

    $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;
    const {t} = this.props;

    if (isLoading) {
      return (
        <div></div>
      );
    }
    return (
      <>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
        </Helmet>
        <Switch>
          <AppRoute
            path={"/"}
            component={LandingPageIndex}
            exact
            layout={AuthLayout}
            title={t('titles.login')}
          />

          <PrivateRoute
            
            path={"/home"}
            component={HomePageIndex}
            layout={MainLayout}
            title={t('titles.home')}
          />

          <PrivateRoute
            
            path={"/inbox"}
            component={MessageIndex}
            layout={MainLayout}
            title={t('titles.messages')}
          />

          <PrivateRoute
            
            path={"/posts-create"}
            component={CreatePostIndex}
            layout={EmptyLayout}
            title={t('titles.create_post')}
          />
          <PrivateRoute
            
            path={"/post/:post_unique_id"}
            component={PostView}
            layout={MainLayout}
            title={t('titles.post_view')}
          />

          <PrivateRoute
            
            path={"/bookmarks"}
            component={BookmarksIndex}
            layout={MainLayout}
            title={t('titles.bookmarks')}
          />

          <PrivateRoute
            
            path={"/bookmark-photo"}
            component={BookmarkPhoto}
            layout={MainLayout}
            title={t('titles.bookmark_photo')}
          />
          <PrivateRoute
            
            path={"/bookmark-video"}
            component={BookmarkVideo}
            layout={MainLayout}
            title={t('titles.bookmark_video')}
          />
          <PrivateRoute
            
            path={"/following"}
            component={FollowingIndex}
            layout={MainLayout}
            title={t('titles.following')}
          />
          <PrivateRoute
            
            path={"/fans"}
            component={FanIndex}
            layout={MainLayout}
            title={t('titles.fans')}
          />
          <PrivateRoute
            
            path={"/favorites"}
            component={FavoritesIndex}
            layout={MainLayout}
            title={t('titles.favorites')}
          />

          <PrivateRoute
            
            path={"/list"}
            component={ListIndex}
            layout={MainLayout}
            title={t('titles.lists')}
          />

          <PrivateRoute
            
            path={"/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
            title={t('titles.edit_profile')}
          />

          <PrivateRoute
            
            path={"/profile"}
            component={ProfileIndex}
            layout={MainLayout}
            title={t('titles.profile')}
          />

          <PrivateRoute
            
            path={"/notification"}
            component={NotificationIndex}
            layout={MainLayout}
            title={t('titles.notifications')}
          />

          <PrivateRoute
            
            path={"/payments"}
            component={PaymentsIndex}
            layout={MainLayout}
            title={t('titles.payments')}
          />

          <PrivateRoute
            
            path={"/billing-accounts"}
            component={BillingAccountIndex}
            layout={MainLayout}
            title={t('titles.billing_accounts')}
          />

          <PrivateRoute
            
            path={"/cards"}
            component={CardsIndex}
            layout={MainLayout}
            title={t('titles.cards')}
          />
          
          <PrivateRoute
            
            path={"/add-card"}
            component={AddCardPage}
            layout={MainLayout}
            title={t('titles.add_card')}
          />

          <PrivateRoute
            
            path={"/add-bank"}
            component={AddBankIndex}
            layout={MainLayout}
            title={t('titles.add_bank')}
          />

          <PrivateRoute
            
            path={"/banking"}
            component={BankingIndex}
            layout={MainLayout}
            title={t('titles.banking')}
          />

          <PrivateRoute
            
            path={"/wallet"}
            component={Wallet}
            layout={MainLayout}
            title={t('titles.wallet')}
          />

          <PrivateRoute
            
            path={"/document-upload"}
            component={DocumentUploadIndex}
            layout={MainLayout}
            title={t('titles.document_upload')}
          />

          <AppRoute
            path={"/page/:title"}
            component={StaticPage}
            layout={MainLayout}
          />

          <AppRoute
            path={"/logout"}
            component={Logout}
            layout={MainLayout}
          />
          <AppRoute
            path={"/resetpassword/:token"}
            component={ResetPasswordForm}
            layout={MainLayout}
            title={t('titles.reset_password')}
          />
          <AppRoute
            path={"/verify-email"}
            component={VerifyEmailForm}
            layout={MainLayout}
            title={t('titles.verify_email')}
          />
          <PrivateRoute
            
            path={"/help"}
            component={HelpIndex}
            layout={MainLayout}
            title={t('titles.help')}
          />
          <PrivateRoute
            
            path={"/change-password"}
            component={ChangePassword}
            layout={MainLayout}
            title={t('titles.change_password')}
          />
          <PrivateRoute
            
            path={"/delete-account"}
            component={DeleteAccount}
            layout={MainLayout}
            title={t('titles.delete_account')}
          />
          <PrivateRoute
            
            path={"/media-planning"}
            component={MediaPlanningPage}
            layout={MainLayout}
            title={t('plan.editorial_plan')}
          />
          
          {/* Dont move this route to top */}
          
          <AppRoute
            path={"/:username"}
            component={ModelViewProfile}
            layout={MainLayout}
          />

          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withTranslation('common')(connect(null, mapDispatchToProps)(App));
