import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import "./Wallet.css";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { fetchAllTransactionStart } from "../../store/actions/TransactionAction";
import WithdrawModal from "../helper/WithdrawModal";
import NoDataFound from "../NoDataFound/NoDataFound";
import WalletLoader from "../Loader/WalletLoader";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";


const Wallet = (props) => {
	useEffect(() => {
		props.dispatch(fetchWalletDetailsStart());
		props.dispatch(fetchAllTransactionStart());
	}, []);
        let history = useHistory();

        const {t, i18n} = useTranslation('common');

	const [withdrawModal, setWithdrawModal] = useState(false);

	const closeWithdrawModal = () => {
		setWithdrawModal(false);
	};
        

	return (
		<>
			<div className="wallet-sec">
				{props.wallet.loading ? (
					<WalletLoader></WalletLoader>
				) : (
					<Container>
						<Row>
							<Col sm={12} md={12}>
								<div className="wallet-header-sec">
                                                                <div className="top-left">
                                        
                                        <Button className="chat-header-back" onClick={history.goBack}>
                                            <i class="fas fa-arrow-left"></i>
                                        </Button>
                                        
                                        <h1 className="chat-page-title">
                                            {t('wallet.wallet')}
                                        </h1>
                                    </div>
                                    <div className="top-right">
                                        <span className="context-menu hide">
                                            <FontAwesomeIcon icon={faEllipsisV} color={"white"} />
                                        </span>
                                    </div>
									<Row>
										<Col sm={12} md={6} xl={5}>
											<div className="wallet-header-card">
												<h5>Total Balance</h5>
												<div className="wallet-header-details">
													<h3>
														{props.wallet.data.user_wallet.remaining_formatted}
													</h3>
												</div>
											</div>
										</Col>
										<Col sm={12} md={12} xl={3}>
											<div className="edit-save">
												<Button
													className="send-btn-white"
													onClick={() => setWithdrawModal(true)}
												>
													{t('wallet.withdraw')}
												</Button>
											</div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				)}
			</div>
			<div className="trans-table-sec">
				<Container>
					<Row>
						<Col sm={12} md={12}>
							<h4>{t('wallet.movements')}</h4>
							<div className="trans-table">
								<Table borderedless responsive>
									<tbody>
										{props.transaction.loading ? (
											"Loading..."
										) : props.transaction.data.history.length > 0 ? (
											props.transaction.data.history.map((data) => (
												<tr>
                                                                                        <td className={'amount ' + (data.payment_type == "credit" ? "credit" : "withdrawal")}>
                                                                                        {data.paid_amount_formatted.replace(' ', '')}
                                                                                        <span className="date">{data.paid_date}</span>
                                                                                        </td>
                                                                                        <td className="payment-type">
                                                                                        {data.payment_type == "credit" ? t('wallet.credit') : t('wallet.withdrawal')}
                                                                                        <span class="from-username">
                                                                                        {data.received_from_username
															? "from @" + data.received_from_username
															: ""}
                                                                                        </span>
                                                                                        </td>
													{/*<td>{data.status_formatted}</td>
													<td className="amount">
														{data.paid_amount_formatted}{" "}
													</td>
													<td className="amount">
														<span className="text-capitalize">
															{data.payment_type}{" "}
														</span>
													</td>
													<td>
														from :{" "}
														{data.received_from_username
															? data.received_from_username
															: "-"}
													</td>
													<td>{data.payment_id}</td>*/}
												</tr>
											))
										) : (
											<NoDataFound></NoDataFound>
										)}
									</tbody>
								</Table>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<WithdrawModal
				withdrawModal={withdrawModal}
				closeWithdrawModal={closeWithdrawModal}
				payments={props.wallet}
			/>
		</>
	);
};

const mapStateToPros = (state) => ({
	wallet: state.wallet.walletData,
	transaction: state.transaction.allTransaction,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Wallet);
