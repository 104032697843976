import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import UserCard from "../UserCard";

import NoDataFound from "../../../NoDataFound/NoDataFound";

const FollowingActiveSec = (props) => {
  return (
    <>
        {props.users.loading ? (
          "Loading..."
        ) : (
          <>
            
            <Row>
              {props.users.length > 0 ? (
                props.users.map((user) =>
                  user.otherUser && typeof user.otherUser.user_id !== "undefined" ? (
                    <UserCard user={user.otherUser} />
                  ) : (
                    ""
                  )
                )
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </>
        )}
    </>
  );
};

export default FollowingActiveSec;
