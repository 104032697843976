import React from "react";
import Emoji from "./Emoji";

const EmojiList = (props) => {
    return (<div className="emoji-container">
        {['\u{1f601}', '\u{1f602}', '\u{1f62d}', '\u{1f61a}',
                    '\u{1f60d}', '\u2764', '\u{1f494}', '\u{1f48b}',
                    '\u270c', '\u{1f44c}', '\u270b', '\u{1f64c}', '\u{1f648}',
                    '\u{1f52b}', '\u{1f480}'
                ].map(character => (<Emoji character={character} onClick={(event) => props.insertEmoji(event, character)}/>))}
    
    </div>);
};

export default EmojiList;


