import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Image, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
postFileUploadStart,
        savePostStart,
        } from "../../../store/actions/PostAction";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import './CreatePost.css';
import { useTranslation } from "react-i18next";
import ToggleButton from 'react-bootstrap/ToggleButton';

const CreatePostIndex = (props) => {

    const [inputData, setInputData] = useState({amount: 0});
    const [t, i18n] = useTranslation('common');

    const [image, setImage] = useState({previewImage: ""});
    const [paidPost, setPaidPost] = useState(false);
    const [videoTitle, setVideoTitle] = useState("");

    const [fileUploadStatus, setFileUploadStatus] = useState(false);

    const [videoThumbnail, setVideoThumbnail] = useState(false);
    const fileRef = useRef(null);
    const previewFileRef = useRef(null);
    const [scheduledPost, setScheduledPost] = useState(false);
    const [promotedPost, setPromotedPost] = useState(false);
    const [promotionText, setPromotionText] = useState('');
    let now = new Date();

    function addZero(number) {
        let result = "" + number;
        if (number < 10 && result.length == 1) {
            result = "0" + number;
        }

        return result;
    }

    const [publicationDate, setPublicationDate] = useState({
        date: now.getDate(),
        month: now.getMonth(),
        year: now.getFullYear(),
        hours: addZero(now.getHours()),
        minutes: addZero(now.getMinutes())
    });


    const handleChangeImage = (event, fileType) => {
        if (event.currentTarget.type === "file") {
            setFileUploadStatus(true);
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            reader.onloadend = () => {
                setImage({...image, previewImage: reader.result});
            };

            if (file) {
                reader.readAsDataURL(file);
            }
            props.dispatch(
                    postFileUploadStart({
                        file: event.currentTarget.files[0],
                        file_type: fileType,
                    })
                    );
        }
    };

    const handleChangeVideo = (event, fileType) => {
        setVideoTitle(event.currentTarget.files[0].name);
        if (event.currentTarget.type === "file") {
            setFileUploadStatus(true);
            let reader = new FileReader();
            let file = event.currentTarget.files[0];

            if (file) {
                reader.readAsDataURL(file);
            }
            props.dispatch(
                    postFileUploadStart({
                        file: event.currentTarget.files[0],
                        file_type: fileType,
                    })
                    );
            setVideoThumbnail(true);
        }
    };

    const handleChangeFile = (event) => {
        if (event.currentTarget.type === "file") {
            let reader = new FileReader();
            let file = event.currentTarget.files[0];
            if (file.type.match('video.*')) {
                handleChangeVideo(event, 'video');
            } else {
                handleChangeImage(event, 'image');
            }
        }
    };

    const imageClose = (event) => {
        event.preventDefault();
        if (props.fileUpload.loadingButtonContent !== null) {
            const notificationMessage = getErrorNotificationMessage(
                    "File is being uploaded.. Please wait"
                    );
            props.dispatch(createNotification(notificationMessage));
        } else {
            setImage({previewImage: ""});
            setFileUploadStatus(false);
        }
    };

    const videoClose = (event) => {
        event.preventDefault();
        if (props.fileUpload.loadingButtonContent !== null) {
            const notificationMessage = getErrorNotificationMessage(
                    "File is being uploaded.. Please wait"
                    );
            props.dispatch(createNotification(notificationMessage));
        } else {
            setFileUploadStatus(false);
            setVideoTitle("");
        }
    };



    const handleSubmit = (event) => {
        event.preventDefault();
        let publishTime = '';
        if (scheduledPost) {
            publishTime = publicationDate.year + "-" + addZero(+publicationDate.month + 1) + "-" + addZero(publicationDate.date) + " " +
                    addZero(publicationDate.hours) + ":" + addZero(publicationDate.minutes) + ":00";
        }
        if (fileUploadStatus) {
            props.dispatch(
                    savePostStart({
                        content: typeof inputData.content !== "undefined" ? inputData.content : "",
                        amount: inputData.amount ? inputData.amount : "",
                        post_files: props.fileUpload.data.post_file.post_file_id,
                        preview_file: inputData.preview_file ? inputData.preview_file : "",
                        publish_time: publishTime,
                        promotion_text: promotionText,
                        promoted: promotedPost
                    })
                    );
        } else {
            props.dispatch(
                    savePostStart({
                        content: typeof inputData.content !== "undefined" ? inputData.content : "",
                        amount: inputData.amount ? inputData.amount : "",
                        publish_time: publishTime,
                        promotion_text: promotionText,
                        promoted: promotedPost
                    })
                    );
        }
    };

    const togglePaidPost = event => setPaidPost(!paidPost);

    const toggleScheduledPost = event => setScheduledPost(!scheduledPost);

    const togglePromotedPost = event => setPromotedPost(!promotedPost);

    const addMedia = event => {
        event.preventDefault();
        fileRef.current.click();
    }
    const addThumbnail = event => {
        event.preventDefault();
        previewFileRef.current.click();
    }

    const addVideoThumbnail = (event) => {
        setInputData({
            ...inputData,
            preview_file: event.currentTarget.files[0],
        });

        setImage({...image, previewImage: URL.createObjectURL(event.currentTarget.files[0])});
    };

    function LastDayOfMonth(Year, Month) {
        let lastDay = new Date((new Date(Year, Month, 1)) - 1);
        return lastDay.getDate();
    }

    let days = [];
    for (let i = 1; i <= 31; i++) {
        days.push(i);
    }
    

    const months = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december"
    ];
    const years = [];
    for(let i = now.getFullYear() - 11; i < now.getFullYear() + 11; i++) {
        years.push(i);
    }

    const dateChanged = event => setPublicationDate({...publicationDate, date: event.target.value});
    const monthChanged = event => setPublicationDate({...publicationDate, month: event.target.value});
    const yearChanged = event => setPublicationDate({...publicationDate, year: event.target.value});
    const hoursChanged = event => setPublicationDate({...publicationDate, hours: event.target.value});
    const minutesChanged = event => setPublicationDate({...publicationDate, minutes: event.target.value});
    const promotionTextChanged = event => setPromotionText(event.target.value);
    
    let hours = [];
    for (let i = 0; i <= 23; i++) {
        hours.push(i);
    }
    
    let minutes = [];
    for (let i = 0; i <= 55; i+=5) {
        minutes.push(i);
    }
    
    return (
            <div className="notification-page create-post" id="tabs">
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={12} md={12}>
                            <div className="post-create-header">
                                <div className="pull-left">
                                    <Link className="bookmarkes-list notify-title" to={"/home"}>
                                    <i class="fas fa-arrow-left"></i>
                                    </Link>
                                </div>
                                <h1>{t('post.new_post')}</h1>
            
                            </div>
                            </Col>
            
                            <Col sm={12} md={12}>
                            <div className="create-post-textarea">
                                <Form.Group>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder={t('post.enter_post_description')}
                                        name="content"
                                        value={inputData.content}
                                        onChange={(event) =>
                                                setInputData({
                                                    ...inputData,
                                                    content: event.currentTarget.value,
                                                })
                                                                                                                                                                                                        }
                                        />
                                </Form.Group>
                                {videoTitle !== "" ? (
                            <div className="post-title-content">
                                <h4>{videoTitle}</h4>
                                <Link to="#" onClick={videoClose}>
                                <i class="far fa-window-close"></i>
                                </Link>
                            </div>
                                            ) : null}
            
                            </div>
                            <div className="left-half post-write">
                                {image.previewImage !== "" ?
                            <div className="post-img-preview-sec">
                                <Link to="#" onClick={imageClose}>
                                <i class="far fa-times-circle"></i>
                                </Link>
                                <Image
                                    alt="#"
                                    src={image.previewImage}
                                    className="post-video-preview"
                                    />
                            </div>
                                            : videoThumbnail === true ? <button className="add-media" onClick={addThumbnail}><i class="fas fa-images"></i>
                                                <span>{t('post.upload_thumbnail')}</span>
                                                <i className="fas fa-angle-right"></i>
                                            </button> : <button className="add-media" onClick={addMedia}>
                                                <div className="icon">
                                                    <i className="far fa-image"></i>
                                                </div>
                                                <span>{t('post.add_media')}</span>
                                                <i className="fas fa-angle-right"></i>
                                            </button>}
            
                                <Form.Control
                                    id="fileupload"
                                    type="file"
                                    multiple="multiple"
                                    accept="video/mp4,video/x-m4v,video/*,.gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                    onChange={(event) => handleChangeFile(event)}
                                    name="post_files"
                                    ref={fileRef}
                                    />
                                <div className="post-price">
                                    <div className="icon icon-crown"><i class="fas fa-crown"></i></div>
                                    <span>{t('post.paid_post')}</span>
                                    {paidPost ? <label className="price-label">€<Form.Control
                                            type="number"
                                            placeholder=""
                                            name="amount"
                                            value={inputData.amount}
                                            width="50%"
                                            onChange={(event) =>
                                                                                    setInputData({
                                                                                        ...inputData,
                                                                                        amount: event.currentTarget.value,
                                                                                    })
                                                                                                                                                                                                        }
                                            /></label> : null}
                                    <ToggleButton value="1" type="checkbox" className="toggle" checked={paidPost} onChange={togglePaidPost}/>
                                </div>
                                <div className="schedule-post">
                                    <div className="icon"><i class="fas fa-calendar-alt"></i></div>
                                    <span>{t('post.publication_plans')}</span>
                                    <ToggleButton value="1" type="checkbox" className="toggle" checked={scheduledPost} onChange={toggleScheduledPost}/>
            
                                    {scheduledPost ? <div className="controls">
                                        <select className="date" value={publicationDate.date} onChange={dateChanged}>
                                            {days.map(i => {
                                                    if(i <= LastDayOfMonth(publicationDate.year, +publicationDate.month+1)) {
                                                    return <option data-month={publicationDate.month+1}>{i}</option>
                                                }
                                                                                })
                                            }
                                        </select>
                                        <select className="month" value={publicationDate.month} onChange={monthChanged}>
                                            {
                                                                                months.map((month, key) => {

                                                                                    return <option key={key} value={key}>{t(`months.${month}`)}</option>
                                                                                })
                                            }
                                        </select>
                                        <select className="year" value={publicationDate.year} onChange={yearChanged}>
                                        {
                                        years.map((year, key) => {

                                                                                    return <option key={key} value={year}>{year}</option>
                                                                                })
                                        }
                                        </select>
            
                                        <div className="clock">
                                            <span>{t('time.at')}</span>
                                            <select className="hours" value={publicationDate.hours} onChange={hoursChanged}>
                                            {hours.map((hour, key) => {

                                                                                    return <option key={key} value={hour}>{addZero(hour)}</option>
                                                                                })}
                                            </select>&nbsp;:&nbsp;
                                            <select className="minutes" value={publicationDate.minutes} onChange={minutesChanged}>
                                            {minutes.map((minute, key) => {

                                                                                    return <option key={key} value={minute}>{addZero(minute)}</option>
                                                                                })}

                                            </select>
                                        </div>
                                    </div> : null}
                                </div>
                                <div className="promote-post">
                                    <div className="icon"><i class="fas fa-bullhorn"></i></div>
                                    <span>{t('post.send_notices')}</span>
                                    <ToggleButton value="1" type="checkbox" className="toggle" checked={promotedPost} onChange={togglePromotedPost}/>
            
                                    {promotedPost ? <div className="controls create-post-textarea">
                                        <div className="form-group">
                                            <textarea className="form-control" value={promotionText} onChange={promotionTextChanged} placeholder={t('post.enter_promotion_text')}/>
                                        </div>
                                    </div> : null}
                                </div>
                                <Button
                                    type="submit"
                                    className="post-btn btn-primary"
                                    onClick={handleSubmit}
                                    disabled={
                                        props.fileUpload.buttonDisable ||
                                                props.savePost.buttonDisable
                                    }
                                    >
                                    {props.fileUpload.loadingButtonContent !== null
                                                ? props.fileUpload.loadingButtonContent
                                                : props.savePost.loadingButtonContent !== null
                                                ? props.savePost.loadingButtonContent
                                                : t('post.share')}
                                </Button>
            
                            </div>
            
                            </Col>
            
                            {videoThumbnail === true ? (
                            <Form.Control
                                type="file"
                                placeholder="Video Thumbnail Image"
                                name="preview_file"
                                width="50%"
                                className="form-control video-thumbnail-input"
                                accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                onChange={addVideoThumbnail}
                                ref={previewFileRef}
                                />
                                            ) : (
                                            ""
                                        )}
                        </Row>
                    </Form>
                </Container>
            </div>
            );
};

const mapStateToPros = (state) => ({
        savePost: state.post.savePost,
        fileUpload: state.post.fileUpload,
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(CreatePostIndex);
