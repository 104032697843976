import React from "react";
import { Link } from "react-router-dom";
import { Row, Image } from "react-bootstrap";
import UserCard from "../UserCard";
import NoDataFound from "../../../NoDataFound/NoDataFound";
import {getThumbnail} from "../../../helper/Thumbnail";

const FanActiveSec = (props) => {
  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.followers.loading ? (
          "Loading..."
        ) : (
          <>
            
            <Row>
              {props.followers.data.followers.length > 0 ? (
                props.followers.data.followers.map((follower) => (
              <div className="fan">
                <Link to={`/` + follower.otherUser.user_unique_id}>
                  <span className="follower-profile-avatar">
                    <Image src={getThumbnail(follower.otherUser.picture, 53)} className="" />
                  </span>
                </Link>
                <div className="follower-info">
                  <div className="follower-wrapper-name">
                    <div className="follower-profile-names">
                      <div className="follower-name-row">
                        <Link to={follower.otherUser.user_unique_id}>
                          <div className="follower-user-name">
                            {follower.otherUser.name}
                          </div>
                        </Link>
                      </div>
                      <div className="follower-name-row">
                        <Link
                          to={`/` + follower.otherUser.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-id">
                            @{follower.otherUser.username}
                          </div>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
                ))
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default FanActiveSec;
