import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Form} from "react-bootstrap";
import PostDisplayCard from "../helper/PostDisplayCard";
import NoDataFound from "../NoDataFound/NoDataFound";


const ModelProfilePostSec = (props) => {
  const [keyword, setKeyword] = useState('');
  return (
      <div
        role="tabpanel"
        className={
          props.activeSec === "post"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="Section1"
      >
        <div className="profile-post-area">
          <div className="alignleft">
            <span className="post-number">
              {props.userPosts.loading ? (
                "Loading..."
              ) : (
                <span>{props.userPosts.data.total} Posts</span>
              )}
            </span>
          </div>
          {localStorage.getItem("userLoginStatus") === 'true' ? 
          <div className="alignright">
            <div className="profile-search-post">
              <Form className="search-box">
                <input
                  className="search-text"
                  type="text"
                  placeholder="Search Anything"
                  onChange={event => setKeyword(event.currentTarget.value)}
                />
                <Link to="#" className="search-btn">
                  <i class="fas fa-search"></i>
                </Link>
              </Form>
            </div>
          </div> : ""}
        </div>

        {props.userPosts.loading ? (
          "Loading..."
        ) : props.userPosts.data.posts.length > 0 ? localStorage.getItem("userLoginStatus") === "true" ? (
          props.userPosts.data.posts.map((post) => !keyword.length || post.content.search(new RegExp(keyword, 'i')) != -1 ? (
            <PostDisplayCard post={post} key={post.post_id} onClickSubscribe={props.onClickSubscribe}/>
          ) : null)
        ) : (<PostDisplayCard post={props.userPosts.data.posts[0]} key={props.userPosts.data.posts[0].post_id} onClickSubscribe={props.onClickSubscribe} />) : (
          <NoDataFound />
        )}
      </div>
  );
};

export default ModelProfilePostSec;
