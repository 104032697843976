import {
FETCH_SINGLE_USER_PROFILE_START,
        FETCH_SINGLE_USER_PROFILE_SUCCESS,
        FETCH_SINGLE_USER_PROFILE_FAILURE,
        FETCH_SINGLE_USER_POSTS_START,
        FETCH_SINGLE_USER_POSTS_SUCCESS,
        FETCH_SINGLE_USER_POSTS_FAILURE,
        SAVE_PROFILE_VIEW_START
} from "../actions/ActionConstant";

const initialState = {
    userDetails: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    userPosts: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    saveProfileView: {
        error: false,
        inputData: {},
    },
};

const OtherUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SINGLE_USER_PROFILE_START:
            return {
                ...state,
                userDetails: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case FETCH_SINGLE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userDetails: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_SINGLE_USER_PROFILE_FAILURE:
            return {
                ...state,
                userDetails: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_SINGLE_USER_POSTS_START:
            return {
                ...state,
                userPosts: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case FETCH_SINGLE_USER_POSTS_SUCCESS:
            return {
                ...state,
                userPosts: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_SINGLE_USER_POSTS_FAILURE:
            return {
                ...state,
                userPosts: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case SAVE_PROFILE_VIEW_START:
            return {
                ...state,
                saveProfileView: {
                    inputData: action.data,
                    error: false,
                },
            };

        default:
            return state;
}
};

export default OtherUserReducer;
