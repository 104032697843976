import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { resetPasswordStart } from "../../store/actions/UserAction";

const ResetPasswordForm = (props) => {
    const [inputData, setInputData] = useState({});
    
    
    
    const handleSubmit = (event) => {
        event.preventDefault();
        
        inputData.reset_token = props.match.params.token;
        let result = props.dispatch(resetPasswordStart(inputData));
    };
    
   
    
    return (<div className="reset-password-page change-password-sec">
          <div className="card-header bg-transparent">
            <h4>Reset Password</h4>
          </div>
          <div className="card-body">
            <Form autoComplete="new-password" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label for="password">New Password</Form.Label>
                <Form.Control
                  className="form-control"
                  id="password"
                  type="password"
                  placeholder="Enter your new password"
                  name="password"
                  value={inputData.password}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      password: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label for="password_confirmation">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  className="form-control"
                  id="password_confirmation"
                  type="password"
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  value={inputData.password_confirmation}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      password_confirmation: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <Row className="mt-5">
                <Col sm={12} xs={12} md={6} className="mx-auto">
                  <Button
                    className="btn btn-auth btn-lg btn btn-primary"
                    type="submit"
                    disabled={props.resetPassword.buttonDisable}
                    onClick={handleSubmit}
                  >
                    {props.resetPassword.loadingButtonContent != null
                      ? props.resetPassword.loadingButtonContent
                      : "Save"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>);
};

const mapStateToPros = (state) => ({
  resetPassword: state.resetPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ResetPasswordForm);
