import {
  SAVE_POST_START,
  SAVE_POST_SUCCESS,
  SAVE_POST_FAILURE,
  FETCH_POSTS_START,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  FETCH_SINGLE_POST_START,
  FETCH_SINGLE_POST_SUCCESS,
  FETCH_SINGLE_POST_FAILURE,
  DELETE_POST_START,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  CHANGE_POST_STATUS_START,
  CHANGE_POST_STATUS_SUCCESS,
  CHANGE_POST_STATUS_FAILURE,
  POST_FILE_UPLOAD_START,
  POST_FILE_UPLOAD_SUCCESS,
  POST_FILE_UPLOAD_FAILURE,
  PPV_PAYMENT_STRIPE_START,
  PPV_PAYMENT_STRIPE_SUCCESS,
  PPV_PAYMENT_STRIPE_FAILURE,
  PPV_PAYMENT_WALLET_START,
  PPV_PAYMENT_WALLET_SUCCESS,
  PPV_PAYMENT_WALLET_FAILURE,
  PPV_PAYMENT_APPLE_START,
  PPV_PAYMENT_APPLE_SUCCESS,
  PPV_PAYMENT_APPLE_FAILURE,
  SAVE_REPORT_POST_START,
  SAVE_REPORT_POST_SUCCESS,
  SAVE_REPORT_POST_FAILURE,
  FETCH_REPORT_POSTS_START,
  FETCH_REPORT_POSTS_SUCCESS,
  FETCH_REPORT_POSTS_FAILURE,
  PPV_PAYMENT_PAYPAL_START,
  PPV_PAYMENT_PAYPAL_SUCCESS,
  PPV_PAYMENT_PAYPAL_FAILURE,
  SAVE_POST_VIEW_START,
  SAVE_POST_VIEW_SUCCESS,
  SAVE_POST_VIEW_FAILURE,
} from "./ActionConstant";

export function savePostStart(data) {
  return {
    type: SAVE_POST_START,
    data,
  };
}

export function savePostSuccess(data) {
  return {
    type: SAVE_POST_SUCCESS,
    data,
  };
}

export function savePostFailure(error) {
  return {
    type: SAVE_POST_FAILURE,
    error,
  };
}
export function fetchPostsStart(data) {
  return {
    type: FETCH_POSTS_START,
    data,
  };
}

export function fetchPostsSuccess(data) {
  return {
    type: FETCH_POSTS_SUCCESS,
    data,
  };
}

export function fetchPostsFailure(error) {
  return {
    type: FETCH_POSTS_FAILURE,
    error,
  };
}

export function fetchSinglePostStart(data) {
  return {
    type: FETCH_SINGLE_POST_START,
    data,
  };
}

export function fetchSinglePostSuccess(data) {
  return {
    type: FETCH_SINGLE_POST_SUCCESS,
    data,
  };
}

export function fetchSinglePostFailure(error) {
  return {
    type: FETCH_SINGLE_POST_FAILURE,
    error,
  };
}

export function deletePostStart(data) {
  return {
    type: DELETE_POST_START,
    data,
  };
}

export function deletePostSuccess(data) {
  return {
    type: DELETE_POST_SUCCESS,
    data,
  };
}

export function deletePostFailure(error) {
  return {
    type: DELETE_POST_FAILURE,
    error,
  };
}

export function changePostStatusStart(data) {
  return {
    type: CHANGE_POST_STATUS_START,
    data,
  };
}

export function changePostStatusSuccess(data) {
  return {
    type: CHANGE_POST_STATUS_SUCCESS,
    data,
  };
}

export function changePostStatusFailure(error) {
  return {
    type: CHANGE_POST_STATUS_FAILURE,
    error,
  };
}

export function postFileUploadStart(data) {
  return {
    type: POST_FILE_UPLOAD_START,
    data,
  };
}

export function postFileUploadSuccess(data) {
  return {
    type: POST_FILE_UPLOAD_SUCCESS,
    data,
  };
}

export function postFileUploadFailure(error) {
  return {
    type: POST_FILE_UPLOAD_FAILURE,
    error,
  };
}

export function PPVPaymentStripeStart(data) {
  return {
    type: PPV_PAYMENT_STRIPE_START,
    data,
  };
}

export function PPVPaymentStripeSuccess(data) {
  return {
    type: PPV_PAYMENT_STRIPE_SUCCESS,
    data,
  };
}

export function PPVPaymentStripeFailure(error) {
  return {
    type: PPV_PAYMENT_STRIPE_FAILURE,
    error,
  };
}

// Subscription Payment wallet actions.

export function PPVPaymentWalletStart(data) {
  return {
    type: PPV_PAYMENT_WALLET_START,
    data,
  };
}

export function PPVPaymentWalletSuccess(data) {
  return {
    type: PPV_PAYMENT_WALLET_SUCCESS,
    data,
  };
}

export function PPVPaymentWalletFailure(error) {
  return {
    type: PPV_PAYMENT_WALLET_FAILURE,
    error,
  };
}

export function PPVPaymentAppleStart(data) {
  return {
    type: PPV_PAYMENT_APPLE_START,
    data,
  };
}

export function PPVPaymentAppleSuccess(data) {
  return {
    type: PPV_PAYMENT_APPLE_SUCCESS,
    data,
  };
}

export function PPVPaymentAppleFailure(error) {
  return {
    type: PPV_PAYMENT_APPLE_FAILURE,
    error,
  };
}

export function saveReportPostStart(data) {
  return {
    type: SAVE_REPORT_POST_START,
    data,
  };
}

export function saveReportPostSuccess(data) {
  return {
    type: SAVE_REPORT_POST_SUCCESS,
    data,
  };
}

export function saveReportPostFailure(error) {
  return {
    type: SAVE_REPORT_POST_FAILURE,
    error,
  };
}

export function fetchReportPostsStart(data) {
  return {
    type: FETCH_REPORT_POSTS_START,
    data,
  };
}

export function fetchReportPostsSuccess(data) {
  return {
    type: FETCH_REPORT_POSTS_SUCCESS,
    data,
  };
}

export function fetchReportPostsFailure(error) {
  return {
    type: FETCH_REPORT_POSTS_FAILURE,
    error,
  };
}

export function PPVPaymentPaypalStart(data) {
  return {
    type: PPV_PAYMENT_PAYPAL_START,
    data,
  };
}

export function PPVPaymentPaypalSuccess(data) {
  return {
    type: PPV_PAYMENT_PAYPAL_SUCCESS,
    data,
  };
}

export function PPVPaymentPaypalFailure(error) {
  return {
    type: PPV_PAYMENT_PAYPAL_FAILURE,
    error,
  };
}

export function savePostViewStart(data) {
  return {
    type: SAVE_POST_VIEW_START,
    data,
  };
}

export function savePostViewSuccess(data) {
  return {
    type: SAVE_POST_VIEW_SUCCESS,
    data,
  };
}

export function savePostViewFailure(error) {
  return {
    type: SAVE_POST_VIEW_FAILURE,
    error,
  };
}