import React, { useState } from 'react';
import { Image, Form } from "react-bootstrap";
import { getThumbnail } from "../helper/Thumbnail";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player/lazy";
import { connect } from "react-redux";
import { savePostStart, deletePostStart } from '../../store/actions/PostAction';

const MediaPlanningPost = (props) => {
    const post = props.post;
    
    let publishTime = new Date(post.publish_time.replace(/-/g, "/"));
    const {t, i18n} = useTranslation('common');
    const [mode, setMode] = useState('read');
    const [postContent, setPostContent] = useState(post.content);
    const editPost = event => {
        event.preventDefault();
        setMode('edit');
    };
    const savePost = event => {
        event.preventDefault();
        props.dispatch(savePostStart({
            content: postContent,
            post_id: post.post_id,
            noRedirect: true
        }));
        setMode('read');
    };
    const deletePost = (event) => {
        event.preventDefault();
        let agree = window.confirm(t('post.are_you_sure'));
        if (agree) {
            props.dispatch(deletePostStart({post_id: post.post_id}));
        }
    };

    return <div key={post.post_id} className="post">
        <div className="post-header">
            <div className="user-name">
                <span className="post-user-name">
                    {post.user_displayname}
                    {"  "}
    
                    {post.is_paid_post ? (<i class="fas fa-crown"></i>) : post.payment_info.monthly_amount > 0 ? (<i class="fas fa-lock"></i>) : (<i class="fas fa-globe-africa"></i>)}
                </span>
    
                <span className="post-username">@{post.username}</span>
    
            </div>
            <span className={'date ' + (post.is_published ? 'published' : 'scheduled')}>
                {post.is_published ? t('plan.published') : t('plan.scheduled')} - {publishTime.toLocaleString().replace(/(.*)\D\d+/, '$1')}
            </span>
        </div>
        <div className="media">
            {post.postFiles ? post.postFiles.length > 0
                            ? post.postFiles.map((postFile, index) =>
                                (<div className="post-image" key={index}>
                                    <div className="">
                                        <div className="gallery js-gallery">
                                            {postFile.file_type === "image" ? (<Image
                                                src={getThumbnail(postFile.post_file, 1100)}
                                                className="post-view-image"
                                                />) : postFile.file_type === "video" ? (
                                                                <ReactPlayer
                                                                    light={postFile.preview_file}
                                                                    url={postFile.post_file}
                                                                    controls={true}
                                                                    width="100%"
                                                                    height="auto"
                                                                    />

                                                                                    ) : ("")}
                        
                        
                                        </div>
                                    </div>
                                </div>)
                            )
                            : null
                            : null}
        </div>
        <div className="post-content">
            {mode == 'read' ? <p>{typeof postContent !== "undefined" ? postContent : ""}</p> : <Form.Control
                as="textarea"
                rows={3}
                placeholder={t('post.enter_post_description')}
                name="content"
                value={postContent}
                autoFocus
                onChange={(event) => setPostContent(event.target.value)
                                                                                                                                   }
                />}
        </div>
        {post.is_published ? <div className="stats">
            <span>{t('plan.views')}: {post.view_count}</span>
            <span>{t('plan.notifications')}: {post.notifications}</span>
            <span>{t('plan.likes')}: {post.total_likes}</span>
            <span>{t('post.comments')}: {post.total_comments}</span>
        </div> : <div className="stats">{t('plan.scheduled_notifications')}: {post.promotion_status == 'scheduled' ? props.stats.data.followers : 0}</div>}
        {!post.is_published  ? mode == 'read'  ? <div className="controls">
            <button className="edit" onClick={editPost}>{t('plan.edit')}</button>
            <button className="delete" onClick={deletePost}>{t('post.delete')}</button>
        </div> : <div className="controls"><button className="edit" onClick={savePost}>{t('forms.save')}</button></div> : null}
    </div>
};

const mapStateToPros = (state) => ({
        stats: state.users.profileStats
    });

function mapDispatchToProps(dispatch)
{
    return {dispatch};
}


export default connect(mapStateToPros, mapDispatchToProps)(MediaPlanningPost);