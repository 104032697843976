import React, { useEffect, useState } from 'react';
import { fetchPostsStart } from '../../store/actions/PostAction';
import { Container, Row, Col } from "react-bootstrap";
import {useTranslation} from "react-i18next";
import NoDataFound from "../NoDataFound/NoDataFound";
import { connect } from "react-redux";
import './MediaPlanningPage.css';
import { fetchProfileStatsStart } from '../../store/actions/UserAction';
import Post from './MediaPlanningPost';
import { useHistory } from "react-router-dom";


const MediaPlanningPage = (props) => {
    const postsPerPage = 20;
    const [pageNumber, setPageNumber] = useState(1);
    useEffect(() => {
        if (props.posts.loading && pageNumber == 1) {
            props.dispatch(fetchPostsStart({
                take: postsPerPage
            }));
        }
        if (props.stats.loading) {
            props.dispatch(fetchProfileStatsStart());
        }

    }, []);

    let history = useHistory();
    const {t, i18n} = useTranslation('common');
    const goBack = event => {
        event.preventDefault();
        history.goBack();
    };

    const loadMorePosts = event => {
        event.preventDefault();
        
        props.dispatch(fetchPostsStart({
            take: postsPerPage,
            skip: pageNumber * postsPerPage
        }));
        setPageNumber(pageNumber + 1);
    };

    return (<div className="notification-page create-post media-planning-page" id="tabs">
        <Container>
            <Row>
                <Col sm={12} md={12}>
                <div className="post-create-header">
                    <div className="pull-left">
                        <a className="bookmarkes-list notify-title" onClick={goBack}>
                            <i class="fas fa-arrow-left"></i>
                        </a>
                    </div>
                    <h1>{t('plan.editorial_plan')}</h1>
    
                </div>
                </Col>
    
                <Col sm={12} md={12}>
                {props.stats.loading ? ("Loading...") : <div class="profile-stats">
                    <div className="info">
                        <p>{t('plan.personal_page_views')}: {props.stats.data.profile_views}</p>
                        <p>{t('plan.subscribers')}: {props.stats.data.followers}</p>
                    </div>
                    <div className="posts-info">
                        <span>{t('plan.posts')}: {props.stats.data.posts}</span>
                        <span>{t('plan.scheduled_posts')}: {props.stats.data.scheduled_posts}</span>
                    </div>
                </div>}
                <div className='posts-list'>
                    {props.posts.loading ? (
                                    "Loading..."
                                    ) : props.posts.data.posts.length > 0 ? props.posts.data.posts.map((post) => <Post post={post}/> 
                            ) : <NoDataFound />}
                </div>
                        {props.posts.loading ||  props.posts.data.posts.length == props.posts.total ? null : <button className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow" onClick={loadMorePosts}>
            {t(props.posts.loadingMore ? 'buttons.loading_please_wait' : 'buttons.load_more')}</button>}
                </Col>
            </Row>
        </Container>
    </div>);
};

const mapStateToPros = (state) => ({
        stats: state.users.profileStats,
        posts: state.post.posts,
    });

function mapDispatchToProps(dispatch)
{
    return {dispatch};
}


export default connect(mapStateToPros, mapDispatchToProps)(MediaPlanningPage);