import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./AddBankIndex.css";
import { addBankAccountStart } from "../../../store/actions/BankAccountAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const AddBankIndex = (props) => {
  const [inputData, setInputData] = useState({});
  const [t, i18n] = useTranslation('common');
  let history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(addBankAccountStart({...inputData, onSuccess: history.replace('/billing-accounts')}));
  };

  return (
    <div className="card-list-sec">
      <Container>
        <div className="go-back" onClick={() => history.push('/billing-accounts')}>
        <i className="fas fa-arrow-left"></i>
        </div>
        <h4 className="head-title">{t('accounts.add_current_account')}</h4>
        <Row>
          <Col sm={12} md={12}>
            <div className="add-bank-box">
              <Form onSubmit={handleSubmit}>
              <Col md={6}>
                  <Form.Group controlId="formHorizontalNickname">
                    <Form.Control
                      type="text"
                      placeholder={t('creator.account_name')}
                      value={inputData.nickname}
                      name="nickname"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          nickname: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formHorizontalBanlName">
                    <Form.Control
                      type="text"
                      placeholder={t('creator.bank_name')}
                      value={inputData.bank_name}
                      name="bank_name"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          bank_name: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formHorizontalAccountNumber">
                    <Form.Control
                      type="number"
                      placeholder={t('creator.account_number')}
                      value={inputData.account_number}
                      name="account_number"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          account_number: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group controlId="formHorizontalAccountName">
                    <Form.Control
                      type="text"
                      placeholder={t('creator.owned_by')}
                      value={inputData.account_holder_name}
                      name="account_holder_name"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          account_holder_name: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                 <Col md={6}>
                  <Form.Group controlId="formHorizontalIBANNumber">
                    <Form.Control
                      type="text"
                      placeholder="IBAN*"
                      value={inputData.iban_number}
                      name="iban_number"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          iban_number: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formHorizontalSWIFTCode">
                    <Form.Control
                      type="text"
                      placeholder={t('creator.swift_code')}
                      value={inputData.swift_code}
                      name="swift_code"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          swift_code: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formHorizontalIFSCCode">
                    <Form.Control
                      type="text"
                      placeholder={t('creator.ifsc_code')}
                      value={inputData.ifsc_code}
                      name="ifsc_code"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          ifsc_code: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                
                
                
                
                

           
               
                <div className="edit-save">
                  <Button
                    className="save-btn"
                    type="submit"
                    disabled={props.bankAccount.buttonDisable}
                  >
                    {props.bankAccount.loadingButtonContent !== null
                      ? props.bankAccount.loadingButtonContent
                      : t('forms.save')}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddBankIndex);
