import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Image, Media, Form } from "react-bootstrap";

import NoDataFound from "../NoDataFound/NoDataFound";
import PostDisplayCard from "../helper/PostDisplayCard";
import GLightbox from "glightbox";
import {getThumbnail} from "../helper/Thumbnail";

const ModelProfileVideoSec = (props) => {
  let lightbox = false;
  const [keyword, setKeyword] = useState('');
  
  const openLightBox = (event) => {
      event.preventDefault();
      
      if(!lightbox) {
          lightbox = GLightbox({
            touchNavigation: true,
            loop: true
          });
          lightbox.open(event.target);
      }
      
  };
  console.log(props.userPosts);
  
  return (
    <div
      role="tabpanel"
      className={
        props.activeSec === "video"
          ? "tab-pane fade in active"
          : "tab-pane fade"
      }
      id="Section3"
    >
      <div className="alignleft">
            <span className="post-number">
              {props.userPosts.loading ? (
                "Loading..."
              ) : (
                <span>{props.userPosts.data.total} Posts</span>
              )}
            </span>
    </div>
    {props.activeSec !== "video" ? "" : localStorage.getItem("userLoginStatus") === 'true' ? (
          <div className="alignright">
            <div className="profile-search-post">
              <Form className="search-box">
                <input
                  className="search-text"
                  type="text"
                  placeholder="Search Anything"
                  onChange={event => setKeyword(event.currentTarget.value)}
                />
                <Link to="#" className="search-btn">
                  <i class="fas fa-search"></i>
                </Link>
              </Form>
            </div>
          </div>
          ) : ""}
     
      <ul className="box-container three-cols model-prof-photo-sec model-prof-video-sec">
        {props.userPosts.loading ? (
          "Loading..."
        ) : 
            props.userPosts.data.posts.length > 0 ? localStorage.getItem("userLoginStatus") !== "true" ?
     (<PostDisplayCard post={props.userPosts.data.posts[0]} key={props.userPosts.data.posts[0].post_id} />)
     : (
          props.userPosts.data.posts.map((post) =>
            post.postFiles.length > 0
              ? post.postFiles.map((p_file) => !keyword.length || post.content.search(new RegExp(keyword, 'i')) != -1 ? (
                  <Media as="li" className="box">
                    <div className="inner">
                    {post.payment_info.is_user_needs_pay == 1 ? (
                              <Image
                                src={
                                  getThumbnail(p_file.preview_file
                                    ? p_file.preview_file
                                    : p_file.post_file, 372)
                                }
                                className="post-view-image blur"
                              /> 
                            ) : (<a href={p_file.post_file} class="glightbox" data-gallery="gallery1" onClick={openLightBox}>
                          <img src={getThumbnail(p_file.preview_file, 170)} alt="image" />
                      </a>)}
                    </div>
                    {post.payment_info.is_user_needs_pay ? <div className="label"><i class="fas fa-lock"></i></div> : <span class="play-button"><i class="fas fa-play"></i></span>}
                  </Media>
                ) : null)
              : ""
          )
        ) : (
          <NoDataFound></NoDataFound>
        )}
      </ul>
    </div>
  );
};

export default ModelProfileVideoSec;
