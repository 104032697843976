import React from "react";
import "./CardsIndex.css";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import configuration from "react-global-configuration";
import AddCardButton from "../../helper/AddCardButton";
import {useTranslation} from "react-i18next";

const AddCardPage = (props) => {
    let history = useHistory();
    const stripePromise = loadStripe(
            configuration.get("configData.stripe_publishable_key")
            );
    const [t, i18n] = useTranslation('common');
    const CARD_OPTIONS = {
        hideIcon: true,
        style: {
            base: {
                fontFamily: "Lato, sans-serif",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: 1.308,
            },
            "::placeholder": {
                color: "#9BA6B8"
            }
        }
    };

    return (<div className="card-list-sec">
        <Container>
            <Button className="chat-header-back" onClick={event => history.replace('/cards')}>
                <i class="fas fa-arrow-left"></i>
            </Button>
            <h4 className="head-title">{t('payment.add_a_credit_card')}</h4>
            <Elements stripe={stripePromise}>
                <Row className="add-card-form">
                    <Col sm={12} md={12}>
                    <div className="card-icon">
                        <img alt="card" src="/assets/images/icons/card.png" />
                    </div>
                    <CardElement options={CARD_OPTIONS} />
                    </Col>
                </Row>
                <AddCardButton afterCardAdded={() => history.push('/cards')}/>
            </Elements>
            
        </Container>
    </div>);
};

export default AddCardPage;