import React, { useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
PPVPaymentStripeStart,
        PPVPaymentWalletStart,
        PPVPaymentPaypalStart,
        PPVPaymentAppleStart
        } from "../../store/actions/PostAction";

import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
getSuccessNotificationMessage,
        getErrorNotificationMessage,
        } from "../../components/helper/NotificationMessage";
import configuration from "react-global-configuration";
import {getThumbnail} from "../helper/Thumbnail";
import {useTranslation} from "react-i18next";
import CardsSelect from "./CardsSelect";
import {
  fetchCardDetailsStart
} from "../../store/actions/CardsAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentAddCardModal from "./PaymentAddCardModal";

import "./PPVPaymentModal.css";

const PPVPaymentModal = (props) => {
    const [selectedCard, setSelectedCard] = useState(props.selectedCard);
    const [showCardsSelect, setShowCardsSelect] = useState(false);

    const {t, i18n} = useTranslation('common');
    const [paymentType, setPaymentType] = useState("card");

    const [showPayPal, payPal] = useState(false);

    const [paymentAddCard, setPaymentAddCard] = useState(false);
    
    const [buyButtonDisable, setBuyButtonDisable] = useState(false);
    const [buyLoadingContent, setBuyLoadingContent] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        setBuyButtonDisable(true);
        setBuyLoadingContent(t('buttons.loading_please_wait'));
        
        console.log('props:');
        console.log(props);
        if (paymentType === "paypal")
            showPayPal(true);
        
        if (typeof window.Payment !== 'undefined') {
            window.deliverProduct = function(verificationData) {
                props.dispatch(
                    PPVPaymentAppleStart({
                        post_id:
                                props.post_id != undefined || props.post_id != null
                                ? props.post_id
                                : "",
                        amount: props.amount,
                        user_id: props.user_id,
                        receiptData: verificationData,
                        onSuccess: () => {
                                setBuyButtonDisable(false);
                                setBuyLoadingContent('');
                                window.deliverProduct = null;
                            },
                        onError: () => {
                                setBuyButtonDisable(false);
                                setBuyLoadingContent('');
                                window.deliverProduct = null;
                            }
                    })
                    );
            };
            window.Payment.postMessage(JSON.stringify({
                    action: 'make_payment',
                    price: props.amount
                }));
        } else if (paymentType === "card")
            props.dispatch(
                    PPVPaymentStripeStart({
                        post_id:
                                props.post_id != undefined || props.post_id != null
                                ? props.post_id
                                : "",
                        amount: props.amount,
                        user_id: props.user_id,
                        card_token: selectedCard ? selectedCard.card_token : props.selectedCard.card_token,
                        onSuccess: () => {
                                setBuyButtonDisable(false);
                                setBuyLoadingContent('');
                                window.deliverProduct = null;
                            },
                        onError: () => {
                                setBuyButtonDisable(false);
                                setBuyLoadingContent('');
                                window.deliverProduct = null;
                            }
                    })
                    );
        if (paymentType === "wallet")
            props.dispatch(
                    PPVPaymentWalletStart({
                        post_id:
                                props.post_id != undefined || props.post_id != null
                                ? props.post_id
                                : "",
                        amount: props.amount,
                        user_id: props.user_id,
                    })
                    );
        if (paymentType === "paypal")
            props.closePPVPaymentModal();
    };

    const paypalOnSuccess = (payment) => {
        console.log(payment);
        setTimeout(() => {
            props.dispatch(
                    PPVPaymentPaypalStart({
                        payment_id: payment.paymentID,
                        post_id:
                                props.post_id != undefined || props.post_id != null
                                ? props.post_id
                                : "",
                        amount: props.amount,
                        user_id: props.user_id,
                    })
                    );
        }, 1000);
    };

    const paypalOnError = (err) => {
        const notificationMessage = getErrorNotificationMessage(err);
        this.props.dispatch(createNotification(notificationMessage));
    };

    const paypalOnCancel = (data) => {
        const notificationMessage = getErrorNotificationMessage(
                "Payment cancelled please try again.."
                );
        this.props.dispatch(createNotification(notificationMessage));
    };

    const choosePaymentOption = (event) => {
        setPaymentType(event);
    };

    let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
    let currency = "USD"; // or you can set this value from your props or state

    const client = {
        sandbox: configuration.get("configData.PAYPAL_ID"),
        production: configuration.get("configData.PAYPAL_ID"),
    };

    const addCard = (event) => {
        event.preventDefault();
        props.closePPVPaymentModal();
        setShowCardsSelect(false);
        setPaymentAddCard(true);
    };
    const openCardsSelect = (event) => {
        event.preventDefault();
        props.closePPVPaymentModal();

        setShowCardsSelect(true);
    };
    
    const hideCardsSelect = () => {
      setShowCardsSelect(false);
      props.openPPVPaymentModal();
  };
  
  const selectCard = (card) => {
      setSelectedCard(card);
      hideCardsSelect();
      props.dispatch(fetchCardDetailsStart());
  };
  
  const closePaymentAddCardModal = () => {
    setPaymentAddCard(false);
    props.openPPVPaymentModal();
  };
  
  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );
  

    return (<div>
        <Modal
            className="modal-dialog-center sent-tip-modal buy-content-extra payment-popup"
            size="md"
            show={props.PPVPayment}
            onHide={props.closePPVPaymentModal}
            >
            {props.PPVPayment === true ? (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <div className="profile-cover">
                                <Image
                                    src={getThumbnail(props.cover, 373)}
                                    alt={props.name}
                                    style={{width: "100%"}}
                                    />
                                <button class="close" onClick={props.closePPVPaymentModal}><i class="fas fa-times-circle"></i></button>
                            </div>
                            <div className="profile--user">
                                <span className="my-profile-status">
                                    <Image src={getThumbnail(props.userPicture, 117)} />
                                </span>
                                <div className="user-name">
                                    {props.name}
                                </div>
                                <span className="username">@{props.username}</span>
                            </div>
                            <div className="intro">
                                {t('payment.buy_extra_content')}
                            </div>
                            <div className="advantage-list">
                                <div className="item">
                                    <div className="icon">
                                        <img alt="thumbs-up" src="/assets/images/icons/thumbs-up.svg"/>
                                    </div>
                                    <div className="text">
                                        <div className="title">{t('profile.full_access')}</div>
                                        <div className="description">{t('profile.to_this_special')}</div>
                                    </div>
                                </div>
                            </div>
                            {!props.is_logged_in || typeof window.Payment !== 'undefined' ? ("") : selectedCard || props.selectedCard ? [selectedCard ? selectedCard : props.selectedCard].map(card =>
                                            (<div className="cards-list"><div className="card-item" onClick={openCardsSelect}>
                            
                                                    <div className="card-icon">
                                                        <img alt="card" src="/assets/images/icons/card.png" />
                                                    </div>
                                                    <div className="card-description">
                                                        <h5 className="mb-4">**** **** **** {card.last_four} {card.card_type}</h5>
                            
                                                        <div className="deadline">{t('payment.deadline')} {card.expiration_date}</div>
                                                    </div>
                                                    <a href="javascript:void(0)" className="select-card"><i class="fas fa-angle-right"></i></a>
                                                </div></div>)

                                        )
                                                    : (<div><button className="add-card" onClick={addCard}>{t('payment.add_a_credit_card')}</button></div>)
                            }
                            <div className="subscription-section">
                                <Button
                                    type="button"
                                    className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                                    data-dismiss="modal"
                                    onClick={handleSubmit}
                                    disabled={buyButtonDisable}
                                    >
                                    {buyLoadingContent !== '' ?
                                                        buyLoadingContent
                                                        : t('payment.buy_for', {price: props.amount})}
                                </Button>
                            </div>
                        </Modal.Body>
                    </Form>
                            ) : null}
        </Modal>
        <Elements stripe={stripePromise}>
        <PaymentAddCardModal
          paymentAddCard={paymentAddCard}
          closePaymentAddCardModal={closePaymentAddCardModal}
          afterCardAdded={selectCard}
        />
      </Elements>
        <CardsSelect 
            show={showCardsSelect} 
            hide={hideCardsSelect} 
            selectCard={selectCard}
            addCard={addCard}
            />
    </div>
            );
};

const mapStateToPros = (state) => ({
        ppvPayStripe: state.post.ppvPayStripe,
        is_logged_in: state.users.is_logged_in,
        selectedCard: state.cards.selectedCard
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(PPVPaymentModal);
