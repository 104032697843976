import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Image, Media } from "react-bootstrap";
import AddFavModal from "../../helper/AddFavModal";
import SendTipModal from "../../helper/SendTipModal";
import { deleteFavStart, saveFavStart } from "../../../store/actions/FavAction";
import { connect } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { saveBlockUserStart } from "../../../store/actions/UserAction";
import { unFollowUserStart } from "../../../store/actions/FollowAction";
import {getThumbnail} from "../../helper/Thumbnail";

const UserCard = (props) => {
  const [sendTip, setSendTip] = useState(false);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const [addFav, setAddFav] = useState(false);
  const [favStatus, setFavStatus] = useState("");
  
  const [badgeStatus, setBadgeStatus] = useState(props.user.is_verified_badge);
  const [blockUserStatus, setBlockUserStatus] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState("");

  const closeAddFavModal = () => {
    setAddFav(false);
  };

  const removeFav = () => {
    props.dispatch(deleteFavStart({ user_id: props.user.user_id }));
  };

  const handleStar = (event, status) => {
    event.preventDefault();
    setFavStatus(status);
    props.dispatch(
      saveFavStart({
        user_id: props.user.user_id,
      })
    );
  };
  const handleBlockUser = (event, status) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: props.user.user_id,
      })
    );
  };

  const handleUnfollowUser = (event, status) => {
    event.preventDefault();
    setSubscribeStatus(status);
    props.dispatch(
      unFollowUserStart({
        user_id: props.user.user_id,
      })
    );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Link to profile was copied to clipboard!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  return (
    <>
      <Col sm={12} md={6} lg={4} xs={12}>
        <div className="follower-lists">
          <div className="follower-subscription-lists">
            <div className="follower-subscription-inner">
              <Link to={`/` + props.user.user_unique_id}>
                <div className="follower-wrapper">
                  <Image className="follower-cover" src={getThumbnail(props.user.cover, 372)} />
                </div>
              </Link>
              <div className="follower-profile-header">
                <Link to={`/` + props.user.user_unique_id}>
                  <span className="follower-profile-avatar">
                    <Image src={getThumbnail(props.user.picture, 53)} className="" />
                  </span>
                </Link>
                <div className="follower-info">
                  <div className="follower-wrapper-name">
                    <div className="follower-profile-names">
                      <div className="follower-name-row">
                        <Link to={props.user.user_unique_id}>
                          <div className="follower-user-name">
                            {props.user.name}
                          </div>
                        </Link>
                      </div>
                      <div className="follower-name-row">
                        <Link
                          to={`/` + props.user.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-id">
                            @{props.user.username}
                          </div>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
                <Button
                  type="button"
                  className="g-btn m-rounded m-border m-profile"
                  onClick={() => setSendTip(true)}
                >
                  <span className="b-btn-text">Mancia</span>
                </Button>
              </div>
                
              <div className="lists-button-group" style={{ display: "none" }}>
                <Link
                  type="button"
                  className="g-btn m-rounded m-border m-profile m-with-icon"
                >
                  <Image
                    src="assets/images/icons/subscribe.svg"
                    className="svg-clone"
                  />
                  <span className="b-btn-text">Iscriviti gratis</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <SendTipModal
        sendTip={sendTip}
        closeSendTipModal={closeSendTipModal}
        username={props.user.username}
        userPicture={props.user.picture}
        name={props.user.usernamee}
        post_id={null}
        user_id={props.user.user_id}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(UserCard);
