import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { connect } from "react-redux";
import {registerVerifyStart} from "../../store/actions/UserAction";
import { useHistory } from "react-router-dom";

import "./VerifyEmailForm.css";

const VerifyEmailForm = (props) => {
    const {t, i18n} = useTranslation('common');
    const history = useHistory();
    const character1 = useRef(null);
    const character2 = useRef(null);
    const character3 = useRef(null);
    const character4 = useRef(null);
    const [verifyInputData, setVerifyInputData] = useState({});
    
    const handleInput = (event, nextCharacter = null) => {
        if(nextCharacter && event.target.value) {
            nextCharacter.current.focus();
        } 
        setVerifyInputData({...verifyInputData, 
            verification_code: character1.current.value + 
                    character2.current.value + character3.current.value + 
                    character4.current.value});
    };
    
    const sendForm = (event) => {
        event.preventDefault();
        
        props.dispatch(registerVerifyStart({...verifyInputData, 
            afterVerified: typeof props.afterVerified !== "undefined" ? props.afterVerified : data => history.push('/home')}));
    };
    
    return (<div className="verify-email-section">
            <form onSubmit={sendForm}>
            <h2>{t('login.confirm_email')}</h2>
             <p>{t('login.check_your_inbox')}</p>
            <div className="code-input">
            <input autoFocus onChange={event => {handleInput(event, character2)}} ref={character1}/>
            <input onChange={event => {handleInput(event, character3)}} ref={character2}/>
            <input onChange={event => {handleInput(event, character4)}} ref={character3}/>
            <input onChange={event => {handleInput(event)}} ref={character4}/>
            </div>
            <button type="submit" disabled={props.registerVerify.buttonDisable}>{props.registerVerify.buttonLoadingContent ? props.registerVerify.buttonLoadingContent : t('forms.send')}</button>
            </form>
           </div>);
};

const mapStateToPros = (state) => ({
  registerVerify: state.users.registerVerify
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(VerifyEmailForm);