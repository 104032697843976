import {
  SAVE_COMMENT_LIKE_START,
  SAVE_COMMENT_LIKE_SUCCESS,
  SAVE_COMMENT_LIKE_FAILURE,
  FETCH_COMMENT_LIKED_START,
  FETCH_COMMENT_LIKED_SUCCESS,
  FETCH_COMMENT_LIKED_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  saveLike: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  likes: {
    data: {},
    loading: true,
    error: false,
  },
};

const CommentLikesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COMMENT_LIKE_START:
      return {
        ...state,
        saveLike: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case SAVE_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        saveLike: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case SAVE_COMMENT_LIKE_FAILURE:
      return {
        ...state,
        saveLike: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_COMMENT_LIKED_START:
      return {
        ...state,
        likes: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_COMMENT_LIKED_SUCCESS:
      return {
        ...state,
        likes: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_COMMENT_LIKED_FAILURE:
      return {
        ...state,
        likes: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default CommentLikesReducer;
