import React from "react";
import { Row, Col} from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BookmarkNoDataFound = () => {
    const [t, i18n] = useTranslation('common');
    
    return (
            <div className="bookmark-no-data-found-sec">
                <Row>
                    <Col sm="12" md="12">
                    <span>
                    <i className="fas fa-bookmark"></i>
                    </span>
                    <p className="desc">{t('home.no_posts_saved_yet')}</p>
                    </Col>
                </Row>
            </div>
            );
};

export default BookmarkNoDataFound;
