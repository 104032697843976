import React, { useState, useRef } from "react";
import { Dropdown, Image, Media, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { saveBookmarkStart } from "../../store/actions/BookmarkAction";
import {
  fetchCommentsStart,
  saveCommentStart,
} from "../../store/actions/CommentsAction";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import ImageLoader from "./ImageLoader";
import SendTipModal from "./SendTipModal";
import ReportModeModal from "./ReportModeModal";
import PPVPaymentModal from "./PPVPaymentModal";
import ReactPlayer from "react-player/lazy";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import {
  deletePostStart,
  saveReportPostStart,
  savePostViewStart
} from "../../store/actions/PostAction";
import { saveBlockUserStart } from "../../store/actions/UserAction";
import {useTranslation} from "react-i18next";
import TimeAgo from "./FormatTime";
import EmojiList from "./EmojiList";
import Comment from "./Comment";
import {getThumbnail} from "../helper/Thumbnail";



const PostDisplayCard = (props) => {
 
  const {t, i18n} = useTranslation('common');
  
  const { post } = props;
  let totalLikes = props.post.total_likes ? props.post.total_likes : 0;

  const [PPVPayment, setPPVPayment] = useState(false);
  const [sendTip, setSendTip] = useState(false);
  const [commentInputData, setCommentInputData] = useState({});
  const [isVisible, setIsVisible] = useState(true);

  const [reportMode, setReportMode] = useState(false);
  const [totalComments, setTotalComments] = useState(post.total_comments);

  const closeReportModeModal = () => {
    setReportMode(false);
  };

  const [bookmarkStatus, setBookmarkStatus] = useState("");
  const [postDisplayStatus, setPostDisplayStatus] = useState(true);
  const [likeStatus, setLikeStatus] = useState("");
  const [likeCount, setLikeCount] = useState(totalLikes);

  const closeSendTipModal = () => {
    setSendTip(false);
  };
  const closePPVPaymentModal = () => {
    setPPVPayment(false);
  };
  const unlockPost = (event) => {
      event.preventDefault();
      setPPVPayment(true);
  }

  const handleCommentSubmit = (event) => {
    event.preventDefault();
    
    props.dispatch(saveCommentStart(commentInputData));
    setCommentInputData({...commentInputData, reply_id: 0, comment: ''});
    setTotalComments(totalComments + 1);
  };

  const showCommentSection = (event, post_id) => {
    setCommentInputData({ post_id: post_id });
    setIsVisible(true);
    props.dispatch(fetchCommentsStart({ post_id: post_id, limit: 3 }));
  };

  const handleLike = (event, status) => {
    event.preventDefault();
    setLikeStatus(status);
    props.dispatch(savePostLikeStart({ post_id: post.post_id }));
    if (status == "added") {
      let currentLikeCount = likeCount + 1;
      setLikeCount(currentLikeCount);
    } else {
      let currentLikeCount = likeCount - 1;
      setLikeCount(currentLikeCount);
    }
  };

  const handleBookmark = (event, post, status) => {
    event.preventDefault();
    setBookmarkStatus(status);
    props.dispatch(saveBookmarkStart({ post_id: post.post_id }));
  };

  const handleReportPost = (event, post) => {
    event.preventDefault();
    setPostDisplayStatus(false);
    props.dispatch(saveReportPostStart({ post_id: post.post_id }));
  };
  const handleBlockUser = (event, post) => {
    event.preventDefault();
    setPostDisplayStatus(false);
    props.dispatch(saveBlockUserStart({ user_id: post.user_id }));
  };

  const handleDeletePost = (event, post) => {
    event.preventDefault();
    let agree = window.confirm(t('post.are_you_sure'));
    if(agree) {
      setPostDisplayStatus(false);
      props.dispatch(deletePostStart({ post_id: post.post_id }));
    }   
  };

  const closeCommentSection = (event) => {
    setIsVisible(false);
  };
  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Link to the post was copied to clipboard!"
    );
    props.dispatch(createNotification(notificationMessage));
  };
  const commentBoxRef = useRef(null);
  const commentTextareaRef = useRef(null);
  
  const reply = (event, comment_id) => {
      event.preventDefault();
      commentBoxRef.current.scrollIntoView();
      commentTextareaRef.current.focus();
      
      setCommentInputData({...commentInputData, reply_id: comment_id});
      
  }
  
  const loadMoreComments = (event, post_id) => {
    props.dispatch(fetchCommentsStart({ post_id: post_id}));
  };
  
  const insertEmoji = (event, character) => {
      event.preventDefault();
      let comment = typeof commentInputData.comment !== "undefined" ? commentInputData.comment : "";
      setCommentInputData({...commentInputData, comment: comment + character});
      commentTextareaRef.current.focus();
  };
  
  const savePostView = () => {
      props.dispatch(savePostViewStart({
          post_id: post.post_id
      }));
  };

  return (
    <>
      {postDisplayStatus == true ? (
        <div className="post-list">
          {localStorage.getItem("userLoginStatus") === "true" ? (<div className="post-header">
            <div className="alignleft">
              <Link className="title-container" to={`/${post.user_unique_id}`}>
                <ImageLoader
                  image={getThumbnail(post.user_picture, 53)}
                  className="user-image img-responsive"
                />

                <div className="user-name">
                  <span className="post-user-name">
                    {post.user_displayname}
                    {"  "}
                    
                    {post.is_paid_post ? (<i class="fas fa-crown"></i>) : post.payment_info.monthly_amount > 0 ? (<i class="fas fa-lock"></i>) : (<i class="fas fa-globe-africa"></i>)}
                  </span>
                  
                  <span className="post-username">@{post.username}</span>
                </div>
              </Link>
            </div>
            <div className="alignright">
              <div className="post-header-right-side">
                <span className="post-time">
                  <span className="post-time">
                    {post.publish_time_formatted}
                  </span>
                  
                </span>
                {props.visitorData.user_id == post.user_id ? 
                <a href="javascript:void(0)" onClick={event => handleDeletePost(event, post)} className="delete">{t('post.delete')}</a>
                : ""}
              </div>
            </div>
          </div>) : ""}

          <div className="post-content">
            <p>{typeof post.content !== "undefined" && post.payment_info.is_user_needs_pay != 1 ? post.content : ""}</p>
            {post.postFiles
              ? post.postFiles.length > 0
                ? post.postFiles.map((postFile, index) =>
                    (<div className="post-image" key={index}>
                        <div className="">
                          <div className="gallery js-gallery">
                            {localStorage.getItem("userLoginStatus") !== "true" ? 
                            (<div className="placeholder"><i class="fas fa-lock"></i></div>) : postFile.file_type === "image" ? (<Image
                              src={getThumbnail(postFile.post_file, 1100)}
                              className="post-view-image"
                            />) : postFile.file_type === "video" ? post.payment_info.is_user_needs_pay == 1 ? (
                              <Image
                                src={
                                  getThumbnail(postFile.preview_file
                                    ? postFile.preview_file
                                    : postFile.post_file, 372)
                                }
                                className="post-view-image blur"
                              />    
                            ) : (
                              <ReactPlayer
                                light={postFile.preview_file}
                                url={postFile.post_file}
                                controls={true}
                                width="100%"
                                height="360px"
                                onStart={savePostView}
                              />
                   
                              ) : ("")}
                            {typeof props.onClickSubscribe !== "undefined" && ((post.payment_info.is_user_needs_pay == 1 &&
                            !post.is_paid_post) || (localStorage.getItem("userLoginStatus") !== "true"))
                            ? (<Button
                            to=""
                            className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                            onClick={props.onClickSubscribe}
                          >
                            {post.payment_info.payment_text}
                            </Button>) : 
                            post.is_paid_post && post.payment_info.is_user_needs_pay == 1 ?(
                                    <Button
                            to=""
                            className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile unblock-post"
                            onClick={unlockPost}
                          >
                            <i class="far fa-crown"></i> {t("post.unlock_post_for")} €{post.amount}
                            </Button>) : ""}
                            {post.payment_info.is_user_needs_pay == 1 && 
                                    localStorage.getItem("userLoginStatus") === "true" ? (<div className="label"><i class="fas fa-lock"></i></div>) : ""}
                            
                          </div>
                        </div>
                      </div>)
                  )
                : null
              : null}
          </div>

          {localStorage.getItem("userLoginStatus") === "true" ? (<div className="post-icons">
            <div className="alignleft">
              {likeStatus !== "" ? (
                <>
                  <>
                    {likeStatus === "added" ? (
                      <Link
                        to="#"
                        onClick={(event) => handleLike(event, "removed")}
                      >
                        <i class="fas fa-heart"></i>
                      </Link>
                    ) : null}
                  </>
                  <>
                    {likeStatus === "removed" ? (
                      <Link
                        to="#"
                        onClick={(event) => handleLike(event, "added")}
                      >
                        <i class="far fa-heart"></i>
                      </Link>
                    ) : null}
                  </>
                </>
              ) : post.is_user_liked == 1 ? (
                <Link to="#" onClick={(event) => handleLike(event, "removed")}>
                  <i className="fas fa-heart"></i>
                </Link>
              ) : (
                <Link to="#" onClick={(event) => handleLike(event, "added")}>
                  <i className="far fa-heart"></i>
                </Link>
              )}

              <Link
                to="#"
                onClick={(event) => showCommentSection(event, post.post_id)}
                className={isVisible && commentInputData.post_id === post.post_id  ? "active" : ""}
              >
                <i className="far fa-comment"></i>
              </Link>

              <Link
                className="post-tip"
                onClick={() => setSendTip(true)}
              >
                <i class="far fa-coins"></i>

                <span>Dai una mancia</span>
              </Link>
            </div>
            <div className="alignright">
              {bookmarkStatus !== "" ? (
                <>
                  <>
                    {bookmarkStatus === "added" ? (
                      <Link
                        to="#"
                        onClick={(event) =>
                          handleBookmark(event, post, "removed")
                        }
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/icons/bookmark-active.svg"
                          }
                          className="svg-clone"
                        />
                      </Link>
                    ) : null}
                  </>
                  <>
                    {bookmarkStatus === "removed" ? (
                      <Link
                        to="#"
                        onClick={(event) =>
                          handleBookmark(event, post, "added")
                        }
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/icons/bookmark.svg"
                          }
                          className="svg-clone"
                        />
                      </Link>
                    ) : null}
                  </>
                </>
              ) : post.is_user_bookmarked == 1 ? (
                <Link
                  to="#"
                  onClick={(event) => handleBookmark(event, post, "removed")}
                >
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/icons/bookmark-active.svg"
                    }
                    className="svg-clone"
                  />
                </Link>
              ) : (
                <Link
                  to="#"
                  onClick={(event) => handleBookmark(event, post, "added")}
                >
                  <i class="far fa-bookmark"></i>
                </Link>
              )}
            </div>
          </div>) : ""}

          {localStorage.getItem("userLoginStatus") === "true" ? (<div className="likes alignleft">
          <p>{likeCount} Mi piace | <span className="clickable" onClick={(event) => showCommentSection(event, post.post_id)}>{totalComments} {t('post.comments')}</span> | €{post.total_tips_amount} {t('post.tips')}</p>
            {isVisible && commentInputData.post_id === post.post_id ? (
              <div id="target">
                {props.comments.loading
                  ? "Loading..."
                  : props.comments.data.post_comments.length > 0
                  ? props.comments.data.post_comments.map((comment) => (
                      <Comment comment={comment} replyHandler={(event) => reply(event, comment.post_comment_id)}/>
                    ))
                  : ""}
                  
                  {!props.comments.loading && props.comments.data.total > props.comments.data.post_comments.length ? (
                          <div className="load-more-comments clickable" onClick={(event) => loadMoreComments(event, post.post_id)}>
                          {t("post.see_more_comments")}
                          </div>
                          ) : ("")}
                <div className="comment-box" ref={commentBoxRef}>
                  <div className="comment-box-form">
                  <EmojiList insertEmoji={insertEmoji}/>
                    <Form
                      action=""
                      onSubmit={handleCommentSubmit}
                    >
                      <div className="text-box">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          className="form-control"
                          id="comment"
                          placeholder={t('post.enter_a_comment')}
                          name="comment"
                          value={commentInputData.comment}
                          onChange={(event) =>
                            setCommentInputData({
                              ...commentInputData,
                              comment: event.currentTarget.value,
                            })
                          }
                          ref={commentTextareaRef}
                        />
                      </div>
                      <Button
                        type="submit"
                        className="custom-btn"
                        onClick={handleCommentSubmit}
                      >
                        <i class="fas fa-paper-plane"></i>
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            ) : null}
          </div>) : ""}
          <SendTipModal
            sendTip={sendTip}
            closeSendTipModal={closeSendTipModal}
            username={post.username}
            userPicture={post.user_picture}
            name={post.user_displayname}
            post_id={post.post_id}
            user_id={post.user_id}
          />
          <PPVPaymentModal
            PPVPayment={PPVPayment}
            closePPVPaymentModal={closePPVPaymentModal}
            post={post}
            username={post.username}
            userPicture={post.user_picture}
            name={post.user_displayname}
            post_id={post.post_id}
            user_id={post.user_id}
            amount={post.amount}
            cover={post.user.cover}
            openPPVPaymentModal={() => setPPVPayment(true)}
          />
          <ReportModeModal
            reportMode={reportMode}
            closeReportModeModal={closeReportModeModal}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  comments: state.comment.comments,
  visitorData: state.users.profile.data
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PostDisplayCard);
