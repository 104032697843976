import {
CHANGE_PASSWORD_START,
        CHANGE_PASSWORD_SUCCESS,
        CHANGE_PASSWORD_FAILURE,
        EDIT_CHANGE_PASSWORD,
} from "../actions/ActionConstant";

const initialState = {
    inputData: {
        data: {},
        loading: true,
        error: false,
        success: {},
    },
    buttonDisable: false,
    loadingButtonContent: null,
    resultMessage: '',
    showResult: false,
    resultSuccess: null
};

const ChangePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_START:
            return {
                ...state,
                inputData: {
                    data: action.data,
                    loading: true,
                    error: false,
                },
                buttonDisable: true,
                loadingButtonContent: "Attendere per cortesia",
                resultMessage: '',
                showResult: false,
                resultSuccess: null
            };
        case CHANGE_PASSWORD_SUCCESS:
            console.log('action:');
            console.log(action);
            return {
                ...state,
                inputData: {
                    data: {},
                    success: action.data,
                    loading: false,
                    error: false,
                },
                buttonDisable: false,
                loadingButtonContent: null,
                resultMessage: action.data.success ? action.data.message : action.data.error,
                showResult: true,
                resultSuccess: action.data.success
            };
        case CHANGE_PASSWORD_FAILURE:
            console.log('action:');
            console.log(action);
            return {
                ...state,
                inputData: {
                    data: {},
                    loading: true,
                    error: action.data.error,
                },
                buttonDisable: false,
                loadingButtonContent: null,
                resultMessage: action.data.error,
                showResult: true,
                resultSuccess: false
            };
        case EDIT_CHANGE_PASSWORD:
            return {
                ...state,
                inputData: {
                    loading: false,
                    error: false,
                    data: {
                        ...state.inputData.data,
                        [action.name]: action.value,
                    },
                },
            };
        default:
            return state;
}
};

export default ChangePasswordReducer;
