import React, { useState } from "react";
import "./BillingAccountIndex.css";
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router-dom";
import { Container, Button, Modal } from "react-bootstrap";
import { selectDefaultCardStart } from "../../../store/actions/CardsAction";
import { connect } from "react-redux";
import Environment from "../../../Environment";
import {
deleteBankAccountStart,
makeDefaultBankAccountStart,
} from "../../../store/actions/BankAccountAction";

const ViewBankPage = (props) => {
    const [t, i18n] = useTranslation('common');


    return (<div className="card-list-sec view-bank">
        <Container>
            <Button className="chat-header-back" onClick={event => props.goBack()}>
                <i class="fas fa-arrow-left"></i>
            </Button>
            <h4 className="head-title">{props.account.bank_name}</h4>
            <div className="icon">
            <i className="fas fa-university"></i>
            </div>
            <div className="account-description">
                <h5 className="mb-4">{props.account.bank_name}</h5>
                <div className="account-number">{props.account.account_number}</div>
            </div>
            {!props.account.is_default ? <Button className="set-as-main" onClick={() => {
                                    props.dispatch(
                                            makeDefaultBankAccountStart({
                                                user_billing_account_id:
                                                        props.account.user_billing_account_id,
                                            })
                                            );
                                    props.goBack();
                                }
                                      }>{t('cards.set_as_main')}</Button> : <div className="default">
                                <div className="icon">
                                <i className="fas fa-check-circle"></i>
                                </div>
                                <span>{t('accounts.main')}</span></div>}
            <button className="cancel-button" onClick={() =>
                        props.dispatch(
                                deleteBankAccountStart({
                                    user_billing_account_id:
                                            props.account.user_billing_account_id,
                                })
                                )
                                    }>{t('cards.cancell')}</button>
    
        </Container>
    
    </div>);
};

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(null, mapDispatchToProps)(ViewBankPage);