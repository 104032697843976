import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { addCardStart } from "../../store/actions/CardsAction";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "./NotificationMessage";
import { createNotification } from "react-redux-notify";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

const AddCardButton = (props) => {
    const [addCardButtonDisable, setAddCardButtonDisable] = useState(false);
    const [addCardLoadingContent, setAddCardLoadingContent] = useState(null);
    const [t, i18n] = useTranslation('common');
    const stripe = useStripe();
    const elements = useElements();

    const addCard = async (ev) => {
        ev.preventDefault();
        setAddCardButtonDisable(true);
        setAddCardLoadingContent(t('buttons.loading_please_wait'));
        if (stripe) {
            await stripe
                    .createPaymentMethod({
                        type: "card",
                        card: elements.getElement(CardElement),
                    })
                    .then((payload) => {
                        // console.log("Payload", payload);
                        const inputData = {
                            card_token: payload.paymentMethod.id,
                            afterCardAdded: () => {
                                setAddCardButtonDisable(false);
                                setAddCardLoadingContent(null);
                                props.afterCardAdded();
                            }
                        };
                        props.dispatch(addCardStart(inputData));
                    })
                    .catch((error) => {
                        console.log("Eroor", error);
                        setAddCardButtonDisable(false);
                        setAddCardLoadingContent(null);
                        const notificationMessage = getErrorNotificationMessage(
                                "Please check your card details and try again.."
                                );
                        props.dispatch(createNotification(notificationMessage));
                    });
        } else {
            setAddCardButtonDisable(false);
            setAddCardLoadingContent(null);
            const notificationMessage = getErrorNotificationMessage(
                    "Stripe is not configured"
                    );
            props.dispatch(createNotification(notificationMessage));
        }
    };
    return (<Button
        type="submit"
        className="btn btn-success width-btn"
        data-dismiss="modal"
        onClick={addCard}
        disabled={addCardButtonDisable}
        >
        {addCardLoadingContent != null ? addCardLoadingContent : t('payment.save')}
    </Button>);
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(AddCardButton);