import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import UserCard from "../FansFollowing/UserCard";
import { connect } from "react-redux";
import { fetchFavStart } from "../../../store/actions/FavAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import useInfiniteScroll from "../../helper/useInfiniteScroll";
import { Link } from "react-router-dom";
import "../FansFollowing/Following/FollowingIndex.css";
import { useTranslation } from "react-i18next";
import FollowingActiveSec from "../FansFollowing/Following/FollowingActiveSec";

const FavoritesIndex = (props) => {
    const [t, i18n] = useTranslation('common');
    useEffect(() => {
        props.dispatch(fetchFavStart());
    }, []);

    const fetchFavUsersData = () => {
        setTimeout(() => {
            if (typeof props.posts !== 'undefined' && props.posts.length !== 0) {
                props.dispatch(fetchFavStart());
                setIsFetching(false);
            } else {
                setNoMoreData(true);
            }
        }, 3000);
    };

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchFavUsersData);
    const [noMoreData, setNoMoreData] = useState(false);

    const [sendTip, setSendTip] = useState(false);

    const closeSendTipModal = () => {
        setSendTip(false);
    };


    return (
            <div className="following">
                <Container>
                    <Row>
                        <Col sm={12} md={12} xs={12}>
                        <div className="profile-post-area">
                            <div className="pull-left">
                                <Link className="go-back" to={'/list'}>
                                <i className="fas fa-arrow-left"></i>
                                </Link>
                            </div>
                            <h1>{t('lists.favorites')}</h1>
                        </div>
                        <div className="listing-tab">
                            <div className="tab" role="tabpanel">
            
                                <div className="tab-content tabs">
                                    {props.fav.loading ? (
                                                "Loading..."
                                                ) :
                            <FollowingActiveSec
                                users={props.fav.data.fav_users}
                                />}
                                </div>
                            </div>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            );
};

const mapStateToPros = (state) => ({
        fav: state.fav.fav,
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(FavoritesIndex);
