import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./HelpIndex.css";
import { Form, Button } from "react-bootstrap";
import { askHelpStart } from "../../store/actions/HelpAction";
import { connect } from "react-redux";

const HelpIndex = (props) => {
    let history = useHistory();
    const [t, i18n] = useTranslation('common');
    const [inputData, setInputData] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(askHelpStart({...inputData, onSuccess: () => history.push('/home')}));
    };

    return (<div className="help-page">
        <div className="pull-left">
            <a href="javascript:void(0);"
               className="go-back"
               onClick={() => history.goBack()}
               >
                <i class="fas fa-arrow-left"></i>
            </a>
            <h1>{t('help.help')}</h1>
        </div>
        <div className="description">{t('help.fill_out_form')}</div>
        <Form className="help-form" onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
                <Form.Control
                    type="text"
                    placeholder={t('help.name')}
                    name="name"
                    value={inputData.name}
                    onChange={(event) => {
                        setInputData({
                          ...inputData,
                          name: event.currentTarget.value,
                        });
                      }}
                    />
            </Form.Group>
            <Form.Group controlId="formSurname">
                <Form.Control
                    type="text"
                    placeholder={t('help.surname')}
                    name="name"
                    value={inputData.surname}
                    onChange={(event) => {
                        setInputData({
                          ...inputData,
                          surname: event.currentTarget.value,
                        });
                      }}
                    />
            </Form.Group>
            <Form.Group controlId="formPhone">
                <Form.Control
                    type="text"
                    placeholder={t('help.phone')}
                    name="phone"
                    value={inputData.phone}
                    onChange={(event) => {
                        setInputData({
                          ...inputData,
                          phone: event.currentTarget.value,
                        });
                      }}
                    />
            </Form.Group>
            <Form.Group controlId="formMessage">
                <Form.Control
                    as="textarea"
                    placeholder={t('help.message')}
                    name="message"
                    value={inputData.message}
                    onChange={(event) => {
                        setInputData({
                          ...inputData,
                          message: event.currentTarget.value,
                        });
                      }}
                    />
            </Form.Group>
            <div className="edit-save">
                <Button
                    className="save-btn"
                    type="submit"
                    disabled={props.help.buttonDisable}
                    >
                    {props.help.loadingButtonContent !== null
                      ? props.help.loadingButtonContent : t('forms.send')}
                </Button>
            </div>
        </Form>
    </div>);
};

const mapStateToPros = (state) => ({
  help: state.help.askHelpInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HelpIndex);

