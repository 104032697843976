import React, {useRef, useEffect} from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TimeAgo from "../helper/FormatTime";

const ChatUserList = (props) => {
  const { chatUsers } = props;
  const [t, i18n] = useTranslation('common');
  
  const activeChatRef = useRef(null);
  useEffect(() => {
      if(window.localStorage.getItem('activeChat')) {
          activeChatRef.current.click();
          window.localStorage.removeItem('activeChat');
      }
  }, [chatUsers.users]);
  
  
  return (
          
          props.activeChat !== null ? null :
    <Col sm={12} md={12} lg={5} xl={4} className="padding-msg resp-mrg-btn-xs">
      <div className="chat-content">
        <div className="chat-header">
          <Link className="chat-header-back" to={`/home`}>
            <i class="fas fa-arrow-left"></i>
          </Link>
          <h1 className="chat-page-title">{t('chat.messages')}</h1>

          <Link type="button" className="search-btn-1 hidden">
            <Image src="assets/images/icons/search.svg" className="svg-clone" />
          </Link>

          <Link to="#" className="add-icon hidden">
            <Image src="assets/images/icons/plus.svg" className="svg-clone" />
          </Link>
        </div>

        <div>
          <div className="friend-chat-list-scroll">

            {chatUsers.users.map((chatUser, index) => (
                    !chatUser.to_user || !chatUser.from_user ? null :
              <div key={index} className={"chat-item-wrapper "+(chatUser.is_read == 2 ? 'read' : 'unread')}>
                <div
                  className="chat-item"
                >
                  <Link to="#" className="friend-profile">
                    <Image
                      src={chatUser.to_userpicture}
                      alt={chatUser.to_displayname}
                    />
                  </Link>
                  <Link
                    to="#"
                    className="chat-msg"
                    onClick={(event) =>
                      props.changeUser(event, chatUser, index)
                    }
                    ref={chatUser.chat_user_id == window.localStorage.getItem('activeChat') ? activeChatRef : null}
                  >
                    <div className="friend-name-row">
                      <div className="friend">
                        <span className="friend-name">
                          {chatUser.to_displayname}
                        </span>
                      </div>
                    </div>
                    <div className="chat-time">
                        <span title={chatUser.time_formatted}>
                          <TimeAgo date={chatUser.created_at}/>
                        </span>
                      </div>
                    <div className="chat-body">
                      <div className="chat-last-message">
                        <span className="chat-last-message-text">
                          {chatUser.message}
                        </span>
                        <span className="emoji-outer emoji-sizer"></span>
                      </div>
                      
                    </div>
                  </Link>
                </div>
                {chatUser.is_read == 2 ? <hr className="solid" /> : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ChatUserList;
