import React, { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import api from "../../Environment";
import { connect } from "react-redux";
import {
subscriptionPaymentStripeStart,
        subscriptionPaymentPaypalStart,
        subscriptionPaymentAppleStart
        } from "../../store/actions/SubscriptionAction";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
getSuccessNotificationMessage,
        getErrorNotificationMessage,
        } from "./NotificationMessage";
import configuration from "react-global-configuration";
import {useTranslation} from "react-i18next";
import PaymentAddCardModal from "./PaymentAddCardModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
fetchCardDetailsStart
        } from "../../store/actions/CardsAction";
import NoDataFound from "../NoDataFound/NoDataFound.jsx";
import {getThumbnail} from "./Thumbnail";
import CardsSelect from "./CardsSelect";

const PaymentModal = (props) => {

    const {t, i18n} = useTranslation('common');
    const [debugInfo, setDebugInfo] = useState('');
    const [paymentType, setPaymentType] = useState("card");
    const [userHasNoCards, setUserHasNoCards] = useState(false);

    const [showPayPal, payPal] = useState(false);
    const [showCardsSelect, setShowCardsSelect] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [selectedCard, setSelectedCard] = useState(props.selectedCard);

    const [subscribeButtonDisable, setSubscribeButtonDisable] = useState(false);
    const [subscribeLoadingContent, setSubscribeLoadingContent] = useState(null);

    const bottomRef = useRef(null);


    const hideCardsSelect = () => {
        setShowCardsSelect(false);
        props.openPaymentModal();
    };

    const hideSuccessModal = () => {
        setShowSuccessModal(false);
        props.onSuccess();
    }

    let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
    let currency = "USD"; // or you can set this value from your props or state

    const client = {
        sandbox: configuration.get("configData.PAYPAL_ID"),
        production: configuration.get("configData.PAYPAL_ID"),
    };

    useEffect(() => {
        if (props.is_logged_in) {
            props.dispatch(fetchCardDetailsStart());
        }

    }, []);
    





    const choosePaymentOption = (event) => {
        setPaymentType(event);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (props.is_logged_in) {
            setSubscribeButtonDisable(true);
            setSubscribeLoadingContent(t('buttons.loading_please_wait'));
            
            if (typeof window.Payment !== 'undefined') {
                window.deliverProduct = function(verificationData) {
                    props.dispatch(
                        subscriptionPaymentAppleStart({
                            user_unique_id: props.user_unique_id,
                            plan_type: props.subscriptionData.plan_type,
                            receiptData: verificationData,
                            is_free: props.subscriptionData.is_free,
                            onSuccess: () => {
                                setSubscribeButtonDisable(false);
                                setSubscribeLoadingContent(null);
                                props.closePaymentModal();
                                setShowSuccessModal(true);
                                window.deliverProduct = null;
                            },
                            onError: () => {
                                setSubscribeButtonDisable(false);
                                setSubscribeLoadingContent(null);
                                window.deliverProduct = null;
                            }
                        })
                        );
                };
                window.Payment.postMessage(JSON.stringify({
                    action: 'make_payment',
                    user_id: props.userDetails.data.user.user_id,
                    price: props.userDetails.data.payment_info.subscription_info.monthly_amount
                }));
            } else if (paymentType === "card") {
                
                
                props.dispatch(
                        subscriptionPaymentStripeStart({
                            user_unique_id: props.user_unique_id,
                            plan_type: props.subscriptionData.plan_type,
                            is_free: props.subscriptionData.is_free,
                            card_token: selectedCard ? selectedCard.card_token : props.selectedCard.card_token,
                            onSuccess: () => {
                                setSubscribeButtonDisable(false);
                                setSubscribeLoadingContent(null);
                                props.closePaymentModal();
                                setShowSuccessModal(true);
                            },
                            onError: () => {
                                setSubscribeButtonDisable(false);
                                setSubscribeLoadingContent(null);
                            }
                        })
                        );
            }

            if (paymentType === "paypal")
                showPayPal(true);
        } else {
            props.closePaymentModal();
            props.openLoginModal();
        }



    };

    const paypalOnSuccess = (payment) => {
        console.log(payment);
        setTimeout(() => {
            props.dispatch(
                    subscriptionPaymentPaypalStart({
                        payment_id: payment.paymentID,
                        user_unique_id: props.user_unique_id,
                        plan_type: props.subscriptionData.plan_type,
                        is_free: props.subscriptionData.is_free,
                    })
                    );
        }, 1000);
    };

    const paypalOnError = (err) => {
        const notificationMessage = getErrorNotificationMessage(err);
        this.props.dispatch(createNotification(notificationMessage));
    };

    const paypalOnCancel = (data) => {
        const notificationMessage = getErrorNotificationMessage(
                "Payment cancelled please try again.."
                );
        this.props.dispatch(createNotification(notificationMessage));
    };

    const stripePromise = loadStripe(
            configuration.get("configData.stripe_publishable_key")
            );

    const [paymentAddCard, setPaymentAddCard] = useState(false);
    const closePaymentAddCardModal = () => {
        setPaymentAddCard(false);
        props.openPaymentModal();
    };

    const addCard = (event) => {
        event.preventDefault();
        props.closePaymentModal();
        setShowCardsSelect(false);
        setPaymentAddCard(true);
    }

    const openCardsSelect = (event) => {
        event.preventDefault();
        props.closePaymentModal();

        setShowCardsSelect(true);
    };

    const selectCard = (card) => {
        setSelectedCard(card);
        hideCardsSelect();
        props.dispatch(fetchCardDetailsStart());
    };

    const onShow = () => {
        bottomRef.current.scrollIntoView();
    }


    return (
            <React.Fragment>
                <Modal
                    className="payment-popup"
                    show={props.subscrptionPayment}
                    onHide={props.closePaymentModal}
                    keyboard={false}
                    scrollable={true}
                    onShow={onShow}
                    >
                    <Modal.Body >
                        <div className="profile-cover">
                            <Image
                                src={getThumbnail(props.cover, 362)}
                                alt={props.name}
                                style={{width: "100%"}}
                                />
                            <button class="close" onClick={props.closePaymentModal}><i class="fas fa-times-circle"></i></button>
                        </div>
                        <div className="profile--user">
                            <span className="my-profile-status">
                                <Image src={getThumbnail(props.userPicture, 117)} />
                            </span>
                            <div className="user-name">
                                {props.name}
                            </div>
                            <span className="username">@{props.username}</span>
                        </div>
                        <div className="intro">{t('profile.SIGN_UP_AND_GET_THE_FOLLOWING_BENEFITS')}</div>
                        <div className="advantage-list">
                            <div className="item">
                                <div className="icon">
                                    <img alt="thumbs-up" src="/assets/images/icons/thumbs-up.svg"/>
                                </div>
                                <div className="text">
                                    <div className="title">{t('profile.full_access')}</div>
                                    <div className="description">{t('profile.to_the_contents_of_this_user')}</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <img alt="comment-dots" src="/assets/images/icons/comment-dots.svg"/>
                                </div>
                                <div className="text">
                                    <div className="title">{t('profile.direct_message')}</div>
                                    <div className="description">{t('profile.direct_with_this_user')}</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <img alt="credit-card" src="/assets/images/icons/new-credit-card.svg"/>
                                </div>
                                <div className="text">
                                    <div className="title">{t('profile.cancel_subscription')}</div>
                                    <div className="description">{t('profile.at_any_time')}</div>
                                </div>
                            </div>
                        </div>
                        {typeof window.Payment !== 'undefined' || !props.is_logged_in || props.userDetails.data.payment_info.subscription_info.monthly_amount == 0 ? ("") : selectedCard || props.selectedCard ? [selectedCard ? selectedCard : props.selectedCard].map(card =>
                    (<div className="cards-list"><div className="card-item" onClick={openCardsSelect}>

                    <div className="card-icon">
                        <img alt="card" src="/assets/images/icons/card.png" />
                    </div>
                    <div className="card-description">
                        <h5 className="mb-4">**** **** **** {card.last_four} {card.card_type}</h5>

                        <div className="deadline">{t('payment.deadline')} {card.expiration_date}</div>
                    </div>
                    <a href="javascript:void(0)" className="select-card"><i class="fas fa-angle-right"></i></a>
                </div></div>)

                                )
                        : (<div><button className="add-card" onClick={addCard}>{t('payment.add_a_credit_card')}</button></div>)
                        }
                        <div className="subscription-section">
                            {debugInfo ? <textarea>{debugInfo}</textarea> : ''}
                            <Button
                                className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                                disabled={(props.is_logged_in && paymentType === "card" && userHasNoCards && typeof window.Payment === 'undefined') || subscribeButtonDisable}
                                onClick={handleSubmit}
                                ref={bottomRef}
                                >
                                {subscribeLoadingContent != null ? subscribeLoadingContent : props.paymentText}
                            </Button>
                        </div>
                    </Modal.Body>
            
                </Modal>
                <Elements stripe={stripePromise}>
                    <PaymentAddCardModal
                        paymentAddCard={paymentAddCard}
                        closePaymentAddCardModal={closePaymentAddCardModal}
                        afterCardAdded={selectCard}
                        />
                </Elements>
                <CardsSelect 
                    show={showCardsSelect} 
                    hide={hideCardsSelect} 
                    selectCard={selectCard}
                    addCard={addCard}
                    />
                <Modal
                    className="success-modal"
                    show={showSuccessModal}
                    onHide={hideSuccessModal}
                    keyboard={false}
                    scrollable={true}
                    centered
                    >
                    <Modal.Body>
                        <div className="profile-cover">
                            <Image
                                src={props.cover}
                                alt={props.name}
                                style={{width: "100%"}}
                                />
                            <button className="close" onClick={hideSuccessModal}><i class="fas fa-times-circle"></i></button>
                        </div>
                        <div className="profile--user">
                            <span className="my-profile-status">
                                <Image src={props.userPicture} />
                            </span>
                        </div>
                        <h2>{t('payment.transaction_successful')}</h2>
                        <div className="message">{t('payment.you_can_now_view', {username: props.username})}</div>
                        <button className="big-close" onClick={hideSuccessModal}>{t('modals.close')}</button>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
            );
};



const mapStateToPros = (state) => ({
        subPayStripe: state.subscriptions.subPayStripe,
        is_logged_in: state.users.is_logged_in,
        selectedCard: state.cards.selectedCard,
        userDetails: state.otherUser.userDetails
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(PaymentModal);
