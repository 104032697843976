import {
  SAVE_COMMENT_LIKE_START,
  SAVE_COMMENT_LIKE_SUCCESS,
  SAVE_COMMENT_LIKE_FAILURE,
  FETCH_COMMENT_LIKED_START,
  FETCH_COMMENT_LIKED_SUCCESS,
  FETCH_COMMENT_LIKED_FAILURE,
} from "./ActionConstant";

export function saveCommentLikeStart(data) {
  return {
    type: SAVE_COMMENT_LIKE_START,
    data,
  };
}

export function saveCommentLikeSuccess(data) {
  return {
    type: SAVE_COMMENT_LIKE_SUCCESS,
    data,
  };
}

export function saveCommentLikeFailure(error) {
  return {
    type: SAVE_COMMENT_LIKE_FAILURE,
    error,
  };
}
export function fetchCommentLikedStart(data) {
  return {
    type: FETCH_COMMENT_LIKED_START,
    data,
  };
}

export function fetchCommentLikedSuccess(data) {
  return {
    type: FETCH_COMMENT_LIKED_SUCCESS,
    data,
  };
}

export function fetchCommentLikedFailure(error) {
  return {
    type: FETCH_COMMENT_LIKED_FAILURE,
    error,
  };
}
