import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Badge } from "react-bootstrap";
import "./BillingAccountIndex.css";
import { getBankAccountStart } from "../../../store/actions/BankAccountAction";
import { Link, useHistory } from "react-router-dom";
import NoDataFound from "../../NoDataFound/NoDataFound";
import BillingAccountLoader from "../../Loader/BillingAccountLoader";
import {useTranslation} from "react-i18next";
import ViewBankPage from "./ViewBankPage";

const BillingAccountIndex = (props) => {
    useEffect(() => {
        props.dispatch(getBankAccountStart());
    }, []);
    const [t, i18n] = useTranslation('common');
    let history = useHistory();

    const [accountToView, setAccountToView] = useState(false);
    const viewAccount = (event, account) => {
        event.preventDefault();
        setAccountToView(account);
    };
    
    return (
            <div>
                {accountToView === false ? <div><div className="accounts-sec">
                        <Container>
                            <Row>
                                <Col sm={12} md={12}>
                                <div className="accounts-header-sec">
                                    <Button className="chat-header-back" onClick={history.goBack}>
                                        <i class="fas fa-arrow-left"></i>
                                    </Button>
                                    <h4 className="head-title">{t('accounts.current_accounts')}</h4>
            
                                </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col sm={12} md={12}>
                            <div className="accounts-list">
                                {props.bankAccount.loading ? (
                                    <BillingAccountLoader />
                                                        ) : props.bankAccount.data.billing_accounts.length > 0 ? (
                                                        props.bankAccount.data.billing_accounts.map(
                                                                (account) => (
                                                <div className="account-item" onClick={event => viewAccount(event, account)}>
                                                    <div className="icon"><i class="fas fa-university"></i></div>
                                                    <div className="description">
                                                        <div className="bank-name">{account.bank_name}</div>
                                                        <div className="account-number">{account.account_number}</div>
                                                    </div>
                                                    <a href="javascript:void(0)" className="select-account"><i class="fas fa-angle-right"></i></a>
                                                    {account.is_default ? <div className="default">{t('accounts.main')}</div> : null}
                            
                                                </div>
                                                                    )
                                                        )
                                                        ) : (
                                    <NoDataFound />
                                                        )}
                            </div>
                            </Col>
                            <Link className="add-account" to={"/add-bank"}>
                            {t('accounts.add_current_account')}
                            </Link>
                        </Row>
                    </Container></div>
                            : <ViewBankPage account={accountToView} goBack={() => setAccountToView(false)} />}
            </div>
            );
};

const mapStateToPros = (state) => ({
        bankAccount: state.bankAccount.bankAccount,
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(BillingAccountIndex);
