import React from "react";
import { Modal, Image } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "./NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { connect } from "react-redux";

const CopyLinkModal = (props) => {
    const {t, i18n} = useTranslation('common');
    const handleCopyLink = (event) => {
        event.preventDefault();

        props.onHide();
    };
    const onCopy = (event) => {
        const notificationMessage = getSuccessNotificationMessage(
                t('profile.copied_to_clipboard')
                );
        props.dispatch(createNotification(notificationMessage));
    };
    
    return (<Modal
            className="copy-link"
            show=
            {props.show}
    
            onHide={props.onHide}
    
            keyboard={false}
    
            centered
            >
            <Modal.Body>
    
                <div>
                    <span className="avatar">
                        <Image src=
                               {props.profile.data.picture}
                               />
                    </span>
                    <span className="username">@
                        {props.profile.data.username}
                    </span>
                </div>
                <div className="copy-link-button" onClick=
                        {handleCopyLink}
                     >
                    <CopyToClipboard
                        text=
                        {props.profile.data.share_link}
    
                        onCopy={onCopy}
    
                        id="copy_to_clipboard"
                        >
                        <Link>
                        {t('profile.copy_link_to_profile')}
                        </Link>
                    </CopyToClipboard>
    
                </div>
            </Modal.Body>
    
        </Modal>);
};



function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(null, mapDispatchToProps)(CopyLinkModal);