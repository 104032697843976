import React, { useState, useEffect } from "react";
import FanAllSec from "./FanAllSec";
import FanActiveSec from "./FanActiveSec";
import FanExpiredSec from "./FanExpiredSec";
import FanTabSec from "./FanTabSec";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchActiveFollowersStart,
  fetchExpiredFollowersStart,
  fetchFollowersStart,
} from "../../../../store/actions/FollowAction";
import FollowingLoader from "../../../Loader/FollowingLoader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./FanIndex.css";

const FanIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchActiveFollowersStart());
  }, []);
  const [t, i18n] = useTranslation('common');
  const [activeSec, setActiveSec] = useState("active-sec");

  const changeSection = (event, type) => {
    setActiveSec(type);
    if (type === "active-sec") props.dispatch(fetchActiveFollowersStart());
    if (type === "expired-sec") props.dispatch(fetchExpiredFollowersStart());
    if (type === "all-sec") props.dispatch(fetchFollowersStart());
  };

  return (
    <div className="fans">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
                <div className="pull-left">
                <Link className="go-back" to={'/list'}>
                <i className="fas fa-arrow-left"></i>
                </Link>
                </div>
                <h1>{t('lists.fans')}</h1>
            </div>
            <div className="listing-tab">
              <div className="tab" role="tabpanel">
               
                <div className="tab-content tabs">
                  <FanActiveSec
                    activeSec={activeSec}
                    setActiveSec={setActiveSec}
                    followers={props.activeFollowers}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  followers: state.follow.followers,
  activeFollowers: state.follow.activeFollowers,
  expiredFollowers: state.follow.expiredFollowers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FanIndex);
