import React from "react";
import {useTranslation} from "react-i18next";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginSection from "../helper/LoginSection";
import { useHistory } from "react-router-dom";

const LandingPageIndex = (props) => {
    
  const {t, i18n} = useTranslation('common');
  let history = useHistory();
  const verifyEmail = () => history.push('/verify-email');
  
  
  
  return (
      <div className="login-section">
        <Container>
          <Row>
            <Col lg={12} xl={12} md={12} sm={12} xs={12}>
              <div className="sign-in form-section">
                <Link to="#" aria-current="page" className="sign-in-logo">
                  <Image
                    src="/assets/images/login-logo.png"
                    
                  />
                </Link>
                <div className="forms-fields">
                  <h1>{t('login.login')}</h1>
                  <LoginSection verifyEmail={verifyEmail}/>
                          <a
                              id="advatages"
                              className="btn"
                              href={'https://creators.privans.com/'}
                            >
                              {t('login.creator_discover_the_advantages')}
                            </a>
                  
                  
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
  );
};


export default LandingPageIndex;
