import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
Form,
        Modal,
        InputGroup,
        FormControl,
        Col,
        Row,
        Button,
        } from "react-bootstrap";
import { CardElement } from "@stripe/react-stripe-js";
import api from "../../Environment";
import { connect } from "react-redux";
import "./PaymentAddCardModal.css";
import {useTranslation} from "react-i18next";
import AddCardButton from "../helper/AddCardButton";

const PaymentAddCardModal = (props) => {
    const {t, i18n} = useTranslation('common');


    const CARD_OPTIONS = {
        hideIcon: true,
        style: {
            base: {
                fontFamily: "Lato, sans-serif",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: 1.308,
            },
            "::placeholder": {
                color: "#9BA6B8"
            }
        }
    };

    return (
            <Modal
                className="modal-dialog-center payment-add-card-modal"
            
                centered
                show={props.paymentAddCard}
                onHide={props.closePaymentAddCardModal}
                >
            
                <Form>
                    <Modal.Header>
                        <a className="go-back" onClick={props.closePaymentAddCardModal}><i className="fas fa-arrow-left"></i></a>
                        <Modal.Title>{t('payment.add_a_credit_card')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12} md={12}>
                            <div className="card-icon">
                                <img alt="card" src="/assets/images/icons/card.png" />
                            </div>
                            <CardElement options={CARD_OPTIONS} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <AddCardButton afterCardAdded={(newCard) => {
                                
                                props.closePaymentAddCardModal();
                                if (typeof props.afterCardAdded !== "undefined") {
                                    props.afterCardAdded(newCard);
                                }
                                           }}/>
                    </Modal.Footer>
                </Form>
            </Modal>
            );
};

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(null, mapDispatchToProps)(PaymentAddCardModal);
