import {
SAVE_POST_START,
        SAVE_POST_SUCCESS,
        SAVE_POST_FAILURE,
        FETCH_POSTS_START,
        FETCH_POSTS_SUCCESS,
        FETCH_POSTS_FAILURE,
        FETCH_SINGLE_POST_START,
        FETCH_SINGLE_POST_SUCCESS,
        FETCH_SINGLE_POST_FAILURE,
        DELETE_POST_START,
        DELETE_POST_SUCCESS,
        DELETE_POST_FAILURE,
        CHANGE_POST_STATUS_START,
        CHANGE_POST_STATUS_SUCCESS,
        CHANGE_POST_STATUS_FAILURE,
        POST_FILE_UPLOAD_START,
        POST_FILE_UPLOAD_SUCCESS,
        POST_FILE_UPLOAD_FAILURE,
        PPV_PAYMENT_STRIPE_START,
        PPV_PAYMENT_STRIPE_SUCCESS,
        PPV_PAYMENT_STRIPE_FAILURE,
        PPV_PAYMENT_WALLET_START,
        PPV_PAYMENT_WALLET_SUCCESS,
        PPV_PAYMENT_WALLET_FAILURE,
        PPV_PAYMENT_APPLE_START,
        PPV_PAYMENT_APPLE_SUCCESS,
        PPV_PAYMENT_APPLE_FAILURE,
        SAVE_REPORT_POST_FAILURE,
        SAVE_REPORT_POST_SUCCESS,
        SAVE_BLOCK_USER_START,
        FETCH_REPORT_POSTS_FAILURE,
        FETCH_REPORT_POSTS_START,
        FETCH_REPORT_POSTS_SUCCESS,
        SAVE_REPORT_POST_START,
        PPV_PAYMENT_PAYPAL_START,
        PPV_PAYMENT_PAYPAL_SUCCESS,
        PPV_PAYMENT_PAYPAL_FAILURE,
        SAVE_POST_VIEW_START,
        SAVE_POST_VIEW_SUCCESS,
        SAVE_POST_VIEW_FAILURE,
        } from "../actions/ActionConstant";

const initialState = {
    savePost: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    posts: {
        data: {
            posts: []
        },
        loading: true,
        error: false,
        skip: 0,
        length: 0,
        loadingMore: false,
        total: 0,
        inputData: {},
    },
    singlePost: {
        inputData: {},
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "Loading... Please wait",
        buttonDisable: true,
    },
    delPost: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    changePostStatus: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    fileUpload: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    ppvPayStripe: {
        inputData: {},
        loading: true,
        error: false,
        success: {},
        buttonDisable: false,
        loadingButtonContent: null,
    },
    ppvPayWallet: {
        inputData: {},
        loading: true,
        error: false,
        success: {},
        buttonDisable: false,
        loadingButtonContent: null,
    },
    ppvPayApple: {
        inputData: {},
        loading: true,
        error: false,
        success: {},
        buttonDisable: false,
        loadingButtonContent: null,
    },
    reportPosts: {
        data: {},
        loading: true,
        error: false,
    },
    saveReportPost: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    savePostView: {
        loading: true,
        error: false,
        inputData: {},
    },
};

const PostReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_POST_START:
            return {
                ...state,
                savePost: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case SAVE_POST_SUCCESS:
            return {
                ...state,
                savePost: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case SAVE_POST_FAILURE:
            return {
                ...state,
                savePost: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_POSTS_START:
            return {
                ...state,
                posts: {
                    inputData: action.data,
                    data: {
                        posts: [...state.posts.data.posts],
                    },
                    loading: state.posts.skip == 0 ? true : false,
                    error: false,
                    skip: state.posts.skip,
                    length: state.posts.length,
                    loadingMore: state.posts.skip == 0 ? false : true,
                },
            };
        case FETCH_POSTS_SUCCESS:
            return {
                ...state,
                posts: {
                    inputData: {},
                    data: {
                        posts: [...state.posts.data.posts, ...action.data.posts],
                        total: action.data.total
                    },
                    loading: false,
                    error: false,
                    skip: action.data.posts.length + state.posts.skip,
                    length: action.data.posts.length,
                    loadingMore: false,
                    total: action.data.total
                },
            };
        case FETCH_POSTS_FAILURE:
            return {
                ...state,
                posts: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };

        case FETCH_SINGLE_POST_START:
            state.singlePost.inputData = action.data;
            return state;
        case FETCH_SINGLE_POST_SUCCESS:
            return {
                ...state,
                singlePost: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_SINGLE_POST_FAILURE:
            return {
                ...state,
                singlePost: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };

        case DELETE_POST_START:
            return {
                ...state,
                delPost: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case DELETE_POST_SUCCESS:
            return {
                ...state,
                posts: {
                    data: {
                        posts: state.posts.data.posts.filter(post => post.post_id != action.data.post_id),
                        total: state.posts.data.total - 1
                    },
                    loading: false,
                    error: false,
                    inputData: {},
                },
                delPost: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case DELETE_POST_FAILURE:
            return {
                ...state,
                delPost: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };

        case CHANGE_POST_STATUS_START:
            return {
                ...state,
                changePostStatus: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case CHANGE_POST_STATUS_SUCCESS:
            return {
                ...state,
                changePostStatus: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case CHANGE_POST_STATUS_FAILURE:
            return {
                ...state,
                changePostStatus: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case POST_FILE_UPLOAD_START:
            return {
                ...state,
                fileUpload: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "File Uploading....",
                    buttonDisable: true,
                },
            };
        case POST_FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                fileUpload: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case POST_FILE_UPLOAD_FAILURE:
            return {
                ...state,
                fileUpload: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case PPV_PAYMENT_STRIPE_START:
            return {
                ...state,
                ppvPayStripe: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    success: {},
                    buttonDisable: true,
                    loadingButtonContent: "Processing.. Please wait...",
                },
            };
        case PPV_PAYMENT_STRIPE_SUCCESS:
            return {
                ...state,
                ppvPayStripe: {
                    loading: false,
                    error: false,
                    success: action.data,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case PPV_PAYMENT_STRIPE_FAILURE:
            return {
                ...state,
                ppvPayStripe: {
                    loading: true,
                    error: action.error,
                    success: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case PPV_PAYMENT_WALLET_START:
            return {
                ...state,
                ppvPayWallet: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    success: {},
                    buttonDisable: true,
                    loadingButtonContent: "Processing.. Please wait...",
                },
            };
        case PPV_PAYMENT_WALLET_SUCCESS:
            return {
                ...state,
                ppvPayWallet: {
                    loading: false,
                    error: false,
                    success: action.data,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case PPV_PAYMENT_WALLET_FAILURE:
            return {
                ...state,
                ppvPayWallet: {
                    loading: true,
                    error: action.error,
                    success: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
            
        case PPV_PAYMENT_APPLE_START:
            return {
                ...state,
                ppvPayApple: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    success: {},
                    buttonDisable: true,
                    loadingButtonContent: "Processing.. Please wait...",
                },
            };
        case PPV_PAYMENT_APPLE_SUCCESS:
            return {
                ...state,
                ppvPayApple: {
                    loading: false,
                    error: false,
                    success: action.data,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case PPV_PAYMENT_APPLE_FAILURE:
            return {
                ...state,
                ppvPayApple: {
                    loading: true,
                    error: action.error,
                    success: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case FETCH_REPORT_POSTS_START:
            return {
                ...state,
                reportPosts: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_REPORT_POSTS_SUCCESS:
            return {
                ...state,
                reportPosts: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_REPORT_POSTS_FAILURE:
            return {
                ...state,
                reportPosts: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case SAVE_REPORT_POST_START:
            return {
                ...state,
                saveReportPost: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case SAVE_REPORT_POST_SUCCESS:
            return {
                ...state,
                saveReportPost: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case SAVE_REPORT_POST_FAILURE:
            return {
                ...state,
                saveReportPost: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case PPV_PAYMENT_PAYPAL_START:
            return {
                ...state,
                ppvPayPal: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    success: {},
                    buttonDisable: true,
                    loadingButtonContent: "Processing.. Please wait...",
                },
            };
        case PPV_PAYMENT_PAYPAL_SUCCESS:
            return {
                ...state,
                ppvPayPal: {
                    loading: false,
                    error: false,
                    success: action.data,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case PPV_PAYMENT_PAYPAL_FAILURE:
            return {
                ...state,
                ppvPayPal: {
                    loading: true,
                    error: action.error,
                    success: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case SAVE_POST_VIEW_START:
            return {
                ...state,
                savePostView: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                },
            };
        case SAVE_POST_VIEW_SUCCESS:
            return {
                ...state,
                savePostView: {
                    inputData: {},
                    loading: false,
                    error: false,
                },
            };
        case SAVE_POST_VIEW_FAILURE:
            return {
                ...state,
                savePostView: {
                    loading: false,
                    error: action.error
                },
            };

        default:
            return state;
}
};

export default PostReducer;
