import React, { useState, useEffect } from "react";
import { Form, Image, Modal, Media } from "react-bootstrap";
import {useTranslation} from "react-i18next";

const NofificationModal = (props) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(props.show);
    }, [props.show]);
    const [t, i18n] = useTranslation('common');
    
    const close = (event) => {
        event.preventDefault();
        setShow(false);
    };
    
    return (
            <Modal
                className="notifcation-modal"
                
                centered
                show={show}
                onHide={() => setShow(false)}
                >
                <Form>
                    <Modal.Body>
                    
                        <div className={"icon " + (props.success ? "success" : "error")}>
                        {props.success ? 
                        <i className="fas fa-check-circle"></i>
                        : <i class="fas fa-exclamation-circle"></i>}
                        </div>
                         <div className="title">{props.success ? t('modals.success') : t('modals.error')}</div>
                        <div className="message">{props.message}</div>
                        <button className="close-button" onClick={close}>{t('modals.close')}</button>
                    </Modal.Body>
                </Form>
            </Modal>
            );
};

export default NofificationModal;
