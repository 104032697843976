import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  Image,
  Media,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  addMessageContent,
  fetchChatMessageStart,
  fetchChatUsersStart,
  messageFileUploadStart
} from "../../store/actions/ChatAction";
import ChatUserList from "./ChatUserList";
import NoDataFound from "../NoDataFound/NoDataFound";
import io from "socket.io-client";
import configuration from "react-global-configuration";

import InboxLoader from "../Loader/InboxLoader";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { useTranslation } from "react-i18next";
import TimeAgo from "../helper/FormatTime";
import EmojiList from "../helper/EmojiList";
import ContentLoader from "react-content-loader"
import GLightbox from "glightbox";
import "./MessageIndex.css";

const $ = window.$;

let chatSocket;

const MessageIndex = (props) => {
  const [activeChat, setActiveChat] = useState(null);
  const [t, i18n] = useTranslation("common");
  const [toUserId, setToUserId] = useState(0);
  const [inputMessage, setInputMessage] = useState("");
  const [initialHeight, setInitialHeight] = useState(0);
  const [image, setImage] = useState({ previewImage: "" });
  const messageTextareaRef = useRef(null);
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    if(messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  
  
  useEffect(() => {
      GLightbox({
            touchNavigation: true,
            loop: true
          });
  }, [props.chatMessages]);

  useEffect(() => {
    console.log("asdfasdf first");
    props.dispatch(fetchChatUsersStart());
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    if (chatSocketUrl === "") {
      const notificationMessage = getErrorNotificationMessage(
        "Socket URL is not configured. Chat wil not work..."
      );
      props.dispatch(createNotification(notificationMessage));
      window.location.assign("/home");
    }
    
  }, [activeChat]);

  // Scroll down function..
  useEffect(() => {
    console.log("Scroll down..");
    const objDiv = document.getElementById("options-holder");
    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      if (differenceNumber > 280) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = initialHeight;
        setInitialHeight(initialHeight + 20);
      }
    }
    
  }, [props.chatMessages.data.messages]);

  const chatSocketConnect = (to_user_id) => {
    // check the socket url is configured
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    console.log("chatSocket", chatSocketUrl);
    console.log("Input ID", to_user_id);
    if (chatSocketUrl) {
      chatSocket = io(chatSocketUrl, {
        query:
          `commonid:'user_id_` +
          localStorage.getItem("userId") +
          `_to_user_id_` +
          to_user_id +
          `',myid:` +
          localStorage.getItem("userId"),
      });
      console.log("chatSocket", chatSocket);
      chatSocket.emit("update sender", {
        commonid:
          "user_id_" +
          localStorage.getItem("userId") +
          "_to_user_id_" +
          to_user_id,
        myid: localStorage.getItem("userId"),
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
          
        
        let content = [];
        content.push(newData);
        console.log("content:");
        console.log(content);
        // chatContent = [...this.state.chatData, ...content];
        // this.setState({ chatData: chatContent });
        props.dispatch(addMessageContent(content));
      });
    }
  };

  const changeUser = (event, chat, index) => {
    if(chatSocket) {
       chatSocket.disconnect();
    }
    event.preventDefault();
    console.log('index:');
    console.log(index);
    setActiveChat(index);
    let to_user_id =
      chat.to_user_id == localStorage.getItem("userId")
        ? chat.from_user_id
        : chat.to_user_id;
    
    setToUserId(to_user_id);
    
    props.dispatch(
      fetchChatMessageStart({
        to_user_id: chat.to_user_id,
        from_user_id: chat.from_user_id,
      })
    );
    chatSocketConnect(to_user_id);
  };

  const handleChatSubmit = (event) => {
    event.preventDefault();
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    
    
    if (chatSocketUrl != undefined && inputMessage) {
        
      let message_files = [];
      message_files.push({message_file: image.previewImage});
      let chatData = [
        {
          from_user_id: localStorage.getItem("userId"),
          to_user_id: toUserId,
          message: inputMessage,
          type: "uu",
          user_picture: localStorage.getItem("user_picture"),
          loggedin_user_id: localStorage.getItem("userId"),
          created: Date(),
          from_username: localStorage.getItem("username"),
          from_displayname: localStorage.getItem("name"),
          from_userpicture: localStorage.getItem("user_picture"),
          from_user_unique_id: "",
          to_username: "",
          to_displayname: "",
          to_userpicture: "",
          to_user_unique_id: "",
          message_files: typeof props.fileUpload.data.message_file !== "undefined" ? props.fileUpload.data.message_file.message_file_id : "",
          message_file_urls: message_files
        },
      ];
      console.log('chat data:');
      console.log(chatData[0]);
      
      chatSocket.emit("message", chatData[0]);
      if(image.previewImage) {
          chatData[0].message_files = message_files
      }
      let messages;
      if (props.chatMessages.data.messages != null) {
        messages = [...props.chatMessages.data.messages, ...chatData];
      } else {
        messages = [...chatData];
      }

      setInputMessage("");
      setImage({ previewImage: "" });
      props.dispatch(addMessageContent(chatData));
    }
  };

  const chatInputChange = (value) => {
    setInputMessage(value);
  };
  
  const insertEmoji = (event, character) => {
      event.preventDefault();
      let message = inputMessage;
      setInputMessage(message + character);
      messageTextareaRef.current.focus();
  };
  const fileuploadRef = useRef(null);
  const selectImage = (event) => {
      event.preventDefault();
      fileuploadRef.current.click();
  };
  
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  
  
  const uploadImage = (event, fileType) => {
    if (event.currentTarget.type === "file") {
      setFileUploadStatus(true);
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        setImage({ ...image, previewImage: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
      console.log(event.currentTarget.files[0]);
      props.dispatch(
        messageFileUploadStart({
          file: event.currentTarget.files[0],
          file_type: fileType,
        })
      );
    }
  };
  
  const imageClose = (event) => {
    event.preventDefault();
    if (props.fileUpload.loadingButtonContent !== null) {
      const notificationMessage = getErrorNotificationMessage(
        "File is being uploaded.. Please wait"
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      setImage({ previewImage: "" });
      setFileUploadStatus(false);
    }
  };
 

  return (
    <div className="message-page">
      <Container>
        <Row>
          {props.chatUsers.loading ? (
            <InboxLoader></InboxLoader>
          ) : props.chatUsers.data.users.length > 0 ?  (
            <ChatUserList
              chatUsers={props.chatUsers.data}
              activeChat={activeChat}
              setActiveChat={setActiveChat}
              changeUser={changeUser}
            />
          ) : (
            <NoDataFound />
          )}
          <Col
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="resp-mrg-btn-xs"
          >
            {props.chatMessages.loading || activeChat === null ? (
              ""
            ) : (
              <Row className="msg-row-chat">
                <div className="msg-header">
                <a className="go-back" onClick={event => { event.preventDefault(); setActiveChat(null)}}>
                  <i class="fas fa-arrow-left"></i>
                </a>
                  <h1 className="chat-section-title">
                    <div className="chat-section-title-width">
                      <Link
                        to={`/` + props.chatMessages.data.user.user_unique_id}
                        className="chat-user-name"
                      >
                        {props.chatMessages.data.user.name}{" "}
                        
                      </Link>
                    </div>
                  </h1>
                  <div className="last-visited"><TimeAgo date={props.chatMessages.data.user.last_seen} string="chat.last_visited_at"/></div>
                  <span
                    className="post-icons padding-top-lg"
                    style={{ display: "none" }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        id="dropdown-basic"
                      >
                        <Image
                          src="assets/images/icons/vertical-dots.svg"
                          className="svg-clone vertical-dots"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <Media as="li">
                          <Link to="#"> Copia link in un post </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#">
                            {" "}
                            Nascondi le foto sfuocate dal feed{" "}
                          </Link>
                        </Media>
                        <Media as="li" className="divider"></Media>
                        <Media as="li">
                          <Link to="#"> Non mi piace questo post </Link>
                        </Media>
                        <Media as="li">
                          <Link to="#"> Nascondi i post dell'utente dal feed</Link>
                        </Media>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                  <div className="chat-subheader hidden">
                    <div className="chat-user-status">
                      Ultima volta online <span title="Sep 7, 3:12 pm"> 1 hour ago </span>
                    </div>

                    <Button type="button" className="chat-subheader-btn">
                      <Image
                        src="assets/images/icons/star.svg"
                        className="svg-clone"
                        width="12"
                      />
                    </Button>
                    <Button type="button" className="chat-subheader-btn">
                      <Image
                        src="assets/images/icons/notification.svg"
                        className="svg-clone"
                        width="12"
                      />
                    </Button>
                    <Button type="button" className="chat-subheader-btn">
                      <Image
                        src="assets/images/icons/gallery.svg"
                        className="svg-clone"
                        width="12"
                      />

                      <span>Gallery</span>
                    </Button>
                    <Button type="button" className="chat-subheader-btn">
                      <Image
                        src="assets/images/icons/search.svg"
                        className="svg-clone"
                        width="12"
                      />

                      <span>Trova</span>
                    </Button>
                  </div>
                </div>

                <div className="chat-area">
                  <div className="chat-wrapper" id="options-holder">
                    <div className="chat-message padding" id="check" >
                      {props.chatMessages.data.messages.length > 0
                        ? props.chatMessages.data.messages.map(
                            (chatMessage, index) => (
                              <>
                                {chatMessage.from_user_id ==
                                localStorage.getItem("userId") ? (
                                  <div className="chat-message chat-message-sender">
                                    <Image
                                      className="chat-image chat-image-default"
                                      src={localStorage.getItem("user_picture")}
                                    />
                                    <div className="chat-message-wrapper">
                                      <div className="chat-message-content">
                                        <p>{chatMessage.message}</p>
                                      </div>
                                    </div>
                                    {chatMessage.message_files
              && chatMessage.message_files.length > 0
                ? chatMessage.message_files.map((messageFile, index) => 
                                    <div className="content-image">
                                            <a href={messageFile.message_file} class="glightbox">
                                            <img src={messageFile.message_file} alt="image" />
                                            </a>
                                    </div>) : null}
                                  </div>
                                ) : (
                                  <div className="chat-message chat-message-recipient">
                                    <Image
                                      className="chat-image chat-image-default"
                                      src={props.chatMessages.data.user.picture}
                                    />

                                    <div className="chat-message-wrapper">
                                      <div className="chat-message-content">
                                        <p>{chatMessage.message}</p>
                                      </div>
                                    </div>
                                    {chatMessage.message_files
              && chatMessage.message_files.length > 0
                ? chatMessage.message_files.map((messageFile, index) => 
                                    <div className="content-image">
                                    <a href={messageFile.message_file} class="glightbox">
                                            <img src={messageFile.message_file} />
                                                    </a>
                                    </div>) : null}
                                  </div>
                                )}{" "}
                              </>
                            )
                          )
                        : ""}
                        <div ref={messagesEndRef} />
                    </div>
                  </div>

                  <div>
                    <Form
                      id="chat_post_form"
                      className="has-advanced-upload"
                      onSubmit={handleChatSubmit}
                    >
                    <EmojiList insertEmoji={insertEmoji} />
                      <div className="chats-post-footer">
                        <div></div>
                        <div className="chat-post">
                          <div className="chat-textarea-price-wrapper">
                            <div className="">
                              <InputGroup className="mb-3">
                                <FormControl
                                  controlId="chat-input-area"
                                  placeholder={t('chat.write_something')}
                                  name="text"
                                  rows="1"
                                  className="form-control chat-input"
                                  style={{
                                    overflow: "hidden",
                                    overflowWrap: "break-word",
                                    height: "48px",
                                  }}
                                  value={inputMessage}
                                  onChange={(event) => {
                                    chatInputChange(event.currentTarget.value);
                                  }}
                                  ref={messageTextareaRef}
                                />
                                {image.previewImage !== "" ? 
                    <span className="insert-image">
                      <Link to="#" onClick={imageClose} className="close-image">
                        <i class="far fa-times-circle"></i>
                      </Link>
                      <Image
                        alt="#"
                        src={image.previewImage}
                        className="post-video-preview"
                      />
                    </span>
                    :
                                <a href="javascript:void" className="insert-image" onClick={selectImage}>
                                <i className="far fa-image"></i>
                                </a>}
                                <InputGroup.Append>
                                  <InputGroup.Text id="basic-addon2">
                                    <Button
                                      type="button"
                                      data-can_send="true"
                                      className="g-btn m-rounded b-chat__btn-submit"
                                      onClick={handleChatSubmit}
                                      disabled={
                      props.fileUpload.buttonDisable ||
                      inputMessage.length < 1
                    }
                                    >
                                      {props.fileUpload.loadingButtonContent !== null
                      ? <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
                       : <i className="fas fa-paper-plane"></i>}
                                    </Button>
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </div>
                          </div>

                          <div className="chat-post__actions">
                            <FormControl
                              id="fileupload_photo"
                              type="file"
                              multiple="multiple"
                              accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                              ref={fileuploadRef}
                              onChange={event => uploadImage(event, "image")}
                            />
                            <label
                              id="attach_file_photo"
                              for="fileupload_photo"
                              className="chat-attach_file"
                              data-original-title="null"
                            >
                            </label>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  chatUsers: state.chat.chatUsers,
  chatMessages: state.chat.messages,
  fileUpload: state.chat.fileUpload,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MessageIndex);
