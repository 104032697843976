import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import AddCardModal from "../../helper/AddCardModal";
import "./CardsIndex.css";
import { fetchCardDetailsStart } from "../../../store/actions/CardsAction";
import { Link, useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentAddCardModal from "../../helper/PaymentAddCardModal";
import NoDataFound from "../../NoDataFound/NoDataFound";
import CardListLoader from "../../Loader/CardListLoader";
import configuration from "react-global-configuration";
import {Modal, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ViewCardPage from "./ViewCardPage";

const CardsIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchCardDetailsStart());
  }, []);
  let history = useHistory();
  const [t, i18n] = useTranslation('common');

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  const [addCard, setAddCard] = useState(false);

  const closeAddCardModal = () => {
    setAddCard(false);
  };

  const [paymentAddCard, setPaymentAddCard] = useState(false);
  const [cardToView, setCardToView] = useState(false);
  
 

  const closePaymentAddCardModal = () => {
    setPaymentAddCard(false);
  };
  
  
  
  
  
  
  const viewCard = (event, card) => {
      event.preventDefault();
      setCardToView(card);
  };
  
  const handleDelete = (card_id) => {
      cards.data.cards.forEach(function(card, index) {
          if(card.id == card_id) {
              cards.data.cards.splice(index, 1);
          }
      });
  };

  const { cards } = props;

  return (
    <div>
      {cardToView === false ? 
      <div className="card-list-sec">
        <Container>
        <Button className="chat-header-back" onClick={history.goBack}>
                                            <i class="fas fa-arrow-left"></i>
                                        </Button>
          <h4 className="head-title">Le Tue Carte</h4>
          
          <Row className="cards-list">
            {cards.loading ? (
              <CardListLoader />
            ) : cards.data.cards.length > 0 ? (
              cards.data.cards.map((card) => (
              <div className="card-item" onClick={event => viewCard(event, card)}>
                        
                                        <div className="card-icon">
                                            <img alt="card" src="/assets/images/icons/card.png" />
                                        </div>
                                        <div className="card-description">
                                            <h5 className="mb-4">* {card.last_four} {card.card_type}</h5>
                        
                                            <div className="deadline">{t('payment.deadline')} {card.expiration_date}</div>
                                                    
                                        </div>
                                        
                                        
                                        <a href="javascript:void(0)" className="select-card"><i class="fas fa-angle-right"></i></a>
                                        {card.is_default == 1 ? (
                          <p className="card-link-text text-success">
                            {t('cards.main')}
                          </p>
                        ) : null}
                                    </div>
              ))
            ) : (
              <NoDataFound />
            )}
          </Row>
          <Row>
            <Col sm={12} md={6} xl={4}>
              <div
                className="card-list-box cursor-pointer-link"
                onClick={(event) => history.push('/add-card')}
              >
                <div className="add-account-sec">
                  <h5>{t('payment.add_a_credit_card')}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> : <ViewCardPage card={cardToView} 
      goBack={() => setCardToView(false)}
      deleteCard={handleDelete}
      />}
      
      <Elements stripe={stripePromise}>
        <PaymentAddCardModal
          paymentAddCard={paymentAddCard}
          closePaymentAddCardModal={closePaymentAddCardModal}
        />
      </Elements>
                        
    </div>
  );
};

const mapStateToPros = (state) => ({
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CardsIndex);
