import {
  ASK_HELP_START,
  ASK_HELP_SUCCESS,
  ASK_HELP_FAILURE
} from "./ActionConstant";


export function askHelpStart(data) {
  return {
    type: ASK_HELP_START,
    data,
  };
}

export function askHelpSuccess(data) {
  return {
    type: ASK_HELP_SUCCESS,
    data,
  };
}

export function askHelpFailure(error) {
  return {
    type: ASK_HELP_FAILURE,
    error,
  };
}


