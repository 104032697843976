import React from "react";
import {
NOTIFICATION_TYPE_SUCCESS,
        NOTIFICATION_TYPE_ERROR,
        } from "react-redux-notify";

export const getSuccessNotificationMessage = (message) => {
    return {
        message: message,
        type: NOTIFICATION_TYPE_SUCCESS,
        duration: 1500,
        canDismiss: true,
        icon: <i className="fas fa-check-circle"></i>,
    };
};

export const getErrorNotificationMessage = (message) => {
    console.log("error message: "+message);
    let callerName = (new Error()).stack.split("\n")[2].trim().split(" ")[1];
    console.log("caller name is "+callerName);
    return {
        message: message,
        type: NOTIFICATION_TYPE_ERROR,
        duration: 1500,
        canDismiss: true,
        icon: <i className="fas fa-exclamation-circle"></i>
    };
};
