import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import configuration from "react-global-configuration";

const NoDataFound = () => {
  return (
    <>
      <div className="no-data-found-sec">
        <Row>
          <Col sm="12" md="12">
            <div className="no-data-found-img-sec">
              <i className="fas fa-bookmark"></i>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NoDataFound;
