import React, { useState} from "react";
import {useTranslation} from "react-i18next";
import TimeAgo from "./FormatTime";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { saveCommentLikeStart } from "../../store/actions/CommentLikesAction";
import { connect } from "react-redux";
import {getThumbnail} from "../helper/Thumbnail";

const Comment = (props) => {
    const {t, i18n} = useTranslation('common');
    const [likeStatus, setLikeStatus] = useState("");
    let totalLikes = props.comment.total_likes ? props.comment.total_likes : 0;
    const [likeCount, setLikeCount] = useState(totalLikes);
    
    const handleLike = (event, status) => {
       event.preventDefault();
       setLikeStatus(status);
       props.dispatch(saveCommentLikeStart({ comment_id: props.comment.post_comment_id }));
       if (status == "added") {
      let currentLikeCount = likeCount + 1;
      setLikeCount(currentLikeCount);
      } else {
         let currentLikeCount = likeCount - 1;
         setLikeCount(currentLikeCount);
      }
       
    };
    
    return (<>
            <div className="row comment-row">
                        <div className="alignleft">
                          <Link
                            className="title-container img-responsive-wrapper"
                            to={`/` + props.comment.user_unique_id}
                          >
                            <Image
                              src={getThumbnail(props.comment.user_picture, 29)}
                              className="user-image img-responsive"
                            />
                          </Link>
                          <div className="user-name">
                            <span className="card-title">
                              <Link
                                className="title-container-1"
                                to={`/` + props.comment.user_unique_id}
                              >
                                {props.comment.user_displayname}{" "}
                              </Link>
                              <span className="comment-message">
                                {props.comment.comment}
                              </span>
                            </span>
                            <div className="comment-info-sec">
                              <ul className="list-unstyled comment-info-link">
                                <Media as="li">
                                <p><TimeAgo date={props.comment.created_at}></TimeAgo></p>
                                </Media>
                              <Media as="li" className="clickable" onClick={props.replyHandler}>
                                  <p>{t('post.reply')}</p>
                              </Media> 
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="heart-icon alignright clickable">
                               {likeStatus !== "" ? (
                <>
                  <>
                    {likeStatus === "added" ? (
                      <Link
                        to="#"
                        onClick={(event) => handleLike(event, "removed")}
                      >
                        <i class="fas fa-heart"></i>
                      </Link>
                    ) : null}
                  </>
                  <>
                    {likeStatus === "removed" ? (
                      <Link
                        to="#"
                        onClick={(event) => handleLike(event, "added")}
                      >
                        <i class="far fa-heart"></i>
                      </Link>
                    ) : null}
                  </>
                </>
              ) : props.comment.is_user_liked == 1 ? (
                <Link to="#" onClick={(event) => handleLike(event, "removed")}>
                  <i className="fas fa-heart"></i>
                </Link>
              ) : (
                <Link to="#" onClick={(event) => handleLike(event, "added")}>
                  <i className="far fa-heart"></i>
                </Link>
              )}
              {likeCount > 0 ? likeCount : ""}
                        </div>
                        <div className="reply-list">
                              {typeof props.comment.comment_replies !== "undefined" ? props.comment.comment_replies.map((comment) => (
                                   <Comment comment={comment} replyHandler={props.replyHandler}/>
                               )) : ""}
                        </div>
                      </div>
                      
                             </>
                      );
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(Comment);