import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col, Container, Image } from "react-bootstrap";
import { changePasswordStart } from "../../../store/actions/UserAction";
import './Profile.css';
import {useTranslation} from "react-i18next";
import { getThumbnail } from "../../helper/Thumbnail";
import {useHistory} from "react-router";
import NotificationModal from "../../helper/NotificationModal";

const ChangePassword = (props) => {
    const [inputData, setInputData] = useState({});
    const [t, i18n] = useTranslation('common');
    const oldPasswordRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(changePasswordStart(inputData));
        oldPasswordRef.current.value = "";
        passwordRef.current.value = "";
        confirmPasswordRef.current.value = "";
    };
    let history = useHistory();

    return (
            <div className="edit-profile">
                <Container>
                    <Row>
                        <Col md={8} sm={12}>
                        <div className="tab-content tabs">
                        <div className="edit-profile-photo">
                            <div className="profile large">
                                <div className="cover">
                                    <div className="edit-profile-gradient">
                                    </div>
                                    <div className="top-left">
                                        <a href="javascript:void(0)" onClick={history.goBack}>
                                        <Button className="chat-header-back">
                                            <i class="fas fa-arrow-left"></i>
                                        </Button>
                                        </a>
                                        <h1 className="chat-page-title">
                                            {t('profile.change_password')}
                                        </h1>
                                    </div>
                                    <Image
                                        src={getThumbnail(props.profile.data.cover, 414)}
                                        />
                
                                    <div className="layer">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                                <div className="user-info">
                                    <div className="profile-pic">
                                        <Image
                                            src={getThumbnail(props.profile.data.picture, 117)}
                                            />
                                        <div className="layer">
                                            <div className="loader"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <Form autoComplete="new-password" onSubmit={handleSubmit} className="edit-input-wrapper">
                                <Form.Group>
                                    <Form.Label for="old_password">{t('profile.old_password')}</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        id="old_password"
                                        type="password"
                                        placeholder={t('profile.enter_your_old_password')}
                                        name="old_password"
                                        onChange={(event) =>
                                                setInputData({
                                                    ...inputData,
                                                    old_password: event.currentTarget.value,
                                                })                                     }
                                        ref={oldPasswordRef}
                                        />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label for="password">{t('profile.new_password')}</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        id="password"
                                        type="password"
                                        placeholder={t('profile.enter_your_new_password')}
                                        name="password"
                                        onChange={(event) =>
                                                setInputData({
                                                    ...inputData,
                                                    password: event.currentTarget.value,
                                                })
                                                                              }
                                        ref={passwordRef}
                                        />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label for="password_confirmation">
                                        {t('profile.confirm_password')}
                                    </Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        id="password_confirmation"
                                        type="password"
                                        placeholder={t('profile.confirm_password')}
                                        name="password_confirmation"
                                        onChange={(event) =>
                                                setInputData({
                                                    ...inputData,
                                                    password_confirmation: event.currentTarget.value,
                                                })
                                                                              }
                                        ref={confirmPasswordRef}
                                        />
                                </Form.Group>
                                <Row className="mt-5">
                                    <Col sm={12} xs={12} md={6} className="mx-auto">
                                    <div className="edit-save">
                                    <Button
                                        className="save-btn"    
                                        type="submit"
                                        disabled={props.changePassword.buttonDisable}
                                        onClick={handleSubmit}
                                        >
                                        {props.changePassword.loadingButtonContent != null
                                                    ? props.changePassword.loadingButtonContent
                                                    : t('profile.change_password')}
                                    </Button>
                                    </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        </Col>
                    </Row>
                </Container>
                <NotificationModal message={props.resultMessage} show={props.showResult} success={props.resultSuccess} />
            </div>
            );
};

const mapStateToPros = (state) => ({
        changePassword: state.changePassword,
        profile: state.users.profile,
        resultMessage: state.changePassword.resultMessage,
        showResult: state.changePassword.showResult,
        resultSuccess: state.changePassword.resultSuccess
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePassword);
