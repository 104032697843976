import React from "react";
import {useEffect} from "react";
import { connect } from "react-redux";
import {
fetchCardDetailsStart
} from "../../store/actions/CardsAction";
import { Modal } from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CardListLoader from "../Loader/CardListLoader";
import NoDataFound from "../NoDataFound/NoDataFound.jsx";

const CardsSelect = function (props) {
    const {t, i18n} = useTranslation('common');
    const {cards} = props;
    useEffect(() => {
        if (props.is_logged_in) {
            props.dispatch(fetchCardDetailsStart());
        }
    }, []);
    return (
            <Modal
                className="cards-select"
                show={props.show}
                onHide={props.hide}
                keyboard={false}
                scrollable={true}
                centered
                >
                <Modal.Header>
                    <a className="go-back" onClick={props.hide}><i className="fas fa-arrow-left"></i></a>
                    <Modal.Title>{t('payment.select_or_add_a_credit_card')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="cards-list">
            
                        {cards.loading ? (
                            <CardListLoader />
                                    ) : cards.data.cards.length > 0 ?
                                    cards.data.cards.map((card) => (<div className="card-item" onClick={(event) => {
                                                                            props.selectCard(card)
                                                                             }}>
                        
                                        <div className="card-icon">
                                            <img alt="card" src="/assets/images/icons/card.png" />
                                        </div>
                                        <div className="card-description">
                                            <h5 className="mb-4">**** **** **** {card.last_four} {card.card_type}</h5>
                        
                                            <div className="deadline">{t('payment.deadline')} {card.expiration_date}</div>
                                        </div>
                                        <a href="javascript:void(0)" className="select-card"><i class="fas fa-angle-right"></i></a>
                                    </div>))
                                    : (
                                    <NoDataFound />
                                            )}
                    </div>
                    <button className="add-card" onClick={props.addCard}>{t('payment.add_a_credit_card')}</button>
                </Modal.Body>
            </Modal>
            );
};
const mapStateToPros = (state) => ({
        cards: state.cards.cardDetails,
        is_logged_in: state.users.is_logged_in,
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(CardsSelect);