import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  resetPasswordSuccess,
  resetPasswordFailure,
} from "../actions/UserAction";
import api from "../../Environment";
import { RESET_PASSWORD_START } from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import { useHistory } from "react-router-dom";

function* resetPasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.resetPassword.inputData.data
    );
    
    const response = yield api.postMethod("reset_password", inputData);
    yield put(resetPasswordSuccess(response.data.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location = "/";
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(resetPasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(RESET_PASSWORD_START, resetPasswordAPI)]);
}
