import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import NoDataFound from "../NoDataFound/NoDataFound";
import TimeAgo from "../helper/FormatTime";

const NotificationAllSec = (props) => {
  const { notifications } = props;

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "notify-all-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="Section1"
      >
        <div className="notification-list">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <div className={"notify-item " + (notification.is_read == 2 ? "read": "unread")}>
                    <Link
                      className="title-container"
                      to={notification.action_url.indexOf('PF-') === 0 ? notification.action_url.replace('PF-', '/post/PF-') : notification.action_url}
                      target="_blank"
                    >
                    <div className="user-image img-responsive notification-user-img ">
                      <Image
                        src={notification.from_userpicture}
                        
                      />
                      </div>
                      <div className="user-name">
                      <div className="alignleft">
                        <span className="post-user-name">
                          {notification.from_displayname}{" "}
                          
                        </span>
                        
                        <span className="post-user-notify">
                          {notification.message}
                        </span>
                        </div>
                        <span className="post-user-notify-date">
                          <TimeAgo date={notification.created_at}/>
                        </span>
                        
                      </div>
                    </Link>
              </div>
            ))
          ) : (
            <NoDataFound></NoDataFound>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationAllSec;
