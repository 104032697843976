import React, { useState, useEffect } from "react";
import NotificationAllSec from "./NotificationAllSec";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { fetchNotificationsStart } from "../../store/actions/NotificationAction";
import { connect } from "react-redux";
import NotificationLoader from "../Loader/NotificationLoader";
import "./NotificationIndex.css";

const NotificationIndex = (props) => {
  const [activeSec, setActiveSec] = useState("notify-all-sec");

  useEffect(() => {
    props.dispatch(fetchNotificationsStart());
  }, []);

  return (
    <>
      <div className="notification-page">
        <Container>
          <Row>
            <Col sm={12} md={12} xs={12}>
              <div className="profile-post-area">
                <div className="bookmarkes-list bookmarks-right-side resp-sapce-center">
                  <div className="pull-left">
                    <Link className="bookmarkes-list notify-title" to={`/home`}>
                      <i className="fas fa-arrow-left"></i>
                      <span>Notifiche</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tabbable-panel">
                <div className="tab" role="tabpanel">
                  <div className="tab-content tabs">
                    {props.notification.loading ? (
                      <NotificationLoader></NotificationLoader>
                    ) : (
                      <NotificationAllSec
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        notifications={props.notification.data.notifications}
                        totalNotifications={props.notification.data.total}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  notification: state.notification.notification,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NotificationIndex);
