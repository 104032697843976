import React, { useState } from "react";
import "./CardsIndex.css";
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router-dom";
import { Container, Button, Modal } from "react-bootstrap";
import { selectDefaultCardStart } from "../../../store/actions/CardsAction";
import { connect } from "react-redux";
import Environment from "../../../Environment";

const ViewCardPage = (props) => {
    let history = useHistory();
    const [t, i18n] = useTranslation('common');
    const [paymentDeleteCard, setPaymentDeleteCard] = useState(false);
    const handleDeleteModalClose = () => setPaymentDeleteCard(false);
    const [cardToDelete, setCardToDelete] = useState(0);

    const handleDeleteModalOpen = (event) => {
        event.preventDefault();
        setCardToDelete(props.card.id);

        setPaymentDeleteCard(true);
    };
    const handleDelete = (event, card_id) => {
        event.preventDefault();
        Environment.postMethod("cards_delete", {user_card_id: card_id});
        setPaymentDeleteCard(false);
        props.goBack();
        props.deleteCard(card_id);
    };

    return (<div className="card-list-sec view-card">
        <Container>
            <Button className="chat-header-back" onClick={event => props.goBack()}>
                <i class="fas fa-arrow-left"></i>
            </Button>
            <h4 className="head-title">* {props.card.last_four} {props.card.card_type}</h4>
            <div className="card-border">
                <div className="card-chip"></div>
            </div>
            <div className="card-description">
                <h5 className="mb-4">* {props.card.last_four} {props.card.card_type}</h5>
    
                <div className="deadline">{t('payment.deadline')} {props.card.expiration_date}</div>
    
            </div>
            {!props.card.is_default ? <Button className="set-as-main" onClick={() => {
                                    props.dispatch(
                                            selectDefaultCardStart({
                                                user_card_id: props.card.id
                                            })
                                            );
                                    props.goBack();
                                }
                                }>{t('cards.set_as_main')}</Button> : 
                                        <div className="default">
                                <div className="icon">
                                <i className="fas fa-check-circle"></i>
                                </div>
                                <span>{t('cards.main')}</span></div>}
            <button className="cancel-button" onClick={handleDeleteModalOpen}>{t('cards.cancell')}</button>
    
        </Container>
        <Modal
            show={paymentDeleteCard}
            onHide={handleDeleteModalClose}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title>Elimina carta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Sei sicuro di voler rimuovere la carta?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    size="lg"
                    onClick={handleDeleteModalClose}
                    >
                    Chiudi
                </Button>
                <Button
                    variant="primary"
                    size="lg"
                    onClick={(event) =>
                                handleDelete(
                                        event,
                                        cardToDelete
                                        )
                                          }
                    >
                    sì
                </Button>
            </Modal.Footer>
        </Modal>
    </div>);
};

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(null, mapDispatchToProps)(ViewCardPage);