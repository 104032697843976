import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../../Environment";



class NotificationsBadge extends Component {
    intervalId = null;
    request = null;

    state = {
        count: 0,
        show: false
    }

    componentDidMount() {
        let badge = this;
        let fetchData = function () {
            api.postMethod(badge.props.action).then(function (response) {
                if (response.data && response.data.data) {
                    badge.setState(
                            {count: response.data.data.count,
                                show: response.data.data.count > 0
                            }
                    );
                }

            });
        };
        fetchData();
        this.intervalId = setInterval(fetchData, this.props.interval * 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

   

    render() {
        return this.state.show ? (<span className="badge">{this.state.count}</span>) : null;
    }
};



export default NotificationsBadge;