import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
Form,
        Button,
        Container,
        Row,
        Col,
        Image,
        Media,
        } from "react-bootstrap";
import PostDisplayCard from "../../helper/PostDisplayCard";
import { fetchPostsStart } from "../../../store/actions/PostAction";
import {
fetchUserDetailsStart,
        updateVerifyBadgeStatusStart,
        } from "../../../store/actions/UserAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import ProfileLoader from "../../Loader/ProfileLoader";
import configuration from "react-global-configuration";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ModelProfileTabSec from "../../Model/ModelProfileTabSec";
import ModelProfilePostSec from "../../Model/ModelProfilePostSec";
import ModelProfilePhotoSec from "../../Model/ModelProfilePhotoSec";
import ModelProfileVideoSec from "../../Model/ModelProfileVideoSec";
import CopyLinkModal from "../../helper/CopyLinkModal";
import { getThumbnail } from "../../helper/Thumbnail";

import "./Profile.css";

const ProfileIndex = (props) => {
    const [badgeStatus, setBadgeStatus] = useState(0);
    const [activeSec, setActiveSec] = useState("post");
    const {t, i18n} = useTranslation('common');

    useEffect(() => {
        if (props.posts.loading)
            props.dispatch(fetchPostsStart());
        if (props.profile.loading) {
            props.dispatch(fetchUserDetailsStart());
            setBadgeStatus(localStorage.getItem("is_verified_badge"));
        }
    }, []);

    
    const onVerificationBadgeChange = (event) => {
        props.dispatch(updateVerifyBadgeStatusStart());
        setTimeout(() => {
            setBadgeStatus(localStorage.getItem("is_verified_badge"));
        }, 1000);
    };
    const [showCopyLink, setShowCopyLink] = useState(false);
    const handleCopyLinkModalClose = () => setShowCopyLink(false);
    const handleCopyLinkModalShow = () => setShowCopyLink(true);

    const setActiveSection = (event, key) => {
        setActiveSec(key);
        if (key === "post")
            props.dispatch(
                    fetchPostsStart({
                        user_unique_id: props.match.params.username,
                        type: "all",
                    })
                    );
        else if (key === "photo")
            props.dispatch(
                    fetchPostsStart({
                        user_unique_id: props.match.params.username,
                        type: "image",
                    })
                    );
        else if (key === "video")
            props.dispatch(
                    fetchPostsStart({
                        user_unique_id: props.match.params.username,
                        type: "video",
                    })
                    );
    };

    
    return (
            <div className="my-profile">
    <Container>
        <Row>
            <Col sm={12} xs={12} md={12}>
            {props.profile.loading ? (
                    <ProfileLoader></ProfileLoader>
                    ) : (
            <>
            <div className="cover-area">
                <div className="profile-cover">
                    <Image
                        src=
    {getThumbnail(props.profile.data.cover, 414)}
                        alt={props.profile.data.name}
                        style={{
                    width: "100%" }}
                        />
                </div>
                <div className="top-left">
                    <Link to={`/home`}>
                    <Button className="chat-header-back">
                        <i class="fas fa-arrow-left"></i>
                    </Button>
                    </Link>
                    <h1 className="chat-page-title">
                        {t('profile.user_profile')}
                    </h1>
                </div>
                <div className="top-right">
                    <span className="context-menu" onClick={handleCopyLinkModalShow}>
                        <FontAwesomeIcon icon={faEllipsisV} color={"white"} />
                    </span>
                </div>
            </div>

            <div className="profile--user">
                <span className="my-profile-status">
                    <Image src=
                    {getThumbnail(props.profile.data.picture, 117)} />
                </span>
                <div className="profile-btn-group">
                    <Link
                        to=
                    {"/edit-profile"}
                        className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile"
                        >
                    {t('profile.edit_profile')}
                    </Link>

                </div>
                <div className="my-profile-names">
                    <div className="user-name-base-row">
                        <Link to=
                    {`/profile`} className="my-name-lg">
                        <div className="g-user--name">
                            {t('profile.my_profile')}
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="profile-about-content">
                <p className="my-profile-about">
                <div className="ml-1">
                    {props.profile.data.about}</div>

                {props.profile.data.website ||
                            props.profile.data.address ||
                            props.profile.data.amazon_wishlist ? (
                                    <p className="">
                <div className="profile-links">
                    {props.profile.data.address ? (
                                            <span>
                        <i className="fas fa-map-marker-alt"></i>{" "}
                        {props.profile.data.address}
                    </span>
                                            ) : null}
                </div>

                <div className="profile-links">
                    {props.profile.data.website ? (
                                    <span>
                        <i className="fas fa-globe"></i>{" "}
                        <a
                            href={props.profile.data.website}
                            rel="nofollow"
                            target="_blank"
                            className="profile-a"
                            >
                            {props.profile.data.website.replace('http://', '').replace('https://', '').replace(/\/$/, "")}
                        </a>
                    </span>
                                    ) : null}
                </div>
                <div className="profile-links">
                    {props.profile.data.amazon_wishlist ? (
                                    <span>
                        <i className="fa fa-gift"></i>{" "}
                        <a
                            href={props.profile.data.amazon_wishlist}
                            rel="nofollow"
                            target="_blank"
                            className="profile-a"
                            >
                            {props.profile.data.amazon_wishlist}
                        </a>
                    </span>
                                    ) : null}
                </div>
                </p>
                                    ) : null}
                </p>
            </div>

            <div className="tab" role="tabpanel">
                <ModelProfileTabSec
                    activeSec=
                    {activeSec}
                    setActiveSec={setActiveSec}
                    setActiveSection={setActiveSection}
                    />

                <div className="tab-content tabs">
                    <ModelProfilePostSec
                        activeSec={activeSec}
                        setActiveSec={setActiveSec}
                        userPosts={props.posts}
                        />

                    <ModelProfilePhotoSec
                        activeSec=
                    {activeSec}
                    
                        setActiveSec={setActiveSec}
                    
                        userPosts={props.posts}
                    
                        />

                    <ModelProfileVideoSec
                        activeSec=
                    {activeSec}
                        setActiveSec={setActiveSec}
                        userPosts={props.posts}
                        />
                </div>
            </div>
            </>
            )}

            </Col>
                    </Row>
                    </Container>
            <CopyLinkModal show={showCopyLink} onHide={handleCopyLinkModalClose} profile={props.profile}/>
                    </div>

                    );
};

const mapStateToPros = (state) => ({
                        profile: state.users.profile,
                        posts: state.post.posts,
});

function mapDispatchToProps(dispatch) 
                    {
                            return {dispatch};
}
                

export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
