import React, { useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
sendTipStripeStart,
        sendTipWalletStart,
        sendTipPaypalStart,
        sendTipAppleStart
        } from "../../store/actions/SendTipAction";
import configuration from "react-global-configuration";

import PaypalExpressBtn from "react-paypal-express-checkout";
import { createNotification } from "react-redux-notify";
import {
getSuccessNotificationMessage,
        getErrorNotificationMessage,
        } from "../../components/helper/NotificationMessage";
import { useTranslation } from "react-i18next";
import {getThumbnail} from "../helper/Thumbnail";

const SendTipModal = (props) => {
    const [amount, setAmount] = useState(0);
    const [paymentType, setPaymentType] = useState("card");
    const [showPayPal, payPal] = useState(false);
    const [t, i18n] = useTranslation('common');
    const [debugInfo, setDebugInfo] = useState('');
    const [sendButtonDisable, setSendButtonDisable] = useState(false);
    const [sendLoadingContent, setSendLoadingContent] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        setSendButtonDisable(true);
        setSendLoadingContent(t('buttons.loading_please_wait'));
        
        if (typeof window.Payment !== 'undefined') {
            window.deliverProduct = function (verificationData) {
                
                props.dispatch(
                        sendTipAppleStart({
                            post_id:
                                    props.post_id != undefined || props.post_id != null
                                    ? props.post_id
                                    : "",
                            amount: amount,
                            user_id: props.user_id,
                            receiptData: verificationData,
                            onSuccess: () => {
                                setSendButtonDisable(false);
                                setSendLoadingContent('');
                                window.deliverProduct = null;
                                props.closeSendTipModal();
                            },
                            onError: () => {
                                setSendButtonDisable(false);
                                setSendLoadingContent('');
                                window.deliverProduct = null;
                            }
                        })
                        );
            };
            window.Payment.postMessage(JSON.stringify({
                action: 'make_payment',
                price: amount
            }));
        } else if (paymentType === "card") {
            
            
            props.dispatch(
                    sendTipStripeStart({
                        post_id:
                                props.post_id != undefined || props.post_id != null
                                ? props.post_id
                                : "",
                        amount: amount,
                        user_id: props.user_id,
                        onSuccess: () => {
                                setSendButtonDisable(false);
                                setSendLoadingContent('');
                                window.deliverProduct = null;
                                props.closeSendTipModal();
                            },
                        onError: () => {
                                setSendButtonDisable(false);
                                setSendLoadingContent('');
                                window.deliverProduct = null;
                            }
                    })
                    );
        }
        if (paymentType === "wallet")
            props.dispatch(
                    sendTipWalletStart({
                        post_id:
                                props.post_id != undefined || props.post_id != null
                                ? props.post_id
                                : "",
                        amount: amount,
                        user_id: props.user_id,
                    })
                    );
        
    };

    const paypalOnSuccess = (payment) => {
        setTimeout(() => {
            props.dispatch(
                    sendTipPaypalStart({
                        payment_id: payment.paymentID,
                        post_id:
                                props.post_id != undefined || props.post_id != null
                                ? props.post_id
                                : "",
                        amount: amount,
                        user_id: props.user_id,
                    })
                    );
        }, 1000);
        props.closeSendTipModal();
    };

    const paypalOnError = (err) => {
        const notificationMessage = getErrorNotificationMessage(err);
        this.props.dispatch(createNotification(notificationMessage));
    };

    const paypalOnCancel = (data) => {
        const notificationMessage = getErrorNotificationMessage(
                "Payment cancelled please try again.."
                );
        this.props.dispatch(createNotification(notificationMessage));
    };

    const choosePaymentOption = (event) => {
        console.log(amount);
        setPaymentType(event);
    };

    let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
    let currency = "USD"; // or you can set this value from your props or state

    const client = {
        sandbox: configuration.get("configData.PAYPAL_ID"),
        production: configuration.get("configData.PAYPAL_ID"),
    };

    return (
            <Modal
                className="modal-dialog-center sent-tip-modal payment-popup"
                size="md"
                centered
                show={props.sendTip}
                onHide={props.closeSendTipModal}
                >
                {props.sendTip === true ? (
                                    <Form onSubmit={handleSubmit}>
                                        <Modal.Body>
                                            <button class="close" onClick={event => {
                                                    event.preventDefault();
                                                    props.closeSendTipModal();
                                                }}><i class="fas fa-times-circle"></i></button>
                                            <h2>{t('send_tip.send_tip')}</h2>
                                            <div className="header-userinfo">
                                                <div className="g-avatar online_status_class">
                                                    <Image
                                                        src={getThumbnail(props.userPicture, 60)}
                                                        alt={props.name}
                                                        className="tips__user__img"
                                                        />
                                                </div>
                                                <div className="popup-username-row">
                                                    <div className="pop-username">
                                                        <div className="">
                                                            {props.name}{" "}
                                
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="popup-username-row">
                                                    <span className="pop-username popuser-realname">
                                                        <div className="pop-user-username">@{props.username}</div>
                                                    </span>
                                                </div>
                                            </div>
                                
                                            <div className="floating-form">
                                                <div className="floating-label mb-5">
                                                    <input
                                                        className="floating-input"
                                                        type="number"
                                                        min="0"
                                                        step="any"
                                                        value={amount}
                                                        onChange={(event) => setAmount(event.currentTarget.value)}
                                                        />
                                                    <span className="highlight"></span>
                                                    <label className="default-label">Tip amount</label>
                                                </div>
                                
                                                <Form className="mt-4">
                                                    {["radio"].map((type) => (
                                                                    <div key={`custom-inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            custom
                                                                            inline
                                                                            label="Card"
                                                                            type={type}
                                                                            id="card"
                                                                            value="card"
                                                                            name="payment_type"
                                                                            defaultChecked={true}
                                                                            onChange={() => setPaymentType("card")}
                                                                            />
                                                                        {configuration.get("configData.is_paypal_enabled") ==
                                                                                1 ? (
                                                                                                                <Form.Check
                                                                                                                    custom
                                                                                                                    inline
                                                                                                                    label="Paypal"
                                                                                                                    type={type}
                                                                                                                    id={`custom-inline-${type}-2`}
                                                                                                                    id="paypal"
                                                                                                                    value="paypal"
                                                                                                                    name="payment_type"
                                                                                                                    onChange={() => setPaymentType("paypal")}
                                                                                                                    />
                                                                                        ) : (
                                                                                                    ""
                                                                                )}
                                                                    </div>
                                                    ))}
                                                </Form>
                                
                                                <div className="floating-label">
                                                    <input
                                                        className="floating-input"
                                                        type="text"
                                                        placeholder="Message (optional) "
                                                        />
                                                    <span className="highlight"></span>
                                                    <label className="default-label">Message (optional)</label>
                                                </div>
                                            </div>
                                            <div className="subscription-section">
                                                {debugInfo ? 
                                                <textarea id = "id" 
                                                name = "name" rows = "5" 
                                                cols = "10">{debugInfo}</textarea> : null}
                                                <Button
                                                    type="button"
                                                    className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                                                    data-dismiss="modal"
                                                    onClick={handleSubmit}
                                                    disabled={sendButtonDisable}
                                                    >
                                                    {sendLoadingContent !== ''
                                                ? sendLoadingContent
                                                : "SEND TIP"}
                                                </Button>
                                            </div>
                                        </Modal.Body>
                                    </Form>
                                ) : null}
            </Modal>
            );
};

const mapStateToPros = (state) => ({
        tipStripe: state.tip.tipStripe,
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(SendTipModal);
