import React, { Component } from "react";
import HeaderIndex from "./Header/HeaderIndex";
// import SideBarIndex from "./SideBar/SideBarIndex";
// import FooterIndex from "./Footer/FooterIndex";
import { Notify } from "react-redux-notify";
import AuthFooter from "./Footer/AuthFooter";
import { connect } from "react-redux";

class MainLayout extends Component {
  state = {};
  
  render() {
    return (
      <div className="app-admin-wrap layout-sidebar-large">
        <Notify position="TopRight" />
        {this.props.is_logged_in && !this.props.hideMenu ? (<HeaderIndex />) : ""}
        <div className="main-content-wrap sidenav-open d-flex flex-column">
          <div className="main-wrap-sec">
            {React.cloneElement(this.props.children)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  is_logged_in: state.users.is_logged_in
});

export default connect(mapStateToPros)(MainLayout);
