import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Image, Media, Button } from "react-bootstrap";
import configuration from "react-global-configuration";
import NotificationsBadge from "../../helper/NotificationsBadge";
import { connect } from "react-redux";
import BecomeCreatorModal from "../../helper/BecomeCreatorModal";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { getThumbnail } from "../../helper/Thumbnail";
import "./HeaderIndex.css";



const HeaderIndex = (props) => {

  const [isVisible, setIsVisible] = useState(false);
  const {t, i18n} = useTranslation('common');
  const [showBecomeCreatorModal, setShowBecomeCreatorModal] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const history = useHistory();
  
  const closeBecomeCreatorModal = () => {
      setShowBecomeCreatorModal(false);
  }
  
  const createPost = (event) => {
      event.preventDefault();
      
      while(props.users.profile.loading && !props.users.profile.error) {}
      setIsVisible(false);
      if(props.users.profile.data.user_account_type != 1) {
          setShowBecomeCreatorModal(true);
      } else {
          history.push('/posts-create');
      }
      
      
  };
  
  

  return (
    <>
      <header className="main-header">
          <nav className="main-header-menu">
            <Link
              to={"/home"}
              className="main-header-menu icon-with-round-hover m-current"
              onClick={() => setIsVisible(false)}
            >
              <i class="far fa-home-alt"></i>
            </Link>
            <Link
              to={"/notification"}
              className="main-header-menu icon-with-round-hover"
              active-className="m-current"
              exact-active-className=""
              onClick={() => setIsVisible(false)}
            >
              <i class="far fa-bell"></i>
              <NotificationsBadge action="bell_notifications_count" interval={12}/>
            </Link>
            <Link
              className="main-header-menu icon-with-round-hover create-post-button"
              onClick={createPost}
            >
              <i class="fas fa-plus"></i>
            </Link>
            <Link
              to={"/inbox"}
              className="main-header-menu icon-with-round-hover"
              onClick={() => setIsVisible(false)}
            >
              
              <i class="far fa-comment-dots"></i>
              <NotificationsBadge action="unread_messages_count" interval={9}/>
              
            </Link>
            <Link
              className="main-header-menu icon-with-round-hover"
              to="#"
              data-drawer-trigger
              aria-controls="drawer-name"
              aria-expanded="false"
              onClick={() => setIsVisible(!isVisible)}
            >
              <i class="far fa-user"></i>
            </Link>
          </nav>
      </header>
      {isVisible ? (
        <div className="drawer" id="drawer-name" data-drawer-target>
          <div
            className="drawer__overlay"
            data-drawer-close
            tabindex="-1"
          ></div>
          <div className="drawer__wrapper">
            <div className="drawer__header">
              <div className="drawer__title">
                <Link to="#" className="l-sidebar__avatar" data-name="Profile">
                  <span className="sidebar-hamburger-user-profile">
                    <Image
                      src={getThumbnail(localStorage.getItem("user_picture"), 48)}
                      alt={configuration.get("configData.site_name")}
                    />
                  </span>
                  <span onClick={() => setIsVisible(!isVisible)} className="close-side-menu">
                    {" "}
                    <i class="fas fa-times-circle"></i>
                  </span>
                </Link>
                <div className="pull-left side-user-head">
                  <Link
                    to={"/profile"}
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <h3 className="g-user-name">
                      {localStorage.getItem("name")} {"  "}
                      
                    </h3>
                    <span className="user-id">
                      @{localStorage.getItem("username")}
                    </span>
                  </Link>

                  <ul className="list-inline">
                    <Media as="li">
                      <Link to={"/fans"} onClick={() => setIsVisible(false)}>
                        <span className="fans-follow">
                          {localStorage.getItem("total_followers")
                            ? localStorage.getItem("total_followers")
                            : 0}
                        </span>{" "}
                        {t('lists.fans')}
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link
                        to={"/following"}
                        onClick={() => setIsVisible(false)}
                      >
                        <span className="fans-follow">
                          {localStorage.getItem("total_followings")
                            ? localStorage.getItem("total_followings")
                            : 0}
                        </span>{" "}
                        {t('lists.following')}
                      </Link>
                    </Media>
                  </ul>
                </div>
              </div>
              {/* <Button
              className="drawer__close"
              data-drawer-close
              aria-label="Close Drawer"
            ></Button> */}
            </div>
            <div className="drawer__content">
              <div className="right-sidebar-menu-item">
                <Link
                  to={"/profile"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-user"></i>
                  {t('header.user_profile')}
                </Link>

                <Link
                  to={"/list"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-stream"></i>
                  Liste
                </Link>
                
                <Link
                  to={"/cards"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-credit-card"></i>
                  Le tue carte 
                </Link>
                
                {props.users.profile.data.user_account_type == 1 ? <Link
                  to={"/billing-accounts"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-university"></i>
                  {t('header.current_accounts')}
                </Link> : null}
                
                {props.users.profile.data.user_account_type == 1 ? <Link
                  to={"/wallet"}
                  className="sidebar-menus-item"
                  data-name="Wallet"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-wallet"></i>
                  Portafoglio 
                </Link> : null}
                
                {props.users.profile.data.user_account_type == 1 ? <Link
                  to={"/media-planning"}
                  className="sidebar-menus-item"
                  data-name="Media planning"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-calendar-alt"></i>
                  {t('plan.editorial_plan')}
                </Link> : null}

               
              </div>
              <div className="right-sidebar-menu-item">
              
               <a
                  href="javascript:void(0)"
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setShowSettingsMenu(!showSettingsMenu)}
                >
                  <i class="fas fa-cog"></i>
                  Impostazioni
                </a>
                {showSettingsMenu ? <div className="sub-menu">
                <Link to={"/change-password"} onClick={() => setIsVisible(!isVisible)}>{t('header.change_password')}</Link>
                <Link to={"/delete-account"} onClick={() => setIsVisible(!isVisible)}>{t('header.delete_user')}</Link>
                {props.users.profile.data.user_account_type == 1 ?
                <Link to={"/document-upload"} onClick={() => setIsVisible(!isVisible)}>{t('header.your_documents')}</Link> : null}
                </div> : null}
                <Link
                  to={`/help`}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-question"></i>
                  Aiuto
                </Link>

                <Link
                  to={"/logout"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i class="fas fa-sign-out-alt"></i>
                  Esci
                </Link>
            </div>
            </div>
          </div>
        </div>
      ) : null}
    <BecomeCreatorModal showBecomeCreatorModal={showBecomeCreatorModal} closeBecomeCreatorModal={closeBecomeCreatorModal}/>
    </>
  );
};

const mapStateToPros = (state) => ({
  users: state.users
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HeaderIndex);
