import React, {useState, useRef, useEffect} from "react";
import { Modal } from "react-bootstrap";
import {useTranslation} from "react-i18next";
import "./BecomeCreatorModal.css";
import { Form, Col, Row } from "react-bootstrap";
import {
addKycDocumentStart,
        getKycDocumentStart,
} from "../../store/actions/KycDocumentAction";
import { connect } from "react-redux";
import DocumentUploadLoader from "../Loader/DocumentUploadLoader";
import { addBankAccountStart } from "../../store/actions/BankAccountAction";
const BecomeCreatorModal = (props) => {
    const {t, i18n} = useTranslation('common');
    const [step, setStep] = useState(-1);
    const [creatorInputData, setCreatorInputData] = useState({
        onSuccess: () => setStep(5)
    });
    const [documentPhotoUploading, setDocumentPhotoUploading] = useState(false);
    const [facePhotoUploading, setFacePhotoUploading] = useState(false);
    const [documentPhotoUploaded, setDocumentPhotoUploaded] = useState(false);
    const [facePhotoUploaded, setFacePhotoUploaded] = useState(false);
    const selectAccountType = type => {
        setCreatorInputData({...creatorInputData, account_type: type});
        setStep(1);
    };
    const documentPhotoRef = useRef(null);
    const facePhotoRef = useRef(null);
    const goBack = event => {
        event.preventDefault();
        setStep(step - 1);
    };
    const goAHead = event => {
        event.preventDefault();
        setStep(step + 1);
    };
    const uploadPhoto = (event, type) => {
        if (event.currentTarget.type === "file") {
            let documentId = null;
            let onSuccess = f => f;
            let onFail = f => f;
            if (type == "document") {
                documentId = 1;
                setDocumentPhotoUploading(true);
                onSuccess = (response) => {
                    setDocumentPhotoUploading(false);
                    setDocumentPhotoUploaded(true);
                    props.dispatch(getKycDocumentStart());
                };
                onFail = () => setDocumentPhotoUploading(false);
            } else if (type == "face") {
                documentId = 2;
                setFacePhotoUploading(true);
                onSuccess = (response) => {
                    setFacePhotoUploading(false);
                    setFacePhotoUploaded(true);
                    props.dispatch(getKycDocumentStart());
                };
                onFail = () => setFacePhotoUploading(false);
            }
            let uploadData = {
                document_file: event.currentTarget.files[0],
                document_id: documentId,
                onSuccess: onSuccess,
                onFail: onFail
            };
            props.dispatch(addKycDocumentStart(uploadData));
        }
    };
    const complete = event => {
        event.preventDefault();
        props.dispatch(addBankAccountStart(creatorInputData));
    };
    return (<Modal
        className="become-creator"
        show={props.showBecomeCreatorModal}
        onHide={props.closeBecomeCreatorModal}
        keyboard={false}
        scrollable={true}
        centered
        >
        <Modal.Body className={"step-" + step}>
            {step == 4 ? null : step == 0 || step == -1 ? <button className="close" onClick={props.closeBecomeCreatorModal}><i class="fas fa-times-circle"></i></button>
                            : <button className="back" onClick={goBack}><i class="fas fa-arrow-left"></i></button>}
            <h2>{t('creator.become_a_creator')}</h2>
            {step > 0 && step < 4 ? <div className="step-header">
                <div className="step-title">{t('creator.step', {number: step})}</div>
                <div className="step-line-list">
                    {[1, 2, 3].map(number => (<span className={"step-line " + (number <= step ? "active" : "")}></span>))}
                </div>
            </div>
                            : null}
            {step == -1 ? <div>
                <h3>{t('creator.publish_and_involve')}</h3>
                <div className="list">
                    <div className="item">
                        <div className="icon">
                            <i class="fas fa-coins"></i>
                        </div>
                        <div className="text">
                            <div className="title">{t('creator.publish_in_freedom')}</div>
                            <div className="description">{t('creator.we_dont_censor')}</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <i class="fas fa-stream"></i>
                        </div>
                        <div className="text">
                            <div className="title">{t('creator.define_price')}</div>
                            <div className="description">{t('creator.choose_price')}</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <i class="fas fa-bell"></i>
                        </div>
                        <div className="text">
                            <div className="title">{t('creator.promote_your_channel')}</div>
                            <div className="description">{t('creator.direct_link')}</div>
                        </div>
                    </div>
                </div>
                <h3>{t('creator.we_take_care')}</h3>
                <hr/>
                <button type="submit" className="continue" onClick={goAHead}>{t('creator.become_a_creator')}</button>
                <div className="direct">
                    <a href="https://creators.privans.com/">{t('creator.request_direct_contact')}</a>
                </div>
            </div> : step == 1 ? <div>
                <Form method="post" onSubmit={goAHead}>
                    <Form.Group controlId="formFirstName">
                        <Form.Control
                            type="text"
                            controlId="first_name"
                            placeholder={t('creator.first_name')}
                            required
                            value={creatorInputData.first_name}
                            name="first_name"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        first_name: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formSurname">
                        <Form.Control
                            type="text"
                            controlId="surname"
                            placeholder={t('creator.surname')}
                            required
                            value={creatorInputData.surname}
                            name="surname"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        surname: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    {creatorInputData.account_type == "company" ?
                                <Form.Group controlId="formBusinessName">
                                    <Form.Control
                                        type="text"
                                        controlId="business_name"
                                        placeholder={t('creator.business_name')}
                                        required
                                        value={creatorInputData.business_name}
                                        name="business_name"
                                        onChange={(event) =>
                                                                setCreatorInputData({
                                                                    ...creatorInputData,
                                                                    business_name: event.currentTarget.value,
                                                                })
                                                                      }
                                        />
                                </Form.Group> : null}
                    <Form.Group controlId="formAddress">
                        <Form.Control
                            type="text"
                            controlId="address"
                            placeholder={t('creator.address')}
                            required
                            value={creatorInputData.address}
                            name="address"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        address: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formCity" as={Row}>
                        <Col xs="6">
                        <Form.Control
                            type="text"
                            controlId="postal_code"
                            placeholder={t('creator.postal_code')}
                            required
                            value={creatorInputData.postal_code}
                            name="postal_code"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        postal_code: event.currentTarget.value,
                                                    })
                                                          }
                            />
                        </Col>
                        <Col xs="6">
                        <Form.Control
                            type="text"
                            controlId="city"
                            placeholder={t('creator.city')}
                            required
                            value={creatorInputData.city}
                            name="city"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        city: event.currentTarget.value,
                                                    })
                                                          }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="formProvince">
                        <Form.Control
                            type="text"
                            controlId="province"
                            placeholder={t('creator.province')}
                            required
                            value={creatorInputData.province}
                            name="province"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        province: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    {creatorInputData.account_type == "company" ?
                                <Form.Group controlId="formVatNumber">
                                    <Form.Control
                                        type="text"
                                        controlId="vat_number"
                                        placeholder={t('creator.vat_number')}
                                        required
                                        value={creatorInputData.vat_number}
                                        name="vat_number"
                                        onChange={(event) =>
                                                                setCreatorInputData({
                                                                    ...creatorInputData,
                                                                    vat_number: event.currentTarget.value,
                                                                })
                                                                      }
                                        />
                                </Form.Group> : null}
    
                    {creatorInputData.account_type == "company" ?
                                <Form.Group controlId="formUniqueCode">
                                    <Form.Control
                                        type="text"
                                        controlId="unique_code"
                                        placeholder={t('creator.unique_code')}
                                        required
                                        value={creatorInputData.unique_code}
                                        name="unique_code"
                                        onChange={(event) =>
                                                                setCreatorInputData({
                                                                    ...creatorInputData,
                                                                    unique_code: event.currentTarget.value,
                                                                })
                                                                      }
                                        />
                                </Form.Group> :
                                <Form.Group controlId="formFiscalCode">
                                    <Form.Control
                                        type="text"
                                        controlId="fiscal_code"
                                        placeholder={t('creator.fiscal_code')}
                                        required
                                        value={creatorInputData.fiscal_code}
                                        name="fiscal_code"
                                        onChange={(event) =>
                                                                setCreatorInputData({
                                                                    ...creatorInputData,
                                                                    fiscal_code: event.currentTarget.value,
                                                                })
                                                                      }
                                        />
                                </Form.Group>}
                    <button type="submit" className="continue">{t('modals.continue')}</button>
                </Form>
            </div> : step == 2 ? <div className="step-2">
                {props.documents.loading ?
                                <DocumentUploadLoader></DocumentUploadLoader>
                                            :
                                <Form method="post">
                    
                                    <div className="rectangle" onClick={event => documentPhotoRef.current.click()}><div className="icon">
                                            {props.documents.data.documents[0].is_user_uploaded ? <img 
                                                src={props.documents.data.documents[0].user_document.document_file} 
                                                alt="document photo url"/> : <i 
                                                class="fas fa-id-card-alt"></i>}</div><div 
                                            className="title">
                                            {documentPhotoUploading ? t('file.uploading') : t('creator.upload_photo_of_the_document')}
                                        </div>
                                        <Form.Control
                                            type="file"
                                            name="document_photo"
                                            className="form-control"
                                            accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                            controlId="document_photo"
                                            onChange={(event) => uploadPhoto(event, "document")}
                                            required
                                            ref={documentPhotoRef}
                                            />
                                    </div>
                                    <div className="rectangle" onClick={event => facePhotoRef.current.click()}><div className="icon">
                                            {props.documents.data.documents[1].is_user_uploaded ?
                                                        <img src={props.documents.data.documents[1].user_document.document_file} alt="face photo url"/>
                                                                    :
                                                        <img src="/assets/images/icons/photo_with_document.png" alt="photo with document"/>}
                                        </div><div className="title">
                                            {facePhotoUploading ? t('file.uploading') : t('creator.upload_photo_of_your_face')}
                                        </div>
                                        <Form.Control
                                            type="file"
                                            name="face_photo"
                                            className="form-control"
                                            accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                                            controlId="face_photo"
                                            name="face_photo"
                                            onChange={(event) => uploadPhoto(event, "face")}
                                            required
                                            ref={facePhotoRef}
                                            />
                                    </div>
                                    <button onClick={goAHead} type="submit" className="continue" disabled={!props.documents.data.documents[0].is_user_uploaded || !props.documents.data.documents[1].is_user_uploaded}>{t('modals.continue')}</button>
                    
                                </Form>
                }
            </div> : step == 3 ? <div><Form method="post" onSubmit={goAHead}>
                    <Form.Group controlId="formAccountName">
                        <Form.Control
                            type="text"
                            controlId="account_name"
                            placeholder={t('creator.account_name')}
                            required
                            value={creatorInputData.account_name}
                            name="account_name"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        account_name: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formBankName">
                        <Form.Control
                            type="text"
                            controlId="bank_name"
                            placeholder={t('creator.bank_name')}
                            required
                            value={creatorInputData.bank_name}
                            name="bank_name"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        bank_name: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formAccountNumber">
                        <Form.Control
                            type="number"
                            controlId="account_number"
                            placeholder={t('creator.account_number')}
                            required
                            value={creatorInputData.account_number}
                            name="account_number"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        account_number: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formOwnedBy">
                        <Form.Control
                            type="text"
                            controlId="owned_by"
                            placeholder={t('creator.owned_by')}
                            required
                            value={creatorInputData.account_holder_name}
                            name="owned_by"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        account_holder_name: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formIban">
                        <Form.Control
                            type="text"
                            controlId="iban"
                            placeholder="IBAN*"
                            required
                            value={creatorInputData.iban_number}
                            name="iban"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        iban_number: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formSwiftCode">
                        <Form.Control
                            type="text"
                            controlId="swift_code"
                            placeholder={t('creator.swift_code')}
                            value={creatorInputData.swift_code}
                            name="swift_code"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        swift_code: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <Form.Group controlId="formIfscCode">
                        <Form.Control
                            type="text"
                            controlId="ifsc_code"
                            placeholder={t('creator.ifsc_code')}
                            value={creatorInputData.ifsc_code}
                            name="ifsc_code"
                            onChange={(event) =>
                                                    setCreatorInputData({
                                                        ...creatorInputData,
                                                        ifsc_code: event.currentTarget.value,
                                                    })
                                                          }
                            />
                    </Form.Group>
                    <button type="submit" className="complete">{t('modals.complete')}</button>
                </Form></div> : step == 4 ? <div><Form method="post" onSubmit={complete} autoComplete="off">
                    <p className="explanation">{t('creator.you_have_inserted')}</p>
                    <p className="explanation">{t('creator.now_set_value')}</p>
                    <Form.Group controlId="formMonthlyAmount">
                        <Form.Control
                            autoComplete="off"
                            type="text"
                            controlId="monthly_amount"
                            placeholder={t('creator.set_monthly_value')}
                            value={typeof creatorInputData.monthly_amount !== "undefined" ? creatorInputData.monthly_amount : ''}
                            name="monthly_amount"
                            onChange={(event) =>
                                                        setCreatorInputData({
                                                            ...creatorInputData,
                                                            monthly_amount: event.currentTarget.value,
                                                        })
                                                          }
                            />
                    </Form.Group>
                    <p className="calmness">{t('creator.you_can_change')}</p>
                    <button type="submit" className="complete">{t('modals.complete')}</button>
                </Form></div> :
                            step == 5 ? <div>
                                <div className="success-icon"><i className="fas fa-check-circle"></i></div>
                                <div className="message-wrapper">
                                    <h4 className="success-title">{t('modals.thank_you')}</h4>
                                    <div className="message">{t('creator.we_will_verify')}</div>
                                </div>
                                <button className="big-close" onClick={props.closeBecomeCreatorModal}>{t('modals.close')}</button>
                            </div> :
                    <div>
                        <h3>{t('creator.select_an_item')}</h3>
                        <div className="list">
                            <div className="item" onClick={event => selectAccountType('individual')}>
                                <div className="icon">
                                    <i class="fas fa-user"></i>
                                </div>
                                <div className="title">
                                    {t('creator.i_am_a_private_individual')}
                                </div>
                            </div>
                            <div className="item" onClick={event => selectAccountType('company')}>
                                <div className="icon">
                                    <i class="fas fa-building"></i>
                                </div>
                                <div className="title">
                                    {t('creator.i_am_a_company')}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Modal.Body>
    </Modal>);
};
const mapStateToPros = (state) => ({
        documents: state.kycDocument.kycDocDetails
    });
function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(BecomeCreatorModal);
