import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchPostSuggesstionStart } from "../../store/actions/HomeAction";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import {getThumbnail} from "../helper/Thumbnail";

const HomePageSuggesstion = (props) => {
  useEffect(() => {
    props.dispatch(fetchPostSuggesstionStart());
  }, []);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 100,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  // };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrow: true,
    vertical: true,
    verticalSwiping: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1195,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Col xl={4} md={12} className="suggest-col">
      <Row>
        <Col sm={12} xl={5} xs={12} md={6} className="mrg-btm-xs">
          <h4 className="suggestions">Suggeriti</h4>
        </Col>
        <Col sm={12} xl={7} xs={12} md={6} className="mrg-btm-xs">
          <div className="controls pull-right ">
           
          </div>
        </Col>
        <Col sm={12} xl={12} xs={12} md={12}>
              <div className="item active">
                <Row className="diplay-block">
                  <Col sm={12} md={12}>
                    {props.postSug.loading ? (
                      "Loading..."
                    ) : props.postSug.data.users.length > 0 ? 
                      
                        props.postSug.data.users.map((user) => (
                          <div className="col-item">
                            <div className="photo">
                                <div className="b-friend">
                                  <Link
                                    to={`/${user.user_unique_id}`}
                                    className=""
                                  >
                                    <div className="b-friend__cover-wrapper">
                                      <Image
                                        src={getThumbnail(user.cover, 348)}
                                        alt={user.username}
                                        className="b-friend__cover"
                                      />
                                    </div>
                                    <div className="b-friend__content">
                                      <div className="b-friend__avatar">
                                        <div className="profile-pic">
                                          <Image
                                            src={getThumbnail(user.picture, 53)}
                                            alt={user.username}
                                            className="suggest-img"
                                          />
                                        </div>
                                      </div>
                                      <div className="b-username-row">
                                        <div className="b-username m-like-link">
                                          <div className="g-user-name m-lg-size m-verified">
                                            {user.name}{" "}
                                          </div>
                                          <div className="g-user-username">
                                            @{user.username}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                              </div>
                            </div>
                          </div>
                        ))
                     : (
                      // </Carousel>
                      "Non ci sono suggeriti"
                    )}
                  </Col>
                </Row>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToPros = (state) => ({
  postSug: state.home.postSug,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomePageSuggesstion);
