import React, { useState, useEffect, useRef } from "react";
import ModelProfilePostSec from "./ModelProfilePostSec";
import ModelProfileTabSec from "./ModelProfileTabSec";
import ModelProfilePhotoSec from "./ModelProfilePhotoSec";
import ModelProfileVideoSec from "./ModelProfileVideoSec";
import SendTipModal from "../helper/SendTipModal";
import PaymentModal from "../helper/PaymentModal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Modal,
  Media,
} from "react-bootstrap";
import {
  fetchSingleUserProfileStart,
  fetchSingleUserPostsStart,
  saveProfileViewStart
} from "../../store/actions/OtherUserAction";
import { saveFavStart } from "../../store/actions/FavAction";
import { saveChatUserStart } from "../../store/actions/ChatAction";
import { subscriptionPaymentStripeStart } from "../../store/actions/SubscriptionAction";
import { unFollowUserStart } from "../../store/actions/FollowAction";
import { saveBlockUserStart } from "../../store/actions/UserAction";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEllipsisV, faShareSquare } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";
import LoginSection from "../helper/LoginSection";
import {getThumbnail} from "../helper/Thumbnail";
import CopyLinkModal from "../helper/CopyLinkModal";



const ModelViewProfile = (props) => {
    
  const {t, i18n} = useTranslation('common');
  const subscribeButtonRef = useRef();
  const fetchDataStart = () => {
    props.dispatch(
      fetchSingleUserProfileStart({
        user_unique_id: props.match.params.username,
      })
    );
    props.dispatch(
      fetchSingleUserPostsStart({
        user_unique_id: props.match.params.username,
        type: "all",
      })
    );
    
  };
  useEffect(fetchDataStart, []);
  
  

  const [activeSec, setActiveSec] = useState("post");
  const [sendTip, setSendTip] = useState(false);
  const [starStatus, setStarStatus] = useState("");
  const [showUnfollow, setShowUnfollow] = useState(false);
  const [showCopyLink, setShowCopyLink] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  
  const [viewSaved, setViewSaved] = useState(false);
  
  useEffect(() => {
      if(!viewSaved && props.userDetails.data.user) {
          props.dispatch(saveProfileViewStart({
              user_id: props.userDetails.data.user.user_id
          }));
          setViewSaved(true);
      }
  }, [props.userDetails]);
  
  const [blockUserStatus, setBlockUserStatus] = useState("");

  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "month",
    amount: 0,
    amount_formatted: 0,
  });

  const [subscrptionPayment, setPaymentModal] = useState(false);
  const [unsubscribeText, setUnsubscribeText] = useState('ISCRITTO');
  
  const closeSendTipModal = () => {
    setSendTip(false);
  };
  
  const openPaymentModal = () => {
    setPaymentModal(true);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const blockStatusUpdate = () => {
    if (props.loading == false) {
      setBlockUserStatus(
        props.data.is_block_user == 1 ? "blocked" : "unblocked"
      );
    }
  };
  const handleBlockUser = (event, status, user_id) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: user_id,
        is_other_profile: 1,
      })
    );
  };

  const setActiveSection = (event, key) => {
    setActiveSec(key);
    if (key === "post")
      props.dispatch(
        fetchSingleUserPostsStart({
          user_unique_id: props.match.params.username,
          type: "all",
        })
      );
    else if (key === "photo")
      props.dispatch(
        fetchSingleUserPostsStart({
          user_unique_id: props.match.params.username,
          type: "image",
        })
      );
    else if (key === "video")
      props.dispatch(
        fetchSingleUserPostsStart({
          user_unique_id: props.match.params.username,
          type: "video",
        })
      );
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);
  
  const handleCopyLinkModalClose = () => setShowCopyLink(false);
  const handleCopyLinkModalShow = (event) => {
      if(navigator.share) {
          navigator.share({
              'url': props.userDetails.data.share_link,
              'title': props.userDetails.data.user.name
          });
      } else {
        setShowCopyLink(true);
      }
  };
  
  
  const handleLoginModalClose = () => setShowLogin(false);
  
  const [loginView, setLoginView] = useState("login");
  
  const handleLoginModalShow = () => {
      setShowLogin(true);
  };
  
  
  
  const handleCopyLink = (event) => {
        event.preventDefault();
       
        handleCopyLinkModalClose();
  }

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const handleStar = (event, user_id, status) => {
    event.preventDefault();
    setStarStatus(status);
      saveFavStart({
        user_id: user_id,
      })
    
  };

  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      saveChatUserStart({
        from_user_id: localStorage.getItem("userId"),
        to_user_id: user_id,
      })
    );
  };
let history = useHistory();

const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    
    setSubscriptionData({
      ...subscriptionData,
      is_free: is_free,
      plan_type: plan_type,
      amount: amount,
      amount_formatted: amount_formatted,
    });
    
    setPaymentModal(true) 
    
    
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Link to profile was copied to clipboard!"
    );
    props.dispatch(createNotification(notificationMessage));
  };
  
  
  const toggleUnsubscribeText = (event) => {
      let newText = unsubscribeText == 'ISCRITTO' ? 'Vuoi disiscriverti?' : 'ISCRITTO';
      setUnsubscribeText(newText);
  }
  const clickSubsribeButton = (event) => {
      event.preventDefault();
      subscribeButtonRef.current.click();
  }

  const { userDetails } = props;
  
  const afterLogin = (visitorData) => {
      window.localStorage.setItem('open_subscribe_popup', 1);
      window.location.reload();
      
  };
  const bottomRef = useRef(null);
  const onShowLogin = () => {
      setTimeout(() => bottomRef.current.scrollIntoView(), 600);
      
  };
  
  useEffect(() => {
    if(userDetails.data.user) {
        document.title = t('titles.view_profile')+' '+userDetails.data.user.name;
    }
  },[userDetails]);
  
  useEffect(() => {
      if(window.localStorage.getItem('open_subscribe_popup') && subscribeButtonRef.current) {
          window.localStorage.removeItem('open_subscribe_popup');
          subscribeButtonRef.current.click();
      }
  }, [subscribeButtonRef.current]);

  return (
    <>
      <div className="my-profile user-profile-page model-view-profile-sec">
        <Container>
          <Row>
            {userDetails.loading ? (
              "Loading..."
            ) : (
              <Col sm={12} md={12}>
                <div className="cover-area">
                  <div className="profile-cover">
                    <Image
                      src={getThumbnail(userDetails.data.user.cover, 414)}
                      alt={userDetails.data.user.name}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="top-left">
                    <Link to={"/home"} className="chat-header-back">
                      <FontAwesomeIcon icon={faArrowLeft} color={"white"} />
                    </Link>
                    
                    
                  </div>
                  <div className="top-right">
                     <span className="context-menu" onClick={handleCopyLinkModalShow}>
                       <FontAwesomeIcon icon={faEllipsisV} color={"white"} />
                     </span>
                  </div>
                </div>

                <div className="profile--user">
                  <span className="my-profile-status">
                    <Image src={getThumbnail(userDetails.data.user.picture, 117)} />
                  </span>
                  <div className="profile-btn-group">
                  {props.is_logged_in && props.visitorData && userDetails.data.user.subcsriber_ids.includes(props.visitorData.user_id) ? (<>
                  <Button
                      type="button"
                      className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                      onClick={() => setSendTip(true)}
                    >
                      <i className="far fa-coins"></i>
                    </Button>

                    <Button
                      type="button"
                      className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                      onClick={(event) =>
                        handleChatUser(event, userDetails.data.user.user_id)
                      }
                    >
                      <i class="far fa-comment"></i>
                    </Button>
                    </>) : ""}
                    
                    {localStorage.getItem("userLoginStatus") === 'true' ? (<>
                    {starStatus !== "" ? (
                      <>
                        <>
                          {starStatus === "added" ? (
                            <Button
                              type="button"
                              className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                              onClick={(event) =>
                                handleStar(
                                  event,
                                  userDetails.data.user.user_id,
                                  "removed"
                                )
                              }
                            >
                              <i class="fas fa-star"></i>
                            </Button>
                          ) : null}
                        </>
                        <>
                          {starStatus === "removed" ? (
                            <Button
                              type="button"
                              className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                              onClick={(event) =>
                                handleStar(
                                  event,
                                  userDetails.data.user.user_id,
                                  "added"
                                )
                              }
                            >
                              <i class="far fa-star"></i>
                            </Button>
                          ) : null}
                        </>
                      </>
                    ) : userDetails.data.is_favuser == 1 ? (
                      <Button
                        type="button"
                        className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                        onClick={(event) =>
                          handleStar(
                            event,
                            userDetails.data.user.user_id,
                            "removed"
                          )
                        }
                      >
                        <i class="fas fa-star"></i>
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                        onClick={(event) =>
                          handleStar(
                            event,
                            userDetails.data.user.user_id,
                            "added"
                          )
                        }
                      >
                        <i class="far fa-star"></i>
                      </Button>
                    )}
                    </>) : ""}
                      <Button
                        type="button"
                        className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip icon-share"
                        onClick={handleCopyLinkModalShow}
                      >
                        <FontAwesomeIcon icon={faShareSquare} />
                      </Button>
                  </div>
                  <div className="my-profile-names">
                    <div className="user-name-base-row">
                      <Link
                        to={`/` + userDetails.data.user.user_unique_id}
                        className="my-name-lg"
                      >
                        <div className="g-user--name">
                          {userDetails.data.user.name}{" "}
                          
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {userDetails.data.user.website ||
                userDetails.data.user.address ||
                userDetails.data.user.amazon_wishlist ||
                userDetails.data.user.about != "null" ? (
                  <div className="profile-about-content">
                    <p className="my-profile-about">
                      {userDetails.data.user.about != "null" ? (
                        <div className="ml-1">
                          {userDetails.data.user.about}
                        </div>
                      ) : (
                        ""
                      )}

                      <p className="">
                        <div className="profile-links">
                          {userDetails.data.user.address ? (
                            <span>
                              <i className="fas fa-map-marker-alt"></i>{" "}
                              {userDetails.data.user.address}
                            </span>
                          ) : null}
                          
                          {userDetails.data.user.website ? (
                            <span>
                              <i class="fas fa-globe"></i>{" "}
                              <a
                                href={userDetails.data.user.website}
                                rel="nofollow"
                                target="_blank"
                                className="profile-a"
                              >
                                {userDetails.data.user.website}
                              </a>
                            </span>
                          ) : null}
                        </div>

                        <div className="profile-links">
                          
                        </div>
                        <div className="profile-links">
                          {userDetails.data.user.amazon_wishlist ? (
                            <span>
                              <i className="fa fa-gift"></i>{" "}
                              <a
                                href={userDetails.data.user.amazon_wishlist}
                                rel="nofollow"
                                target="_blank"
                                className="profile-a"
                              >
                                {userDetails.data.user.amazon_wishlist}
                              </a>
                            </span>
                          ) : null}
                        </div>
                      </p>
                    </p>
                  </div>
                ) : null}

                {userDetails.data.is_block_user == 0 ? (
                  <>
                    {userDetails.data.payment_info.is_user_needs_pay ? (
                      userDetails.data.payment_info.subscription_info.length >
                      0 ? (
                        <>
                          <div className="subscription-section">
                            <span className="subscribe-title">
                              Monthly Subscription{" "}
                            </span>

                            <Button
                              to=""
                              className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                              onClick={(event) =>
                                subscriptionPayment(
                                  event,
                                  "month",
                                  userDetails.data.payment_info
                                    .subscription_info.length > 0
                                    ? userDetails.data.payment_info
                                        .subscription_info.monthly_amount
                                    : 0.0,
                                  userDetails.data.payment_info
                                    .subscription_info.monthly_amount_formatted
                                )
                              }
                              ref={subscribeButtonRef}
                            >
                              {userDetails.data.payment_info.payment_text}
                            </Button>
                          </div>
                          <div className="subscription-section">
                            <span className="subscribe-title">
                              Yearly Subscription{" "}
                            </span>
                            <Button
                              to=""
                              className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                              onClick={(event) =>
                                subscriptionPayment(
                                  event,
                                  "year",
                                  userDetails.data.payment_info
                                    .subscription_info.yearly_amount,
                                  userDetails.data.payment_info
                                    .subscription_info.yearly_amount_formatted
                                )
                              }
                              ref={subscribeButtonRef}
                            >
                              Iscriviti per{" "}
                              {
                                userDetails.data.payment_info.subscription_info
                                  .yearly_amount_formatted
                              }
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className="subscription-section">
                          <Button
                            to=""
                            className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                            onClick={(event) =>
                              subscriptionPayment(
                                event,
                                "month",
                                userDetails.data.payment_info.subscription_info
                                  .monthly_amount,
                                userDetails.data.payment_info.subscription_info
                                  .monthly_amount_formatted,
                                1
                              )
                            }
                            ref={subscribeButtonRef}
                          >
                            {userDetails.data.payment_info.payment_text}
                          </Button>
                        </div>
                      )
                    ) : (
                      ""
                    )}

                    {userDetails.data.payment_info.unsubscribe_btn_status ==
                    1 ? (
                      <>
                        <div className="subscription-section">
                          <Button
                            className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow unsubscribe-button"
                            onClick={handleUnfollowModalShow}
                            onMouseOver={toggleUnsubscribeText}
                            onMouseLeave={toggleUnsubscribeText}
                          >
                            {unsubscribeText}
                          </Button>
                        </div>

                        <Modal
                          show={showUnfollow}
                          onHide={handleUnfollowModalClose}
                          backdrop="static"
                          keyboard={false}
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Cancella abbonamento</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Sei sicuro che vuoi cancellare l'abbonamento?
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              size="lg"
                              onClick={handleUnfollowModalClose}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              size="lg"
                              onClick={(event) =>
                                handleUnfollow(
                                  event,
                                  userDetails.data.user.user_id
                                )
                              }
                            >
                              Yes
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    ) : null}
                  </>
                ) : (
                  <div className="subscription-section">
                    <Link
                      to=""
                      className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
                      onClick={(event) =>
                        handleBlockUser(
                          event,
                          "unblocked",
                          userDetails.data.user.user_id
                        )
                      }
                    >
                      Sblocca utente
                    </Link>
                  </div>
                )}

                <div className="tab" role="tabpanel">
                  <ModelProfileTabSec
                    activeSec={activeSec}
                    setActiveSec={setActiveSec}
                    setActiveSection={setActiveSection}
                  />

                  <div className="tab-content tabs">
                    <ModelProfilePostSec
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      userPosts={props.userPosts}
                      onClickSubscribe={clickSubsribeButton}
                    />

                    <ModelProfilePhotoSec
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      userPosts={props.userPosts}
                    />

                    <ModelProfileVideoSec
                      activeSec={activeSec}
                      setActiveSec={setActiveSec}
                      userPosts={props.userPosts}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {userDetails.loading ? (
        "Caricando..."
      ) : (
        <SendTipModal
          sendTip={sendTip}
          closeSendTipModal={closeSendTipModal}
          username={props.userDetails.data.user.username}
          userPicture={props.userDetails.data.user.picture}
          name={props.userDetails.data.user.name}
          post_id={null}
          user_id={props.userDetails.data.user.user_id}
        />
      )}

      {userDetails.loading ? (
        "Caricando..."
      ) : (
        <>
        <PaymentModal
          subscrptionPayment={subscrptionPayment}
          openPaymentModal={openPaymentModal}
          closePaymentModal={closePaymentModal}
          userPicture={props.userDetails.data.user.picture}
          name={props.userDetails.data.user.name}
          user_unique_id={props.userDetails.data.user.user_unique_id}
          subscriptionData={subscriptionData}
          username={props.userDetails.data.user.username}
          cover={props.userDetails.data.user.cover}
          paymentText={userDetails.data.payment_info.payment_text}
          openLoginModal={handleLoginModalShow}
          onSuccess={fetchDataStart}
        />
                <CopyLinkModal show={showCopyLink} onHide={handleCopyLinkModalClose} profile={userDetails}/>
                        
                        
                        
                        <Modal
                          className="profile-login"
                          show={showLogin}
                          onHide={handleLoginModalClose}
                          keyboard={false}
                          centered
                          scrollable={true}
                          onShow={onShowLogin}
                        >
                          <Modal.Body>
                            <div className="profile-cover">
                                <Image
                                  src={getThumbnail(userDetails.data.user.cover, 362)}
                                  alt={userDetails.data.user.name}
                                  style={{ width: "100%" }}
                                />
                                <button class="close" onClick={handleLoginModalClose}><i class="fas fa-times-circle"></i></button>
                            </div>
                            <div className="profile--user">
                                <span className="my-profile-status">
                                  <Image src={getThumbnail(userDetails.data.user.picture, 117)} />
                                </span>
                                <div className="user-name">
                                      {userDetails.data.user.name}
                                </div>
                                <span className="username">@{userDetails.data.user.username}</span>
                            </div>
                            <h2>{loginView == "login" ? t('login.login_to_view_posts') : loginView == "signup" ? t('login.register_to_view_posts') : null}</h2>
                            <div className="login-section">
                              <div className="sign-in form-section">
                                <div className="forms-fields">
                                  <LoginSection afterLogin={afterLogin} setView={setLoginView} afterVerified={afterLogin} verifyEmail={() => history.push('/verify-email')}/>
                                </div>
                              </div>
                            </div>
                            <div ref={bottomRef}></div>
                          </Modal.Body>
                          
                        </Modal>
                        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  comments: state.comment.comments,
  chat: state.chat,
  userDetails: state.otherUser.userDetails,
  userPosts: state.otherUser.userPosts,
  is_logged_in: state.users.is_logged_in,
  visitorData: state.users.profile.data
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ModelViewProfile);
