import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ASK_HELP_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  askHelpSuccess,
  askHelpFailure,
} from "../actions/HelpAction";

function* askHelpAPI() {
  try {
    const inputData = yield select(
      (state) => state.help.askHelpInput.inputData
    );
    const response = yield api.postMethod("ask_for_help", inputData);

    if (response.data.success) {
      yield put(askHelpSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      if(typeof inputData.onSuccess !== "undefined") {
          inputData.onSuccess();
      }
    } else {
      yield put(askHelpFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(askHelpFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}



export default function* pageSaga() {
  yield all([yield takeLatest(ASK_HELP_START, askHelpAPI)]);
}
