import {Component} from "react";
import {withTranslation} from "react-i18next";

class TimeAgo extends Component {

    state = {
        time: null
    };
    componentDidMount() {
        const {t} = this.props;
        let date = new Date(this.props.date);
        let component = this;
        const formatTime = function() {
            let now = new Date();
            var seconds = Math.floor((now - date) / 1000);
            let time = '';
            var interval = Math.floor(seconds / 31536000);
            
            if (interval > 1) {
                time = interval + ' ' + t('time.years_ago');
            } else if(interval == 1) {
                time = t('time.a_year_ago');
            } else if ((interval = Math.floor(seconds / 2592000)) > 1) {
                time = interval + ' ' + t('time.months_ago');
            } else if(interval == 1) {
                time = t('time.a_month_ago');
            } else if ((interval = Math.floor(seconds / 86400)) > 1) {
                time = interval + ' ' + t('time.days_ago');
            } else if (now.getDate() - date.getDate() == 1) {
                time = t('time.yesterday');
            } else if ((interval = Math.floor(seconds / 3600)) > 1) {
                time = interval + ' ' + t('time.hours_ago');
            } else if(interval == 1) { 
                time = t('time.an_hour_ago');
            }else if ((interval = Math.floor(seconds / 60)) > 1) {
                time = interval + ' ' + t('time.minutes_ago');
            } else if(interval == 1) {
                time = t('time.one_minute_ago');
            } else {
                time = t('time.just_now');
            }
            if(component.state.time !== time) {
                component.setState({time: time});
            }
        };
        this.interval = setInterval(formatTime, 1000);
        formatTime();

    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        const {t} = this.props;
        if(typeof this.props.string !== "undefined") {
            return t(this.props.string, {time: this.state.time});
        } else {
            return this.state.time;
        }
    }
}


export default withTranslation('common')(TimeAgo);