import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_COMMENT_LIKED_START,
  SAVE_COMMENT_LIKE_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchCommentLikedFailure,
  fetchCommentLikedSuccess,
  saveCommentLikeFailure,
  saveCommentLikeSuccess,
} from "../actions/CommentLikesAction";

function* saveCommentLikesAPI() {
  try {
    const inputData = yield select(
      (state) => state.commentLike.saveLike.inputData
    );
    const response = yield api.postMethod("comment_likes_save", inputData);
    if (response.data.success) {
      yield put(saveCommentLikeSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(saveCommentLikeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveCommentLikeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchCommentLikesAPI() {
  try {
    const inputData = yield select(
      (state) => state.commentLike.saveLike.inputData
    );
    const response = yield api.postMethod("comment_likes", inputData);
    if (response.data.success) {
      yield put(fetchCommentLikedSuccess(response.data.data));
    } else {
      yield put(fetchCommentLikedFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCommentLikedFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(SAVE_COMMENT_LIKE_START, saveCommentLikesAPI)]);
  yield all([yield takeLatest(FETCH_COMMENT_LIKED_START, fetchCommentLikesAPI)]);
}
