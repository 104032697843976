import React, { useState, useEffect } from "react";
import FollowingAllSec from "./FollowingAllSec";
import FollowingActiveSec from "./FollowingActiveSec";
import FollowingExpiredSec from "./FollowingExpiredSec";
import FollowingTabSec from "./FollowingTabSec";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchActiveFollowingStart,
  fetchExpiredFollowingStart,
  fetchFollowingStart,
} from "../../../../store/actions/FollowAction";
import FollowingLoader from "../../../Loader/FollowingLoader";
import { useTranslation } from "react-i18next";
import "./FollowingIndex.css";
import { Link } from "react-router-dom";

const FollowingIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchActiveFollowingStart());
  }, []);
  const [t, i18n] = useTranslation('common');
  const [activeSec, setActiveSec] = useState("active-sec");

  const changeSection = (event, type) => {
    setActiveSec(type);
    if (type === "active-sec") props.dispatch(fetchActiveFollowingStart());
    if (type === "expired-sec") props.dispatch(fetchExpiredFollowingStart());
    if (type === "all-sec") props.dispatch(fetchFollowingStart());
  };
  
  console.log(props.activeFollowing);
  return (
    <div className="following">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
                <div className="pull-left">
                <Link className="go-back" to={'/list'}>
                <i className="fas fa-arrow-left"></i>
                </Link>
                </div>
                <h1>{t('lists.following')}</h1>
            </div>
            <div className="listing-tab">
              <div className="tab" role="tabpanel">
                
                <div className="tab-content tabs">
                {props.activeFollowing.loading ? (
                                                "Loading..."
                                                ) :
                  <FollowingActiveSec
                    users={props.activeFollowing.data.followers}
                  />}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  following: state.follow.following,
  activeFollowing: state.follow.activeFollowing,
  expiredFollowing: state.follow.expiredFollowing,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FollowingIndex);
