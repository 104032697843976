import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col, Container, Image } from "react-bootstrap";
import { deleteAccountStart } from "../../../store/actions/UserAction";
import './Profile.css';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {getThumbnail} from "../../helper/Thumbnail";

const DeleteAccount = (props) => {
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(deleteAccountStart({ password: password }));
  };
  
  const history = useHistory();
  const [t, i18n] = useTranslation('common');

  return (
      <div className="edit-profile">
                <Container>
                    <Row>
                        <Col md={8} sm={12}>
                        <div className="tab-content tabs">
                        <div className="edit-profile-photo">
                            <div className="profile large">
                                <div className="cover">
                                    <div className="edit-profile-gradient">
                                    </div>
                                    <div className="top-left">
                                        <a href="javascript:void(0)" onClick={history.goBack}>
                                        <Button className="chat-header-back">
                                            <i class="fas fa-arrow-left"></i>
                                        </Button>
                                        </a>
                                        <h1 className="chat-page-title">
                                            {t('header.delete_user')}
                                        </h1>
                                    </div>
                                    <Image
                                        src={getThumbnail(props.profile.data.cover, 414)}
                                        />
                
                                    <div className="layer">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                                <div className="user-info">
                                    <div className="profile-pic">
                                        <Image
                                            src={getThumbnail(props.profile.data.picture, 117)}
                                            />
                                        <div className="layer">
                                            <div className="loader"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div className="card-details edit-input-wrapper">
                        <h5>
                          <b>{t('profile.hope_see_you')}</b>
                        </h5>
                        <p>
                          {t('profile.once_you_deleted')}
                        </p>
                      </div>
                      <Form autoComplete="new-password" onSubmit={handleSubmit} className="edit-input-wrapper">
                        <Form.Group>
                          <Form.Label for="password">Password</Form.Label>
                          <Form.Control
                            className="form-control"
                            id="password"
                            type="password"
                            placeholder="Enter your password"
                            name="password"
                            value={password}
                            onChange={(event) =>
                              setPassword(event.currentTarget.value)
                            }
                          />
                        </Form.Group>
                        <Row className="mt-5">
                          <Col sm={12} xs={12} md={6} className="mx-auto">
                          <div className="edit-save">
                            <Button
                              className="save-btn"
                              type="submit"
                              disabled={props.deleteAcc.buttonDisable}
                            >
                              {props.deleteAcc.loadingButtonContent !== null
                                ? props.deleteAcc.loadingButtonContent
                                : t('header.delete_user')}
                            </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                        </Col>
                    </Row>
                </Container>
            </div>
  );
};

const mapStateToPros = (state) => ({
  deleteAcc: state.users.deleteAccount,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccount);
