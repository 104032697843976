import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import { sendWithDrawRequestStart } from "../../store/actions/WithDrawAction";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WithdrawModel = (props) => {
  useEffect(() => {
    props.dispatch(getBankAccountStart());
  }, []);

  const [inputData, setInputData] = useState({});
  const [t, i18n] = useTranslation('common');

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(sendWithDrawRequestStart(inputData));
    props.closeWithdrawModal();
  };

  return (
      <Modal
        className="modal-dialog-center withdraw-modal payment-popup"
        size="md"
        centered
        show={props.withdrawModal}
        onHide={props.closeWithdrawModal}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
          <button class="close" onClick={event => { event.preventDefault(); props.closeWithdrawModal(); }}><i class="fas fa-times-circle"></i></button>
          <h2>{t('wallet.send_request')}</h2>
            {props.payments.loading ? (
              "Loading"
            ) : (
              <Row>
                <Col md="12">
                  <div className="mb-5">
                    <h4 className="mb-2">
                      {t('wallet.min_amount_required')}:{" "}
                      <span className="text-muted">
                        {
                          props.payments.data
                            .user_withdrawals_min_amount_formatted
                        }
                      </span>
                    </h4>
                    <h4 className="text-muted">
                      {t('wallet.wallet_balance')}:{" "}
                      <span className="text-muted">
                        {props.payments.data.user_wallet
                          ? props.payments.data.user_wallet.remaining_formatted
                          : ""}
                      </span>
                    </h4>
                  </div>
                </Col>
              </Row>
            )}
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="number"
                  placeholder={t('wallet.amount')}
                  value={inputData.requested_amount}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      requested_amount: event.currentTarget.value,
                    })
                  }
                />
                <span className="highlight"></span>
                <label className="default-label">{t('wallet.enter_amount')}</label>
              </div>
              <div className="floating-label">
                <label className="label-default-1">{t('wallet.choose_bank_account')}</label>
                {props.bankAccount.loading ? (
                  "Loading..."
                ) : props.bankAccount.data.billing_accounts.length > 0 ? (
                  props.bankAccount.data.billing_accounts.map((account) => (
                    <Form>
                      {["radio"].map((type) => (
                        <div key={`custom-inline-${type}`} className="mb-3">
                          <Form.Check
                            custom
                            inline
                            label={account.nickname}
                            type={type}
                            id={account.user_billing_account_id}
                            value={account.user_billing_account_id}
                            name="user_billing_account_id"
                            onChange={(event) =>
                              setInputData({
                                ...inputData,
                                user_billing_account_id:
                                  account.user_billing_account_id,
                              })
                            }
                          />
                        </div>
                      ))}
                    </Form>
                  ))
                ) : (
                  <p>
                    No Bank accounts added. To add account, please{" "}
                    <Link className="text-sm" to={`/add-bank`}>
                      Click here
                    </Link>
                  </p>
                )}
              </div>
            </div>
            <div className="subscription-section">
            <Button
              type="button"
              className="g-btn m-rounded m-border m-uppercase m-flex m-fluid-width m-profile user-follow"
              data-dismiss="modal"
              onClick={handleSubmit}
              disabled={props.sendWithDraw.buttonDisable}
            >
              {props.sendWithDraw.loadingButtonContent !== null
                ? props.sendWithDraw.loadingButtonContent
                : t('wallet.send_request')}
            </Button>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
  );
};

const mapStateToPros = (state) => ({
  sendWithDraw: state.withDraw.sendWithDraw,
  bankAccount: state.bankAccount.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WithdrawModel);
