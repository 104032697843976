import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Button, Image } from "react-bootstrap";
import {
editUserDetails,
        fetchUserDetailsStart,
        updateUserDetailsStart,
} from "../../../store/actions/UserAction";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import CopyLinkModal from "../../helper/CopyLinkModal";
import { getThumbnail } from "../../helper/Thumbnail";

const EditProfileCard = (props) => {
    const {t, i18n} = useTranslation('common');
    const [profileInputData, setProfileInputData] = useState({});

    const [image, setImage] = useState({
        picture: "",
        cover: "",
    });

    useEffect(() => {
        if (props.profile.loading)
            props.dispatch(fetchUserDetailsStart());
    }, []);

    const handleChangeImage = (event) => {
        if (event.currentTarget.type === "file") {
            setProfileInputData({
                ...profileInputData,
                [event.currentTarget.name]: event.currentTarget.files[0],
            });
            let reader = new FileReader();
            let file = event.currentTarget.files[0];

            if (event.currentTarget.name === "cover") {
                reader.onloadend = () => {
                    setImage({...image, cover: reader.result});
                };
            }

            if (event.currentTarget.name === "picture") {
                reader.onloadend = () => {
                    setImage({...image, picture: reader.result});
                };
            }

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(profileInputData).length > 0)
            props.dispatch(updateUserDetailsStart(profileInputData));
        else
            props.dispatch(updateUserDetailsStart());
    };
    const [showCopyLink, setShowCopyLink] = useState(false);
    const handleCopyLinkModalClose = () => setShowCopyLink(false);
    const handleCopyLinkModalShow = () => setShowCopyLink(true);



    return (<div>
        {props.profile.loading ? (
                        "Loading..."
                        ) : (
                    <div
                        role="tabpanel"
                        className={
                                props.activeSec === "profile-card"
                                        ? "tab-pane fade in active"
                                        : "tab-pane fade"
                        }
                        id="Section2"
                        >
                        <div className="edit-profile-photo">
                            <div className="profile large">
                                <div className="cover">
                                    <div className="edit-profile-gradient">
                                    </div>
                                    <div className="top-left">
                                        <Link to={`/profile`}>
                                        <Button className="chat-header-back">
                                            <i class="fas fa-arrow-left"></i>
                                        </Button>
                                        </Link>
                                        <h1 className="chat-page-title">
                                            {t('profile.edit_user_profile')}
                                        </h1>
                                    </div>
                                    <div className="top-right">
                                        <span className="context-menu" onClick={handleCopyLinkModalShow}>
                                            <FontAwesomeIcon icon={faEllipsisV} color={"white"} />
                                        </span>
                                    </div>
                                    <Image
                                        src={getThumbnail(image.cover === "" ? props.profile.data.cover : image.cover, 414)}
                                        />
                
                                    <div className="layer">
                                        <div className="loader"></div>
                                    </div>
                                    <a className="image-wrapper" href="#">
                                        <Form id="coverForm" action="#">
                                            <Form.Control
                                                className="hidden-input"
                                                id="changeCover"
                                                type="file"
                                                name="cover"
                                                accept="image/*"
                                                onChange={handleChangeImage}
                                                />
                                            <Form.Label
                                                className="edit "
                                                for="changeCover"
                                                title="Change cover"
                                                >
                                                <i class="fas fa-camera"></i>
                                            </Form.Label>
                                        </Form>
                                    </a>
                                </div>
                                <div className="user-info">
                                    <div className="profile-pic">
                                        <Image
                                            src={getThumbnail(image.picture === ""
                                                            ? props.profile.data.picture
                                                            : image.picture, 117)}
                                            />
                                        <div className="layer">
                                            <div className="loader"></div>
                                        </div>
                                        <a className="image-wrapper" href="#">
                                            <Form id="profile-img" action="#">
                                                <Form.Control
                                                    className="hidden-input"
                                                    id="changePicture"
                                                    type="file"
                                                    accept="image/*"
                                                    name="picture"
                                                    onChange={handleChangeImage}
                                                    />
                                                <Form.Label
                                                    className="edit"
                                                    for="changePicture"
                                                    type="file"
                                                    title="Change picture"
                                                    ><i class="fas fa-camera"></i></Form.Label>
                                            </Form>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="edit-input-wrapper"
                            data-vv-delay="1000"
                            data-vv-as="username"
                            >
                            <Form.Label className="edit-input-label">
                                Nome utente <span className="edit-input-optional">(facoltativo)</span>
                            </Form.Label>
                            <div className="">
                                <Form.Control
                                    id="username"
                                    type="text"
                                    placeholder={t('profile.my_username')}
                                    name="username"
                                    value={props.profile.data.username}
                                    className="form-control edit-reset"
                                    onChange={(event) => {
                                                props.dispatch(
                                                        editUserDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                                )
                                                        );
                                            }}
                                    />
                            </div>
                        </div>
                        <div
                            className="edit-input-wrapper"
                            data-vv-delay="1000"
                            data-vv-as="Display Name"
                            >
                            <Form.Label className="edit-input-label">
                                Nome pubblico
                                <span className="edit-input-optional">({t('forms.optional')})</span>
                            </Form.Label>
                            <div className="">
                                <Form.Control
                                    id="name"
                                    type="text"
                                    placeholder={t('profile.my_username')}
                                    defaultValue={props.profile.data.name}
                                    name="name"
                                    className="form-control edit-reset"
                                    onChange={(event) => {
                                                props.dispatch(
                                                        editUserDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                                )
                                                        );
                                            }}
                                    />
                            </div>
                        </div>
                        <div
                            className="edit-input-wrapper disabled"
                            data-vv-delay="1000"
                            data-vv-as="monthly_amount"
                            >
                            <Form.Label className="edit-input-label">
                                Costo iscrizione mensile
                                <span className="edit-input-optional">(facoltativo)</span>
                            </Form.Label>
                            <div className="">
                                <Form.Control
                                    id="monthly_amount"
                                    type="number"
                                    step="any"
                                    min="0"
                                    placeholder=""
                                    name="monthly_amount"
                                    className="form-control edit-reset"
                                    disabled={
                                            localStorage.getItem("is_subscription_enabled") == 1
                                                    ? false
                                                    : true
                                    }
                                    defaultValue={props.profile.data.monthly_amount}
                                    onChange={(event) => {
                                                props.dispatch(
                                                        editUserDetails(
                                                                event.currentTarget.monthly_amount,
                                                                event.currentTarget.value
                                                                )
                                                        );
                                            }}
                                    readOnly={true}
                                    />
                            </div>
                        </div>
                        <div
                            className="edit-input-wrapper disabled"
                            data-vv-delay="1000"
                            data-vv-as="yearly_amount"
                            >
                            
                            {localStorage.getItem("is_subscription_enabled") == 1 ? (
                                        <p className="inuput-help">
                                            Puoi cambiare  
                                            <Link to={`/add-bank`}>
                                            le informazioni del conto
                                            </Link>{" "}
                                            in ogni momento
                                        </p>
                                            ) : (
                                        <p className="inuput-help">
                                            Devi
                                            <Link to={`/add-bank`}>
                                            aggiungere un conto corrente bancario
                                            </Link>{" "}
                                            prima di impostare il costo dell'iscrizione o accettare mance
                                        </p>
                                            )}
                        </div>
                        <div
                            className="edit-input-wrapper disabled"
                            data-vv-delay="1000"
                            data-vv-as="description"
                            >
                            <Form.Label className="edit-input-label">
                                Chi siamo <span className="edit-input-optional">(facoltativo)</span>
                            </Form.Label>
                            <div className="">
                                <Form.Control
                                    id="edit-description"
                                    type="text"
                                    autocomplete="off"
                                    placeholder=""
                                    value={props.profile.data.about}
                                    name="about"
                                    className="form-control edit-reset"
                                    onChange={(event) => {
                                                props.dispatch(
                                                        editUserDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                                )
                                                        );
                                            }}
                                    />
                            </div>
                        </div>
                        <div
                            className="edit-input-wrapper disabled"
                            data-vv-delay="1000"
                            data-vv-as="Address"
                            >
                            <Form.Label className="edit-input-label">
                                Dove siamo <span className="edit-input-optional">(facoltativo)</span>
                            </Form.Label>
                            <div className="">
                                <Form.Control
                                    id="edit-address"
                                    type="text"
                                    autocomplete="off"
                                    value={props.profile.data.address}
                                    placeholder="Location"
                                    name="address"
                                    className="form-control edit-reset"
                                    onChange={(event) => {
                                                props.dispatch(
                                                        editUserDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                                )
                                                        );
                                            }}
                                    />
                            </div>
                        </div>
                        <div
                            className="edit-input-wrapper disabled"
                            data-vv-delay="1000"
                            data-vv-as="Website"
                            >
                            <Form.Label className="edit-input-label">
                                URL sito
                                <span className="edit-input-optional">(facoltativo)</span>
                            </Form.Label>
                            <div className="">
                                <Form.Control
                                    id="edit-website"
                                    type="text"
                                    autocomplete="off"
                                    value={props.profile.data.website}
                                    placeholder="Website URL"
                                    name="website"
                                    className="form-control edit-reset"
                                    onChange={(event) => {
                                                props.dispatch(
                                                        editUserDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                                )
                                                        );
                                            }}
                                    />
                            </div>
                        </div>
                        <div
                            className="edit-input-wrapper disabled"
                            data-vv-delay="1000"
                            data-vv-as="Amazon Wishlist"
                            >
                            <Form.Label className="edit-input-label">
                                AMAZON WISHLIST
                                <span className="edit-input-optional">(facoltativo)</span>
                            </Form.Label>
                            <div className="">
                                <Form.Control
                                    id="edit-amazon-wishlist"
                                    type="text"
                                    autocomplete="off"
                                    value={props.profile.data.amazon_wishlist}
                                    placeholder="Amazon Wishlist"
                                    name="amazon_wishlist"
                                    className="form-control edit-reset"
                                    onChange={(event) => {
                                                props.dispatch(
                                                        editUserDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                                )
                                                        );
                                            }}
                                    />
                            </div>
                        </div>
                        <div className="edit-save">
                            <Button
                                className="save-btn"
                                onClick={handleSubmit}
                                disabled={props.profileInputData.buttonDisable}
                                >
                                {props.profileInputData.loadingButtonContent !== null
                                                ? props.profileInputData.loadingButtonContent
                                                : t('forms.save')}
                            </Button>
                        </div>
                    </div>
                        )}
        <CopyLinkModal show={showCopyLink} onHide={handleCopyLinkModalClose} profile={props.profile}/>
    </div>
            );
};

const mapStateToPros = (state) => ({
        profile: state.users.profile,
        profileInputData: state.users.profileInputData,
    });

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfileCard);
