import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button} from "react-bootstrap";
import configuration from "react-global-configuration";
import SocialButton from "./SocialButton";

import { connect } from "react-redux";
import {
forgotPasswordStart,
        userLoginStart,
        userRegisterStart,
        } from "../../store/actions/UserAction";
import {useTranslation} from "react-i18next";
import VerifyEmailForm from "../Accounts/VerifyEmailForm";

const LoginSection = (props) => {
    const {t, i18n} = useTranslation('common');
    const [show, setSectionToShow] = useState("login");
    
    
    
  
    const setShow = (show) => {
        setSectionToShow(show);
        if(typeof props.setView !== "undefined") {
            props.setView(show);
        }
    }

    const [loginInputData, setLoginInputData] = useState({});
    

    const [signupInputData, setSignupInputData] = useState({});

    const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});
    
    
    const handleLogin = (event) => {
        event.preventDefault();
        if(typeof props.afterLogin !== "undefined") {
           loginInputData.afterLogin = props.afterLogin;
        }
        
        props.dispatch(userLoginStart(loginInputData));
    };
      

    const handleSignup = (event) => {
        event.preventDefault();
        if(typeof props.afterLogin !== "undefined") {
            signupInputData.afterLogin = props.afterLogin;
        }
        if(typeof props.verifyEmail !== "undefined") {
            signupInputData.verifyEmail = props.verifyEmail;
        } else {
            signupInputData.verifyEmail = () => setShow('verify-email');
        }
        props.dispatch(userRegisterStart(signupInputData));
    };

    const handleForgotPassword = (event) => {
        event.preventDefault();
        props.dispatch(forgotPasswordStart(forgotPasswordInputData));
    };

    const handleFacebookLogin = (user) => {
        console.log("handleFacebookLogin", user._profile);
        let userData = {
                    name: user._profile.name,
                    first_name: user._profile.firstName ? user._profile.firstName : "",
                    last_name: user._profile.lastName ? user._profile.lastName : "",
                    email: user._profile.email ? user._profile.email : "",
                    social_unique_id: user._profile.id,
                    picture: user._profile.profilePicURL,
                    login_by: "facebook",
                };
        if(typeof props.afterLogin !== "undefined") {
            userData.afterLogin = props.afterLogin;
        }
        props.dispatch(
                userRegisterStart(userData)
                );
    };

    const handleGoogleLogin = (user) => {
        console.log("handleGoogleLogin", user._profile);
        let userData = {
                    name: user._profile.name,
                    email: user._profile.email,
                    first_name: user._profile.firstName ? user._profile.firstName : "",
                    last_name: user._profile.lastName ? user._profile.lastName : "",
                    social_unique_id: user._profile.id,
                    picture: user._profile.profilePicURL,
                    login_by: "google",
                };
        if(typeof props.afterLogin !== "undefined") {
            userData.afterLogin = props.afterLogin;
        }
        props.dispatch(
                userRegisterStart(userData)
                );
    };

    const handleSocialLoginFailure = (err) => {
        console.error(err);
    };
    
    
    return (<>
                  <div id="main">
                  <div id="first" className={props.is_logged_in ? 'logged_in' : ''}>
                      {show === "login" ? (
                              <>
                        <Form onSubmit={handleLogin} method="post">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Control
                              type="text"
                              controlId="loginemail"
                              placeholder="E-mail"
                              required
                              value={loginInputData.email}
                              name="email"
                              onChange={(event) =>
                                setLoginInputData({
                                  ...loginInputData,
                                  email: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>

                          <Form.Group controlId="formBasicPassword">
                            <Form.Control
                              type="password"
                              controlId="loginpassword"
                              placeholder="Password"
                              required
                              value={loginInputData.password}
                              name="password"
                              onChange={(event) =>
                                setLoginInputData({
                                  ...loginInputData,
                                  password: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>
                          
                          <div className="">
                            <Button
                              id="login"
                              type="submit"
                              onClick={handleLogin}
                              className="btn btn-auth btn-lg"
                              disabled={props.login.buttonDisable}
                            >
                              {props.login.loadingButtonContent !== null
                                ? props.login.loadingButtonContent
                                : t('login.login')}
                            </Button>
                          </div>
                        </Form>
                        <div> {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                  configuration.get("configData.FB_CLIENT_ID") ? (
                    <span className="or-line">
                      <span>OPPURE</span>
                    </span>
                  ) : (
                    <span classsName="login-or-hide"></span>
                  )}
                  {configuration.get("configData.FB_CLIENT_ID") ? (
                    <SocialButton
                      provider="facebook"
                      appId={configuration.get("configData.FB_CLIENT_ID")}
                      onLoginSuccess={handleFacebookLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="facebook-connect"
                    >
                      <i class="fab fa-facebook-f"></i>
                      <span>{t('login.log_in_with_facebook')}</span>
                    </SocialButton>
                  ) : (
                    ""
                  )}

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                    <SocialButton
                      provider="google"
                      key={"google"}
                      appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                      onLoginSuccess={handleGoogleLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="google-connect"
                    >
                      <i class="fab fa-google"></i>
                      <span>{t('login.log_in_with_google')}</span>
                    </SocialButton>
                  ) : (
                    ""
                  )}
          </div>
          <div className="forget-password">
                            <p id="one">
                              <Link
                                type="button"
                                className="forgot-link"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setShow("forgotpassword");
                                }}
                              >
                                {t('login.lost_password')}?
                              </Link>
                            </p>
                 </div>
                 <div className="signup-wrapper">
                            <Link
                              className="signup"
                              to="#"
                              id="signup"
                              onClick={(event) => {
                                event.preventDefault();
                                setShow("signup");
                              }}
                            >
                              {t('login.register')}
                            </Link>
                          </div>
          </>
                      ) : null}
                      {show === "signup" ? (
                        <Form
                          onSubmit={handleSignup}
                          id="form"
                          method="post"
                          name="form"
                          className="signup"
                        >
                          <Form.Group controlId="formBasicName">
                            <Form.Control
                              type="text"
                              controlId="firstname"
                              placeholder="Nome"
                              required
                              value={signupInputData.first_name}
                              name="firstname"
                              onChange={(event) =>
                                setSignupInputData({
                                  ...signupInputData,
                                  first_name: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Form.Group controlId="formBasicName">
                            <Form.Control
                              type="text"
                              controlId="lastname"
                              placeholder="Cognome"
                              required
                              value={signupInputData.last_name}
                              name="lastname"
                              onChange={(event) =>
                                setSignupInputData({
                                  ...signupInputData,
                                  last_name: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Control
                              type="text"
                              controlId="registeremail"
                              placeholder="E-mail"
                              required
                              value={signupInputData.email}
                              name="email"
                              onChange={(event) =>
                                setSignupInputData({
                                  ...signupInputData,
                                  email: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>

                          <Form.Group controlId="formBasicPassword">
                            <Form.Control
                              type="password"
                              controlId="registerpassword"
                              placeholder="Password"
                              required
                              value={signupInputData.password}
                              name="password"
                              onChange={(event) =>
                                setSignupInputData({
                                  ...signupInputData,
                                  password: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>


                          <Form.Group controlId="formBasicName">
                            <Button
                              id="register"
                              type="submit"
                              onClick={handleSignup}
                              className="btn btn-auth btn-lg r-btn"
                              disabled={props.login.buttonDisable}
                            >
                              {props.signup.loadingButtonContent !== null
                                ? props.signup.loadingButtonContent
                                : t("login.sign_up")}
                            </Button>
                          </Form.Group>
                          
                          <div> {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                  configuration.get("configData.FB_CLIENT_ID") ? (
                    <span className="or-line">
                      <span>OPPURE</span>
                    </span>
                  ) : (
                    <span classsName="login-or-hide"></span>
                  )}
                  {configuration.get("configData.FB_CLIENT_ID") ? (
                    <SocialButton
                      provider="facebook"
                      appId={configuration.get("configData.FB_CLIENT_ID")}
                      onLoginSuccess={handleFacebookLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="facebook-connect"
                    >
                      <i class="fab fa-facebook-f"></i>
                      <span>{t('login.sign_up_with_facebook')}</span>
                    </SocialButton>
                  ) : (
                    ""
                  )}

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                    <SocialButton
                      provider="google"
                      key={"google"}
                      appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                      onLoginSuccess={handleGoogleLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="google-connect"
                    >
                      <i class="fab fa-google"></i>
                      <span>{t('login.sign_up_with_google')}</span>
                    </SocialButton>
                  ) : (
                    ""
                  )}
          </div>
                <a href="javascript:void" class="already-registered" onClick={event => { event.preventDefault(); setShow('login') }}>{t('login.already_registered')}</a>

                        </Form>
                      ) : null}
                      {show === "forgotpassword" ? (
                        <Form onSubmit={handleForgotPassword} method="post">
                          <div className="form-group">
                            <Form.Control
                              type="text"
                              controlId="loginemail"
                              className="form-control"
                              placeholder="E-mail"
                              required
                              value={forgotPasswordInputData.email}
                              name="email"
                              onChange={(event) =>
                                setForgotPasswordInputData({
                                  ...forgotPasswordInputData,
                                  email: event.currentTarget.value,
                                })
                              }
                            />
                          </div>

                          <Form.Group controlId="formBasicName">
                            <Button
                              id="forgotpassword"
                              type="submit"
                              onClick={handleForgotPassword}
                              className="btn btn-auth btn-lg"
                              disabled={props.forgotPassword.buttonDisable}
                            >
                              {props.forgotPassword.loadingButtonContent !==
                              null
                                ? props.forgotPassword.loadingButtonContent
                                : "Password dimenticata?"}
                            </Button>
                          </Form.Group>
                          <p id="two">Ti sei già registrato?</p>
                          <p >
                            <Link
                              className="signup"
                              to="#"
                              id="signin"
                              onClick={(event) => {
                                event.preventDefault();
                                setShow("login");
                              }}
                            >
                              {" "}
                              Login for{" "}
                              {configuration.get("configData.site_name")}
                            </Link>
                          </p>
                        </Form>
                      ) : null}
                     {show === "verify-email" ? (<VerifyEmailForm afterVerified={props.afterVerified}/>) : null}
                    </div>
                  </div>
                  
                  
    </>
    );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
  signup: state.users.registerInputData,
  forgotPassword: state.users.forgotPasswordInputData,
  is_logged_in: state.users.is_logged_in
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginSection);