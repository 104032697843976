import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Image, Media, Form } from "react-bootstrap";
import NoDataFound from "../NoDataFound/NoDataFound";
import GLightbox from "glightbox";
import {getThumbnail} from "../helper/Thumbnail";

const ModelProfilePhotoSec = (props) => {
  let lightbox = false;
  
  const openLightBox = (event) => {
      event.preventDefault();
      
      if(!lightbox) {
          lightbox = GLightbox({
            touchNavigation: true,
            loop: true
          });
          lightbox.open(event.target);
      }
      
  };
  const [keyword, setKeyword] = useState('');
  
  return (
    <div
      role="tabpanel"
      className={
        props.activeSec === "photo"
          ? "tab-pane fade in active"
          : "tab-pane fade"
      }
      id="Section2"
    >
    <div className="alignleft">
            <span className="post-number">
              {props.userPosts.loading ? (
                "Loading..."
              ) : (
                <span>{props.userPosts.data.total} Posts</span>
              )}
            </span>
    </div>
    {localStorage.getItem("userLoginStatus") === 'true' ? (
          <div className="alignright">
            <div className="profile-search-post">
              <Form className="search-box">
                <input
                  className="search-text"
                  type="text"
                  placeholder="Search Anything"
                  onChange={event => setKeyword(event.currentTarget.value)}
                />
                <Link to="#" className="search-btn">
                  <i class="fas fa-search"></i>
                </Link>
              </Form>
            </div>
          </div>) : ""}
      <ul className="box-container three-cols model-prof-photo-sec">
        {props.activeSec !== "photo" ? "" : props.userPosts.loading ? (
          "Loading..."
        ) : props.userPosts.data.posts.length > 0 ? (
          props.userPosts.data.posts.map((post) =>
            post.postFiles.length > 0
              ? post.postFiles.map((p_file) => !keyword.length || post.content.search(new RegExp(keyword, 'i')) != -1 ?(
                  <Media as="li" className="box">
                    <div className="inner">
                      <a href={p_file.post_file} class="glightbox" onClick={openLightBox}>
                          <img src={getThumbnail(p_file.post_file, 177)} alt="image" />
                      </a>
                    </div>
                  </Media>
                ) : null)
              : ""
          )
        ) : (
          <NoDataFound></NoDataFound>
        )}
      </ul>
    </div>
  );
};

export default ModelProfilePhotoSec;
